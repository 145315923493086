<template>
  <div class="container">
    <!--Title-->
    <div class="main_title2 mb-5">
      <h4>{{ $t("dashboard.shipment.title") }}</h4>
    </div>
    <!--Title-->
    <!-- Actions-->
    <div class="reports_actions">
      <!--New-->
      <div class="cre_req">
        <router-link class="new_req" to="/addShipment"
          >{{ $t("dashboard.shipment.newRequest") }} <i class="fal fa-plus"></i
        ></router-link>
      </div>
      <!-- New-->
    </div>
    <!-- Actions-->
    <div class="custom_card pl-0 pr-0">
      <div class="row">
        <div class="col-sm-12">
          <!-- Start Main Section -->
          <v-data-table
            class="elevation-1 thumb"
            :headers="headers"
            :items="rows"
            :search="search"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            item-key="id"
            :items-per-page="paginations.items_per_page"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select actions-->

            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
                <v-icon class="show" small @click="showItem(item)">
                  fal fa-eye
                </v-icon>
                <!-- <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash-alt
                </v-icon> -->
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
                      $t("table.deletedialog.ok")
                    }}</v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete_selected" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteSelectedConfirm">{{
                      $t("table.deletedialog.ok")
                    }}</v-btn>
                    <v-btn
                      color="#F44336"
                      @click="dialogDelete_selected = false"
                      >{{ $t("table.deletedialog.cancel") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Add & Delete -->
              <v-row>
                <v-col cols="12" sm="12">
                  <!-- Search -->
                  <v-text-field
                    v-model="search"
                    :label="$t('table.search')"
                    class="mx-4"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
          <!-- End Main Section -->

          <!-- Start Pagination -->
          <template>
            <div class="pagination_container text-center mb-5">
              <v-pagination
                v-model="paginations.current_page"
                :length="paginations.last_page"
                :total-visible="6"
                prev-icon="fal fa-angle-left"
                next-icon="fal fa-angle-right"
              ></v-pagination>
            </div>
          </template>
          <!-- End Pagination -->

          <!-- Start Image_Model -->
          <base-model
            @closeModel="model_1.show_model = false"
            :show="model_1.show_model"
          >
            <div class="image">
              <img
                v-if="model_1.model_img_src"
                :src="model_1.model_img_src"
                alt="..."
              />
            </div>
          </base-model>
          <!-- End Image_Model -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Data Table 1
      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Search
      search: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },

      // ========== Loding
      lodaing: false,
      table_length: null,
    };
  },
  watch: {
    "paginations.current_page"(newVal) {
      this.setRows(newVal);
    },
  },
  methods: {
    //Data Table

    // ==================== Start CRUD 1====================
    editItem(item) {
      this.$router.push({ path: "/editShipment/" + item.id });
    },
    showItem(item) {
      this.$router.push({ path: "/showShipment/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `shipment/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "DELETE",
        url: `client/debt/delete_all`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },

    // Set Rows 1
    setRows() {
      this.$axios({
        method: "GET",
        url: "shipment",
        params: { page: this.paginations.current_page },
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.paginations.last_page = res.data.meta.last_page;
        this.rows = res.data.data;
      });
    },

    // ==================== End CRUD 1 ====================
  },
  computed: {
    headers() {
      return [
        { text: "رقم الطلب", value: "id", align: "center", width: 20 },
        // {
        //   text: "اسم العميل",
        //   value: "shipper_name",
        //   align: "center",
        //   width: 80,
        // },
        {
          text: "المنفذ الجمركي",
          value: "port_id.name",
          align: "center",
        },
        {
          text: "مكتب التخليص",
          value: "office_id.name",
          align: "center",
        },
        {
          text: "رقم البوليصة",
          value: "bill_of_lading_number",
          align: "center",
        },
        {
          text: "تاريخ الوصول المتوقع",
          value: "expected_date",
          align: "center",
        },
        // {
        //   text: "نوع الشحن",
        //   value: "mode_shipping_id.name",
        //   align: "center",
        // },
        // {
        //   text: "نوع الشحنة",
        //   value: "shipping_type_id.name",
        //   align: "center",
        // },
        {
          text: "حالة الشحنة",
          value: "status.name",
          align: "center",
        },
        {
          text: "التحكم",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.setRows();
  },
};
</script>

<style></style>
