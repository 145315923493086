<template>
  <div class="our_values" id="val">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <!--Main Title-->
          <div class="main_title">
            <h3>{{ $t("values.title") }}</h3>
          </div>
          <!--Main Title-->
        </div>
        <div class="row">
          <div class="col-md-2 values_content">
            <!--Value Item-->
            <div class="value_item">
              <!--Value Image-->
              <div class="image">
                <div class="icon">
                  <img src="@/assets/images/Values/icon1.png" />
                </div>
              </div>
              <!--Value Image-->
              <!--Value Title-->
              <div class="title">
                <h4>{{ $t("values.trust") }}</h4>
              </div>
              <!--Value Title-->
            </div>
            <!--Value Item-->
          </div>
          <div class="col-md-2 values_content">
            <!--Value Item-->
            <div class="value_item">
              <!--Value Image-->
              <div class="image">
                <div class="icon">
                  <img src="@/assets/images/Values/icon2.png" />
                </div>
              </div>
              <!--Value Image-->
              <!--Value Title-->
              <div class="title">
                <h4>{{ $t("values.team") }}</h4>
              </div>
              <!--Value Title-->
            </div>
            <!--Value Item-->
          </div>
          <div class="col-md-2 values_content">
            <!--Value Item-->
            <div class="value_item">
              <!--Value Image-->
              <div class="image">
                <div class="icon">
                  <img src="@/assets/images/Values/icon3.png" />
                </div>
              </div>
              <!--Value Image-->
              <!--Value Title-->
              <div class="title">
                <h4>{{ $t("values.prediction") }}</h4>
              </div>
              <!--Value Title-->
            </div>
            <!--Value Item-->
          </div>
          <div class="col-md-2 values_content">
            <!--Value Item-->
            <div class="value_item">
              <!--Value Image-->
              <div class="image">
                <div class="icon">
                  <img src="@/assets/images/Values/icon4.png" />
                </div>
              </div>
              <!--Value Image-->
              <!--Value Title-->
              <div class="title">
                <h4>{{ $t("values.fast") }}</h4>
              </div>
              <!--Value Title-->
            </div>
            <!--Value Item-->
          </div>
          <div class="col-md-2 values_content">
            <!--Value Item-->
            <div class="value_item">
              <!--Value Image-->
              <div class="image">
                <div class="icon">
                  <img src="@/assets/images/Values/icon5.png" />
                </div>
              </div>
              <!--Value Image-->
              <!--Value Title-->
              <div class="title">
                <h4>{{ $t("values.respect") }}</h4>
              </div>
              <!--Value Title-->
            </div>
            <!--Value Item-->
          </div>
          <div class="col-md-2 values_content">
            <!--Value Item-->
            <div class="value_item">
              <!--Value Image-->
              <div class="image">
                <div class="icon">
                  <img src="@/assets/images/Values/icon6.png" />
                </div>
              </div>
              <!--Value Image-->
              <!--Value Title-->
              <div class="title">
                <h4>{{ $t("values.creative") }}</h4>
              </div>
              <!--Value Title-->
            </div>
            <!--Value Item-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
