<template>
  <div class="all_static">
    <!--Main Bg-->
    <div class="main_bg">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-md-4">
            <!--Image-->
            <div class="image">
              <img src="@/assets/images/Icons/about.png" />
            </div>
            <!--Image-->
          </div>
        </div>
      </div>
      <!--Particles Shape-->
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="120"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <!--Particles Shape-->
    </div>
    <!--Main Bg-->
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <!--All Text-->
          <div class="all_text about">
            <!--Main Title-->
            <div class="main_title">
              <h3>{{ $t("aboutUs.about.title") }}</h3>
            </div>
            <!--Main Title-->
            <!--Description Text-->
            <div class="desc_text">
              <p>{{ $t("aboutUs.about.desc") }}</p>
            </div>
            <!--Description Text-->
          </div>
          <!--All Text-->
          <!--All Text-->
          <div class="all_text message">
            <!--Main Title-->
            <div class="main_title">
              <h3>{{ $t("aboutUs.message.title") }}</h3>
            </div>
            <!--Main Title-->
            <!--Description Text-->
            <div class="desc_text">
              <p>{{ $t("aboutUs.message.desc") }}</p>
            </div>
            <!--Description Text-->
          </div>
          <!--All Text-->
          <!--All Text-->
          <div class="all_text">
            <!--Main Title-->
            <div class="main_title">
              <h3>{{ $t("aboutUs.vision.title") }}</h3>
            </div>
            <!--Main Title-->
            <!--Description Text-->
            <div class="desc_text">
              <p>{{ $t("aboutUs.vision.desc") }}</p>
            </div>
            <!--Description Text-->
          </div>
          <!--All Text-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
