<template>
  <v-app class="my_app">
    <transition name="fadeInUp" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
export default {
  name: "App",

  mounted() {
    // ===> Vuetify Direction
    if (this.$store.getters["lang_module/lang"] == "ar") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }

    // ===> Vuetify Theme
    if (this.$store.getters["theme_module/current_theme"] == "light") {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }

    // ===> scroll To Top Alwayse
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

      // (function() {

      //       "use strict";
            
      //       document.addEventListener("contextmenu", function(e) {
      //           e.preventDefault();
      //           return false;
      //       });

      //       document.addEventListener("keydown", function(e) {
      //           console.log(e.key);
      //           if (e.keyCode == 123) {
      //               return false;
      //           }
      //           if (e.ctrlKey && e.shiftKey && e.key == 'I') {
      //               e.preventDefault();
      //           }
      //           if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //           if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //           if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //           if (e.metaKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //           if (e.metaKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //           if (e.metaKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //           if (e.metaKey && e.keyCode == 'U'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //           if (e.metaKey && e.keyCode == 'S'.charCodeAt(0)) {
      //                                 e.preventDefault();

      //               return false;
      //           }
      //         });
              
      //       })();
  
  },
};
</script>
