<template>
  <div class="bubble-dotted">
    <span class="dotted dotted-1"></span>
    <span class="dotted dotted-2"></span>
    <span class="dotted dotted-3"></span>
    <span class="dotted dotted-4"></span>
    <span class="dotted dotted-5"></span>
    <span class="dotted dotted-6"></span>
    <span class="dotted dotted-7"></span>
    <span class="dotted dotted-8"></span>
    <span class="dotted dotted-9"></span>
    <span class="dotted dotted-10"></span>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
