<template>
  <div class="loading">
    <div class="loader3">
      <div class="spinner-icon"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading {
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader3 {
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
    .spinner-icon {
      width: 55px;
      height: 55px;
      border: solid 4px transparent;
      border-top-color: #354128 !important;
      border-left-color: #354128 !important;
      border-radius: 50%;
    }
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
