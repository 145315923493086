export default {
    set_sliders(state, payload) {
        state.sliders = payload;
    },

    set_degrees(state, payload) {
        state.degrees = payload;
    },
    set_specialization(state, payload) {
        state.specialization = payload;
    },

    set_academicYears(state, payload) {
        state.academicYears = payload;
    },

    set_categories(state, payload) {
        state.categories = payload;
    },

    set_countries(state, payload) {
        state.countries = payload;
    },

    set_cities(state, payload) {
        state.cities = payload;
    },

    set_messages(state, payload) {
        state.messages = payload;
    },

    set_orders(state, payload) {
        state.orders = payload;
    },

    set_teachers(state, payload) {
        state.teachers = payload;
    },

    set_parents(state, payload) {
        state.parents = payload;
    },

    set_students(state, payload) {
        state.students = payload;
    },

    set_products(state, payload) {
        state.products = payload;
    },

    set_withdrawals(state, payload) {
        state.withdrawals = payload;
    },

    set_transactions(state, payload) {
        state.transactions = payload;
    },

    set_relayRequests(state, payload) {
        state.relayRequests = payload;
    },

    set_notification(state, payload) {
        state.notification = payload;
    },

    set_loading(state, payload) {
        state.loading = payload;
    },
    set_noteIcon(state, payload) {
        state.noteIcon = payload;
    },
    set_lastPage(state, payload) {
        state.paginations.last_page = payload;
    }
};