<template>
  <div class="custom_card">
    <h2>Page not not found</h2>
    <p>
      This page could not be found - go To home
      <router-link to="/">Home</router-link>.
    </p>
  </div>
</template>

<script>
export default {};
</script>
