<template>
  <div class="auth_page">
    <!--Static Part-->
    <div class="static_part">
      <!--Lang-->
      <!-- <div class="lang">
        <button class="btn_lang">
          <span v-if="lang == 'ar'">En</span>
          <span v-if="lang == 'en'">Ar</span>
        </button>
      </div> -->
      <!--Lang-->
      <div class="content_part">
        <!--Title-->
        <div class="title">
          <h4>مرحباً بك في مرونة</h4>
        </div>
        <!--Title-->
        <!--Image-->
        <div class="image">
          
          <img src="@/assets/images/Auth/Logistics_hub.png" />
        </div>
        <!--Image-->
      </div>
    </div>
    <!--Static Part-->

    <div class="view_part">
      <transition name="slither" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    lang() {
      return this.$store.getters["lang_module/lang"];
    },
  },
};
</script>
