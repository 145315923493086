import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,
    state() {
        return {
            token: localStorage.getItem("Moronah_App_Token"),
            usertype: localStorage.getItem("Moronah_App_User_Type"),
            user_id: localStorage.getItem("Moronah_App_User_Id"),
            userImage: localStorage.getItem("Moronah_App_userImg"),
            userName: localStorage.getItem("Moronah_App_userName"),
            userPhone: localStorage.getItem("Moronah_App_userPhone"),
            userEmail: localStorage.getItem("Moronah_App_userEmail"),
            verifyCode: localStorage.getItem("Moronah_App_verifyCode"),
        };
    },
    mutations,
    actions,
    getters,
};