<template>
  <div class="all_notification">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main_title">
            <h3>{{ $t("notification.title") }}</h3>
          </div>
        </div>
        <div class="col-md-12">
          <div
            class="custom_card"
            v-for="item in notifications.notifications"
            :key="item.id"
          >
            <div class="notification_card">
              <div class="note_details">
                <h4>{{ item.title }}</h4>
                <p></p>
              </div>
              <div class="date">
                <span>{{ item.created_at | moment("YYYY, MM, DD") }}</span>
                <button class="btn" @click="deleteItem(item.id)">
                  <i class="fal fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">{{
          $t("table.deletedialog.areYouSure")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
            $t("table.deletedialog.ok")
          }}</v-btn>
          <v-btn color="#F44336" @click="dialogDelete = false">{{
            $t("table.deletedialog.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `notifications/${this.itemtoDelete.id}`,
      })
        .then(() => {
          // this.notifications = this.rows.filter((item) => {
          //   return item.id != this.itemtoDelete.id;
          // });
          this.$store.dispatch("socket_module/get_notification");
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
  },
  computed: {
    notifications() {
      return this.$store.getters["socket_module/notification"];
    },
  },
  created() {
    this.$store.dispatch("socket_module/get_notification");
  },
};
</script>
