<template>
  <div class="profile_page">
    <div class="container">
      <div class="col-md-12">
        <!--Title-->
        <div class="dashboard_title">
          <h4>{{ $t("dashboard.profile.title") }}</h4>
        </div>
        <!--Title-->

        <!--Request Form-->
        <div class="request_form">
          <!--Tabs-->
          <b-tabs content-class="mt-3" v-model="tabIndex">
            <!--Personal Info-->
            <b-tab :title="$t('dashboard.profile.data.personalInfo')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-md-4">
                          <!--User Name Ar-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.nameAr")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-user"></i
                            ></span>
                            <input
                              type="text"
                              v-model="data.nameAr"
                              class="form-control"
                            />
                          </div>
                          <!--User Name Ar-->

                          <!--User Name En-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.nameEn")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-user"></i
                            ></span>
                            <input
                              type="text"
                              v-model="data.nameEn"
                              class="form-control"
                            />
                          </div>
                          <!--User Name En-->

                          <!--Email Address-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.email")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-envelope"></i
                            ></span>
                            <input
                              type="email"
                              class="form-control"
                              v-model="data.email"
                            />
                          </div>
                          <!--Email Address-->

                          <!--Country-->
                          <div
                            class="input_wrapper round_radius select with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.country")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-building"></i
                            ></span>
                            <select
                              class="form-control"
                              v-model="data.country"
                              @change="getCity"
                            >
                              <option
                                v-for="country in countries"
                                :key="country.id"
                                :value="country.id"
                              >
                                {{ country.name }}
                              </option>
                            </select>
                          </div>
                          <!--Country-->

                          <!--City-->
                          <div
                            class="input_wrapper round_radius select with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.city")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-building"></i
                            ></span>
                            <select
                              class="form-control"
                              v-model="data.city"
                              @change="getArea"
                            >
                              <option
                                v-for="city in cities"
                                :key="city.id"
                                :value="city.id"
                              >
                                {{ city.name }}
                              </option>
                            </select>
                          </div>
                          <!--City-->

                          <!--Input Wrapper National Id-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.nationalId")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-address-card"></i
                            ></span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="data.nationalId"
                            />
                          </div>
                          <!--Input Wrapper National Id--->

                          <!--Input Wrapper National Exp Date-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.nationalIdExp")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-address-card"></i
                            ></span>
                            <Datepicker
                              class="form-control"
                              v-model="data.nationalIdExp"
                              :format="formatDate"
                            ></Datepicker>
                          </div>
                          <!--Input Wrapper National Exp Date--->
                        </div>

                        <div class="col-md-4">
                          <!--Phone Number-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.phone")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-phone"></i
                            ></span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="data.phone"
                            />
                          </div>
                          <!--Phone Number-->

                          <!--Commercial Number-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.ibanNumber")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-building"></i
                            ></span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="data.ibanNumber"
                            />
                          </div>
                          <!--Commercial Number-->

                          <!--Cr Number-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.commercialRegister")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-university"></i
                            ></span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="data.commercialNum"
                            />
                          </div>
                          <!--Cr Number-->

                          <!--Cr Image-->
                          <div
                            class="input_wrapper round_radius with_icon file_input full_file with_flex"
                          >
                            <span class="icon"
                              ><i class="fal fa-image"></i
                            ></span>
                            <input
                              type="file"
                              class="form-control"
                              @change="commercialImage"
                            />
                            <label class="form-control">
                              <span v-if="!commercial_image.name">{{
                                $t("auth.data.commercialImage")
                              }}</span>
                              <span v-else>{{
                                commercial_image.name.substring(0, 30)
                              }}</span>
                            </label>
                            <a
                              :href="commercial_image.name"
                              class="see_link"
                              target="_blank"
                              ><i class="fas fa-eye"></i
                            ></a>
                          </div>
                          <!--Cr Image-->

                          <!--Vat Number-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.vatNumber")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-university"></i
                            ></span>
                            <input
                              type="number"
                              :placeholder="$t('auth.data.vatNumber')"
                              class="form-control"
                              v-model="data.vatNumber"
                            />
                          </div>
                          <!--Vat Number-->

                          <!--Vat Image-->
                          <div
                            class="input_wrapper round_radius with_icon file_input full_file with_flex"
                          >
                            <span class="icon"
                              ><i class="fal fa-image"></i
                            ></span>
                            <input
                              type="file"
                              class="form-control"
                              @change="vatImage"
                            />
                            <label class="form-control">
                              <span v-if="!vat_image.name">{{
                                $t("auth.data.vatImage")
                              }}</span>
                              <span v-else>{{
                                vat_image.name.substring(0, 30)
                              }}</span>
                            </label>
                            <a
                              :href="vat_image.name"
                              class="see_link"
                              target="_blank"
                              ><i class="fas fa-eye"></i
                            ></a>
                          </div>
                          <!--Vat Image-->

                          <!--Input Wrapper Import Number-->
                          <div
                            class="input_wrapper round_radius with_icon top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.importNumber")
                            }}</label>
                            <span class="icon"
                              ><i class="fal fa-address-card"></i
                            ></span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="data.importNumber"
                            />
                          </div>
                          <!--Input Wrapper Import Number--->
                        </div>

                        <div class="col-md-4">
                          <div class="request_actions">
                            <!--Upload Image-->
                            <uplode-image
                              @inputChanged="uplodeImg_1"
                              :data_src="data.avatar"
                            ></uplode-image>
                            <!--Upload Image-->
                            <!-- <button type="button" class="order_button button">
                              <i class="fas fa-spinner"></i>
                              {{ $t("saveBtn") }}
                            </button> -->
                          </div>
                        </div>
                      </div>
                      <div class="flex_buttons">
                        <button
                          type="button"
                          class="order_button button"
                          @click="tabIndex++"
                        >
                          {{ $t("next") }}
                        </button>
                        <button
                          ref="update"
                          type="button"
                          class="order_button button"
                          @click="updateProfile"
                        >
                          <i class="fas fa-spinner"></i>
                          {{ $t("editBtn") }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Personal Info-->

            <!--Resident Info-->
            <b-tab :title="$t('dashboard.profile.data.addresses')">
              <div class="row">
                <div class="col-md-11">
                  <!-- Actions-->
                  <div class="reports_actions">
                    <!--Create New-->
                    <div class="cre_req">
                      <router-link class="new_req" to="/addAddress"
                        >{{ $t("address.addNew") }} <i class="fal fa-plus"></i
                      ></router-link>
                    </div>
                    <!-- New-->
                  </div>
                  <!--Reports Actions-->
                  <!-- Start Main Section -->
                  <v-data-table
                    class="elevation-1 thumb"
                    :headers="headers"
                    :items="rows"
                    :search="search"
                    :loading="lodaing"
                    :loading-text="$t('table.loadingData')"
                    v-model="selected"
                    item-key="id"
                    :items-per-page="paginations.items_per_page"
                    hide-default-footer
                  >
                    <!-- ================== You Can use any slots you want ================== -->
                    <!-- ====== Select row field ====== -->
                    <!-- Select actions-->

                    <template v-slot:[`item.actions`]="{ item }">
                      <div class="_actions">
                        <v-icon class="edit" small @click="editItem(item)">
                          fal fa-edit
                        </v-icon>
                        <v-icon class="delete" small @click="deleteItem(item)">
                          fal fa-trash-alt
                        </v-icon>
                      </div>
                    </template>

                    <!-- ======================== Start Top Section ======================== -->
                    <template v-slot:top>
                      <!-- Delete dialog -->
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5 justify-center">{{
                            $t("table.deletedialog.areYouSure")
                          }}</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
                              $t("table.deletedialog.ok")
                            }}</v-btn>
                            <v-btn
                              color="#F44336"
                              @click="dialogDelete = false"
                              >{{ $t("table.deletedialog.cancel") }}</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                        v-model="dialogDelete_selected"
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-title class="text-h5 justify-center">{{
                            $t("table.deletedialog.areYouSure")
                          }}</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="#1B5E20"
                              @click="deleteSelectedConfirm"
                              >{{ $t("table.deletedialog.ok") }}</v-btn
                            >
                            <v-btn
                              color="#F44336"
                              @click="dialogDelete_selected = false"
                              >{{ $t("table.deletedialog.cancel") }}</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- Add & Delete -->
                      <v-row>
                        <v-col cols="12" sm="12">
                          <!-- Search -->
                          <v-text-field
                            v-model="search"
                            :label="$t('table.search')"
                            class="mx-4"
                            append-icon="mdi-magnify"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </template>
                    <!-- ======================== End Top Section ======================== -->
                  </v-data-table>
                  <!-- End Main Section -->

                  <!-- Start Pagination -->
                  <template>
                    <div class="pagination_container text-center mb-5">
                      <v-pagination
                        v-model="paginations.current_page"
                        :length="paginations.last_page"
                        :total-visible="6"
                        prev-icon="fal fa-angle-left"
                        next-icon="fal fa-angle-right"
                      ></v-pagination>
                    </div>
                  </template>
                  <!-- End Pagination -->

                  <div class="flex_buttons">
                    <button
                      type="button"
                      class="order_button button"
                      @click="tabIndex--"
                    >
                      {{ $t("previous") }}
                    </button>

                    <button
                      type="button"
                      class="order_button button"
                      @click="tabIndex++"
                    >
                      {{ $t("next") }}
                    </button>
                  </div>

                  <!-- Start Image_Model -->
                  <base-model
                    @closeModel="model_1.show_model = false"
                    :show="model_1.show_model"
                  >
                    <div class="image">
                      <img
                        v-if="model_1.model_img_src"
                        :src="model_1.model_img_src"
                        alt="..."
                      />
                    </div>
                  </base-model>
                  <!-- End Image_Model -->
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Resident Info-->

            <!--Other Data-->
            <b-tab :title="$t('dashboard.profile.data.authrization')">
              <div class="row">
                <div class="col-md-11">
                  <!-- Actions-->
                  <div class="reports_actions">
                    <!--Create New-->
                    <div class="cre_req">
                      <router-link class="new_req" to="/addAuthrization"
                        >{{ $t("authrization.addNew") }}
                        <i class="fal fa-plus"></i
                      ></router-link>
                    </div>
                    <!-- New-->
                  </div>
                  <!--Reports Actions-->
                  <!-- Start Main Section -->
                  <v-data-table
                    class="elevation-1 thumb"
                    :headers="headers2"
                    :items="rows2"
                    :search="search"
                    :loading="lodaing"
                    :loading-text="$t('table.loadingData')"
                    v-model="selected"
                    item-key="id"
                    :items-per-page="paginations.items_per_page"
                    hide-default-footer
                  >
                    <!-- ================== You Can use any slots you want ================== -->
                    <!-- ====== Select row field ====== -->
                    <!-- Select actions-->

                    <template v-slot:[`item.actions`]="{ item }">
                      <div class="_actions">
                        <!-- <v-icon class="edit" small @click="editItem(item)">
                          fal fa-edit
                        </v-icon> -->
                        <v-icon class="delete" small @click="deleteItem2(item)">
                          fal fa-trash-alt
                        </v-icon>
                      </div>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <div class="status">
                        <v-chip
                          class="ma-2"
                          color="red"
                          text-color="white"
                          v-if="!item.is_valid"
                        >
                          {{ $t("ended") }}
                        </v-chip>

                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                          v-if="item.is_valid"
                        >
                          {{ $t("continue") }}
                        </v-chip>
                      </div>
                    </template>

                    <!-- ======================== Start Top Section ======================== -->
                    <template v-slot:top>
                      <!-- Delete dialog -->
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5 justify-center">{{
                            $t("table.deletedialog.areYouSure")
                          }}</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
                              $t("table.deletedialog.ok")
                            }}</v-btn>
                            <v-btn
                              color="#F44336"
                              @click="dialogDelete = false"
                              >{{ $t("table.deletedialog.cancel") }}</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                        v-model="dialogDelete_selected"
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-title class="text-h5 justify-center">{{
                            $t("table.deletedialog.areYouSure")
                          }}</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="#1B5E20"
                              @click="deleteSelectedConfirm"
                              >{{ $t("table.deletedialog.ok") }}</v-btn
                            >
                            <v-btn
                              color="#F44336"
                              @click="dialogDelete_selected = false"
                              >{{ $t("table.deletedialog.cancel") }}</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- Add & Delete -->
                      <v-row>
                        <v-col cols="12" sm="12">
                          <!-- Search -->
                          <v-text-field
                            v-model="search2"
                            :label="$t('table.search')"
                            class="mx-4"
                            append-icon="mdi-magnify"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </template>
                    <!-- ======================== End Top Section ======================== -->
                  </v-data-table>
                  <!-- End Main Section -->

                  <!-- Start Pagination -->
                  <template>
                    <div class="pagination_container text-center mb-5">
                      <v-pagination
                        v-model="paginations.current_page"
                        :length="paginations.last_page"
                        :total-visible="6"
                        prev-icon="fal fa-angle-left"
                        next-icon="fal fa-angle-right"
                      ></v-pagination>
                    </div>
                  </template>
                  <!-- End Pagination -->

                  <div class="flex_buttons">
                    <button
                      type="button"
                      class="order_button button"
                      @click="tabIndex--"
                    >
                      {{ $t("previous") }}
                    </button>
                  </div>

                  <!-- Start Image_Model -->
                  <base-model
                    @closeModel="model_1.show_model = false"
                    :show="model_1.show_model"
                  >
                    <div class="image">
                      <img
                        v-if="model_1.model_img_src"
                        :src="model_1.model_img_src"
                        alt="..."
                      />
                    </div>
                  </base-model>
                  <!-- End Image_Model -->
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Other Data-->
          </b-tabs>
          <!--Tabs-->
        </div>
        <!--Request Form-->
      </div>
    </div>
    <!-- Contact Dialog-->
    <v-dialog v-model="show_contact" class="custom_dialog contact_dialog">
      <!--Dialog Title-->
      <div class="dialog_title">
        <h4>تواصل مع الدعم الفني</h4>
        <p>اختر ما هي مشكلتك وسوف يتم التواصل معك على الفور</p>
        <form>
          <div class="row">
            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-md-8">
              <!--Problem Details-->
              <div class="input_wrapper round_radius">
                <textarea
                  placeholder="تفاصيل المشكلة"
                  class="form-control"
                ></textarea>
              </div>
              <!--Problem Details-->
            </div>

            <div class="col-md-4">
              <div class="request_actions">
                <button type="button" class="order_button button">
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendBtn") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--Dialog Title-->
    </v-dialog>
    <!-- Contact Dialog-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import * as moment from "moment/moment";
export default {
  data() {
    return {
      //Show Contact
      show_contact: false,
      //Show Contact
      //Countries
      countries: [],
      //Cities
      cities: [],
      //Areas
      areas: [],
      data: {
        avatar: null,
        nameAr: null,
        nameEn: null,
        email: null,
        phone: null,
        commercialNum: null,
        commercialImage: null,
        vatNumber: null,
        country: null,
        city: null,
        address: null,
        area: null,
        importNumber: null,
        nationalIdExp: null,
        nationalId: null,
        ibanNumber: null,
      },
      commercial_image: {
        file: null,
        name: null,
        path: null,
      },
      vat_image: {
        file: null,
        name: null,
        path: null,
      },

      tabIndex: 0,

      //Data Table 1
      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Search
      search: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },

      // ========== Loding
      lodaing: false,
      table_length: null,

      //Data Table 2
      // ========== dialog Status
      dialogDelete2: false,
      dialogDelete_selected2: false,
      itemtoDelete2: null,

      // ========== Model
      model_2: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Search
      search2: "",

      // ========== Your Data
      rows2: [], // injected in created

      // ========== elected Data
      selected2: [],
      singleSelect2: false,

      // ========== pagination
      paginations2: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },

      // ========== Loding
      lodaing2: false,
      table_length2: null,
    };
  },
  watch: {
    "paginations.current_page"(newVal) {
      // this.setRows(newVal);
      this.setRows(newVal);
    },
    "paginations2.current_page"(newVal) {
      // this.setRows(newVal);
      this.setRows2(newVal);
    },
  },
  methods: {
    // Uplode Avatar
    uplodeImg_1(obj) {
      this.data.avatar = obj;
    },
    // Uplode Avatar

    //Show Contract
    showContact() {
      this.show_contact = true;
    },
    //Show Contract

    // START:: COMMERICAL IMAGE METHOD
    commercialImage(e) {
      this.commercial_image.file = e.target.files[0];
      this.commercial_image.name = e.target.files[0].name;
      this.commercial_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: COMMERICAL IMAGE METHOD

    // START:: VAT IMAGE METHOD
    vatImage(e) {
      this.vat_image.file = e.target.files[0];
      this.vat_image.name = e.target.files[0].name;
      this.vat_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: VAT IMAGE METHOD

    // START:: GET COUNTRY
    getCountry() {
      this.$axios({
        method: "GET",
        url: "countries",
      }).then((res) => {
        this.countries = res.data.data;
        this.getCity();
      });
    },
    // END:: GET COUNTRY

    // START:: GET CITY
    getCity() {
      this.$axios({
        method: "GET",
        url: "cities",
        params: { country_id: this.data.country },
      }).then((res) => {
        this.cities = res.data.data;
        this.getArea();
      });
    },
    // END:: GET CITY

    // START:: GET AREA
    getArea() {
      this.$axios({
        method: "GET",
        url: "areas",
        params: { country_id: this.data.country, city_id: this.data.city },
      }).then((res) => {
        this.areas = res.data.data;
      });
    },
    // END:: GET AREA

    //Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: "profile",
        headers: {
          Authorization: "bearer" + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.data.nameAr = res.data.data.name;
        this.data.nameEn = res.data.data.name_en;
        this.data.phone = res.data.data.mobile;
        this.data.email = res.data.data.email;
        this.data.commercialNum = res.data.data.cr_number;
        this.commercial_image.name = res.data.data.cr_image;
        this.data.avatar = res.data.data.image;
        this.data.country = res.data.data.country.id;
        this.data.city = res.data.data.city.id;
        this.data.address = res.data.data.address;
        console.log(res.data.data.cr_image);
        this.data.vatNumber = res.data.data.vat_number;
        this.vat_image.name = res.data.data.vat_image;
        this.data.area = res.data.data.area.id;
        this.data.nationalIdExp = res.data.data.nid_expired_date;
        this.data.nationalId = res.data.data.nid_number;
        this.data.importNumber = res.data.data.import_number;
        this.data.ibanNumber = res.data.data.bank_account_iban;
      });
    },

    //Date Form
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //Date Form

    //Update Profile
    updateProfile() {
      var submitButton = this.$refs["update"];
      submitButton.classList.add("click");
      if (!this.data.nameAr) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("register.validation.nameAr"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.data.nameEn) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.nameEn"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else if (!this.data.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("register.validation.email"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.data.country) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.country"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.city) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.city"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.nationalId) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.nationalId"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.nationalIdExp) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.nationalIdExp"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else if (!this.data.phone) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("register.validation.phone"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.data.ibanNumber) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.ibanNumber"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else if (!this.data.commercialNum) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("register.validation.commercialRegister"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.data.vatNumber) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.vatNumber"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.importNumber) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("register.validation.importNumber"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else {
        let submit_data = new FormData();
        submit_data.append("name", this.data.nameAr);
        submit_data.append("name_en", this.data.nameEn);
        submit_data.append("email", this.data.email);
        submit_data.append("phone", this.data.phone);
        if (this.data.country) {
          submit_data.append("country_id", this.data.country);
        }
        if (this.data.city) {
          submit_data.append("city_id", this.data.city);
        }
        if (this.data.area) {
          submit_data.append("area_id", this.data.area);
        }
        submit_data.append("nid_number", this.data.nationalId);
        submit_data.append(
          "nid_expired_date",
          this.formatDate(this.data.nationalIdExp)
        );
        submit_data.append("import_number", this.data.importNumber);
        submit_data.append("cr_number", this.data.commercialNum);
        submit_data.append("vat_number", this.data.vatNumber);
        submit_data.append("bank_account_iban", this.data.commercialNum);
        if (this.commercial_image.file) {
          submit_data.append("cr_image", this.commercial_image.file);
        }
        if (this.vat_image.file) {
          submit_data.append("vat_image", this.vat_image.file);
        }
        if (this.data.avatar.img_file) {
          submit_data.append("image", this.data.avatar.img_file);
        }
        this.$axios({
          method: "POST",
          url: "profile",
          data: submit_data,
          headers: {
            Authorization:
              "bearer " + localStorage.getItem("Moronah_App_Token"),
          },
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("editSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },

    //Data Table

    // ==================== Start CRUD 1====================
    editItem(item) {
      this.$router.push({ path: "/editAddress/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `address/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "DELETE",
        url: `client/debt/delete_all`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },

    // Set Rows 1
    setRows() {
      this.$axios({
        method: "GET",
        url: "address",
        params: { page: this.paginations.current_page },
      }).then((res) => {
        this.paginations.last_page = res.data.meta.last_page;
        this.rows = res.data.data;
      });
    },

    // ==================== End CRUD 1 ====================

    // ==================== Start CRUD 2 ====================
    editItem2(item) {
      this.$router.push({ path: "/editAuthrization/" + item.id });
    },
    // ===== Delete
    deleteItem2(item) {
      this.dialogDelete2 = true;
      this.itemtoDelete2 = item;
    },
    deleteItemConfirm2() {
      this.$axios({
        method: "DELETE",
        url: `authorization/${this.itemtoDelete2.id}`,
      })
        .then(() => {
          this.rows2 = this.rows2.filter((item) => {
            return item.id != this.itemtoDelete2.id;
          });
          this.dialogDelete2 = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete2 = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected2() {
      this.dialogDelete_selected2 = true;
    },
    deleteSelectedConfirm2() {
      this.$axios({
        method: "DELETE",
        url: `authorization/delete_all`,
        data: { ids: this.selected2.map((item) => item.id) },
      })
        .then(() => {
          this.rows2 = this.rows2.filter((item) => {
            return (
              !this.selected2.filter((obj) => obj.id == item.id).length > 0
            );
          });
          this.dialogDelete_selected2 = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected2 = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },

    // ==================== End CRUD ====================

    // Set Rows
    setRows2() {
      this.$axios({
        method: "GET",
        url: "authorization",
        params: { page: this.paginations2.current_page },
      }).then((res) => {
        this.paginations2.last_page = res.data.meta.last_page;
        this.rows2 = res.data.data;
      });
    },
  },

  computed: {
    headers() {
      return [
        { text: "م", value: "id", align: "center", width: 40 },
        {
          text: "الدولة",
          value: "country.name",
          align: "center",
        },
        {
          text: "المدينة",
          value: "city.name",
          align: "center",
        },
        {
          text: "المنطقة",
          value: "area.name",
          align: "center",
        },
        {
          text: "العنوان",
          value: "address",
          align: "center",
        },
        {
          text: "وصف العنوان",
          value: "desc",
          align: "center",
        },
        {
          text: "التحكم",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
    headers2() {
      return [
        { text: "م", value: "id", align: "center", width: 40 },
        {
          text: "المنفذ الجمركي",
          value: "port.name",
          align: "center",
        },
        {
          text: "مكتب التخليص",
          value: "office.name",
          align: "center",
        },
        {
          text: "تاريخ الانتهاء",
          value: "end_date",
          align: "center",
        },
        {
          text: "رقم التفويض",
          value: "number",
          align: "center",
        },
        {
          text: "حالة التفويض",
          value: "status",
          align: "center",
        },
        {
          text: "التحكم",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  components: {
    Datepicker,
  },
  created() {
    this.getData();
    this.getCountry();
    this.setRows();
    this.setRows2();
  },
};
</script>

<style>
.see_link {
  position: relative;
  z-index: 9;
  margin-inline-start: 5px;
  background-color: #28a4cb;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 33px;
  height: 28px;
  font-size: 12px;
}
</style>
