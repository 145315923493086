<template>
  <div class="login">
    <!--Auth Form-->
    <div class="auth_form">
      <!--Logo-->
      <div class="logo">
        <img src="@/assets/images/Icons/logo.png" />
      </div>
      <!--Logo-->
      <form @submit.prevent="verifyAccount">
        <div dir="ltr" class="wrapper_flex code_wrapper input_wrapper">
          <input
            ref="firstInput"
            class="form-control"
            type="text"
            maxlength="1"
            size="1"
            @input="toggleFocus"
            v-model="data.num1"
          />
          <input
            class="form-control"
            type="text"
            maxlength="1"
            size="1"
            @input="toggleFocus"
            v-model="data.num2"
          />
          <input
            class="form-control"
            type="text"
            maxlength="1"
            size="1"
            @input="toggleFocus"
            v-model="data.num3"
          />
          <input
            class="form-control"
            type="text"
            maxlength="1"
            size="1"
            @input="toggleFocus"
            v-model="data.num4"
          />
        </div>

        <!--Login Button-->
        <button class="second_button button login_btn" ref="verify">
          <i class="fas fa-spinner"></i>
          {{ $t("auth.verify") }}
        </button>
        <!--Login Button-->
      </form>
    </div>
    <!--Auth Form-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //START INPUTS DATA
      data: {
        num1: null,
        num2: null,
        num3: null,
        num4: null,
        num5: null,
        allCode: null,
      },
      //END INPUTS DATA
    };
  },
  methods: {
    //Verify Account
    verifyAccount() {
      var submitButton = this.$refs["verify"];
      submitButton.classList.add("click");
      if (!this.data.num1) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("verify.validation.num1"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.num2) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("verify.validation.num2"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.num3) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("verify.validation.num3"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.num4) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("verify.validation.num4"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("email", this.email);
        submit_data.append("code", this.code);
        this.$axios({
          method: "post",
          url: "verify",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("auth.validation.codeTrue"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/login" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //Mobile Verify
    toggleFocus(e) {
      if (e.currentTarget.value.length == 1) {
        if (e.currentTarget.nextSibling) {
          e.currentTarget.blur();
          e.currentTarget.nextSibling.focus();
        }
      } else if (e.currentTarget.value.length == 0) {
        if (e.currentTarget.previousSibling) {
          e.currentTarget.blur();
          e.currentTarget.previousSibling.focus();
        }
      }
    },
    //Mobile Verify
  },
  mounted() {},
  computed: {
    email() {
      return this.$store.getters["auth_module/userEmail"];
    },
    code() {
      return this.$store.getters["auth_module/verifyCode"];
    },
  },
};
</script>

<style></style>
