<template>
  <header class="header">
    <nav
      class="navbar navbar-expand-lg"
      :class="{ is_sticky: scrollPosition > 50 }"
    >
      <div class="container">
        <!--Logo-->
        <router-link class="navbar-brand" to="/"
          ><img src="@/assets/images/Icons/logo.png"
        /></router-link>
        <!--Logo-->

        <!--Button Toggle On Mobile Screen-->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="showNav"
        >
          <span class="navbar-toggler-icon"><i class="fal fa-bars"></i></span>
        </button>
        <!--Button Toggle On Mobile Screen-->

        <!--Navbar Container-->
        <div class="navbar-collapse s_nav" :class="{ show: show }">
          <!--Navbar List-->
          <ul class="navbar-nav mr-auto">
            <!--Nav Item-->
            <li class="nav-item active" @click="hideNav">
              <!--Nav Link-->
              <router-link class="nav-link" to="/">{{
                $t("navbar.home")
              }}</router-link>
              <!--Nav Link-->
            </li>
            <!--Nav Item-->
            <li class="nav-item" @click="hideNav">
              <!--Nav Link-->
              <router-link class="nav-link" to="/terms">{{
                $t("navbar.terms")
              }}</router-link>
              <!--Nav Link-->
            </li>
            <!--Nav Item-->
                        <!--Nav Item-->
            <li class="nav-item" @click="hideNav">
              <!--Nav Link-->
              <router-link class="nav-link" to="/policy">{{
                $t("navbar.policy")
              }}</router-link>
              <!--Nav Link-->
            </li>
            <!--Nav Item-->
            <li class="nav-item" @click="hideNav">
              <!--Nav Link-->
              <router-link class="nav-link" to="/aboutUs">{{
                $t("navbar.aboutUs")
              }}</router-link>
              <!--Nav Link-->
            </li>
            <!--Nav Item-->
            <!--Nav Item-->
            <!--             <li class="nav-item" @click="hideNav">
              <!~~Nav Link~~>
              <router-link class="nav-link" to="/Blogs">{{
                $t("navbar.blogs")
              }}</router-link>
              <!~~Nav Link~~>
            </li> -->
            <!--Nav Item-->
            <!--Nav Item-->
            <!--             <li class="nav-item" @click="hideNav">
              <!~~Nav Link~~>
              <router-link class="nav-link" to="/contactUs">{{
                $t("navbar.contactUs")
              }}</router-link>
              <!~~Nav Link~~>
            </li> -->
            <!--Nav Item-->
          </ul>
          <!--Navbar List-->

          <!--Before Login-->
          <div class="before_login">
            <ul>
              <li class="login_link">
                <router-link to="/login">{{ $t("navbar.login") }}</router-link>
              </li>
              <li class="sign_link">
                <router-link to="/register">{{
                  $t("navbar.signUp")
                }}</router-link>
              </li>
              <!-- <li class="lang">
                <button type="button" @click="changeLang" class="btn">
                  {{ $t("lang") }}
                </button>
              </li> -->
            </ul>
          </div>
          <!--Before Login-->
          <!--Close SideMenu-->
          <div class="close_menu">
            <button class="btn" type="button" @click="hideNav">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <!--Close SideMenu-->
        </div>
        <!--Navbar Container-->
      </div>
      <!--Overlay-->
      <transition name="fadeIn" mode="in-out">
        <div class="overlay" v-if="show" @click="hideNav"></div>
      </transition>
      <!--Overlay-->
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: null,
      show: false,
    };
  },
  methods: {
    //Update Scroll
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    //Update Scroll

    //Show Side Nav
    showNav() {
      this.show = true;
    },
    //Show Side Nav
    //Hide Side Nav
    hideNav() {
      this.show = false;
    },
    //Hide Side Nav
    //Change Lang
    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
    //Change Lang
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss" scoped></style>
