<template>
  <div class="create_request pad_100">
    <div class="container">
      <div class="col-md-12">
        <!--Title-->
        <div class="dashboard_title">
          <h4>{{ $t("dashboard.requests.create") }}</h4>
        </div>
        <!--Title-->

        <!--Request Form-->
        <div class="request_form">
          <!--Tabs-->
          <b-tabs content-class="mt-3">
            <!--Request Info-->
            <b-tab :title="$t('dashboard.requests.data.requestInfo')" active>
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-md-5">
                          <!--Order Number-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.orderNum')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Order Number-->

                          <!--Place-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="text"
                              :placeholder="$t('dashboard.requests.data.place')"
                              class="form-control"
                            />
                          </div>
                          <!--Place-->

                          <!--Bian Number-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.bianNum')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Bian Number-->
                        </div>

                        <div class="col-md-5">
                          <!--Pols Number-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.polsNum')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Pols Number-->
                        </div>

                        <div class="col-md-2">
                          <div class="request_actions">
                            <button type="button" class="order_button button">
                              <i class="fas fa-spinner"></i>
                              {{ $t("saveBtn") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Request Info-->

            <!--Shipping-->
            <b-tab :title="$t('dashboard.requests.data.transport')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-md-5">
                          <!--Shipping Way-->
                          <div class="input_wrapper round_radius select">
                            <select class="form-control">
                              <option>
                                {{ $t("dashboard.requests.data.shippingWay") }}
                              </option>
                            </select>
                          </div>
                          <!--Shipping Way-->

                          <!--Shipping Type-->
                          <div class="input_wrapper round_radius select">
                            <select class="form-control">
                              <option>
                                {{ $t("dashboard.requests.data.shippingType") }}
                              </option>
                            </select>
                          </div>
                          <!--Shipping Type-->

                          <!--Country-->
                          <div class="input_wrapper round_radius select">
                            <select class="form-control">
                              <option>
                                {{ $t("dashboard.requests.data.country") }}
                              </option>
                            </select>
                          </div>
                          <!--Country-->

                          <!--Shipper Name-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="text"
                              :placeholder="
                                $t('dashboard.requests.data.shipperName')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Shipper Name-->

                          <!--Bian Number-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.bianNum')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Bian Number-->
                        </div>

                        <div class="col-md-5">
                          <!--Weight Qaem-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.weightQaem')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Weight Qaem-->

                          <!--Weight Safy-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.weightSafy')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Weight Safy-->

                          <!--Size-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="$t('dashboard.requests.data.size')"
                              class="form-control"
                            />
                          </div>
                          <!--Size-->

                          <!--Quantity Info-->
                          <div class="wrapper_flex equal_flex">
                            <!--Quentity-->
                            <div class="input_wrapper round_radius">
                              <input
                                type="number"
                                :placeholder="
                                  $t('dashboard.requests.data.quantity')
                                "
                                class="form-control"
                              />
                            </div>
                            <!--Quentity-->

                            <!--Quentity Details-->
                            <div class="input_wrapper round_radius">
                              <input
                                type="text"
                                :placeholder="
                                  $t('dashboard.requests.data.quantityDetails')
                                "
                                class="form-control"
                              />
                            </div>
                            <!--Quentity Details-->
                          </div>
                          <!--Quantity Info-->
                        </div>

                        <div class="col-md-2">
                          <div class="request_actions">
                            <button type="button" class="order_button button">
                              <i class="fas fa-spinner"></i>
                              {{ $t("saveBtn") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Shipping-->

            <!--Bill-->
            <b-tab :title="$t('dashboard.requests.data.bill')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-md-5">
                          <!--Choose Shipping-->
                          <div class="input_wrapper round_radius select">
                            <select class="form-control">
                              <option>
                                {{
                                  $t("dashboard.requests.data.chooseShipping")
                                }}
                              </option>
                            </select>
                          </div>
                          <!--Choose Shipping-->

                          <!--Bill Number-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="
                                $t('dashboard.requests.data.billNum')
                              "
                            />
                          </div>
                          <!--Bill Number-->

                          <!--Bill Term-->
                          <div class="input_wrapper round_radius select">
                            <select class="form-control">
                              <option>
                                {{ $t("dashboard.requests.data.billTerm") }}
                              </option>
                            </select>
                          </div>
                          <!--Bill Term-->

                          <!--Bill Value-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.billValue')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Bill Value-->

                          <!--Bill Value-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.billValue')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Bill Value-->
                        </div>

                        <div class="col-md-5">
                          <!--Pols Cost-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.polsCost')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Pols Cost-->

                          <!--Pols Cost Currency-->
                          <div class="input_wrapper round_radius select">
                            <select class="form-control">
                              <option>
                                {{
                                  $t("dashboard.requests.data.polsCostCurrency")
                                }}
                              </option>
                            </select>
                          </div>
                          <!--Pols Cost Currency-->

                          <!--Ensure Cost-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.ensureCost')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Ensure Cost-->

                          <!--Ensure Cost Currency-->
                          <div class="input_wrapper round_radius select">
                            <select class="form-control">
                              <option>
                                {{
                                  $t(
                                    "dashboard.requests.data.ensureCostCurrency"
                                  )
                                }}
                              </option>
                            </select>
                          </div>
                          <!--Ensure Cost Currency-->

                          <!--Other Costs-->
                          <div class="input_wrapper round_radius">
                            <input
                              type="number"
                              :placeholder="
                                $t('dashboard.requests.data.otherCosts')
                              "
                              class="form-control"
                            />
                          </div>
                          <!--Other Costs-->
                        </div>

                        <div class="col-md-2">
                          <div class="request_actions">
                            <button type="button" class="order_button button">
                              <i class="fas fa-spinner"></i>
                              {{ $t("saveBtn") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Bill-->

            <b-tab :title="$t('dashboard.requests.data.files')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-md-5">
                          <!--Bill Image-->
                          <div
                            class="wrapper_flex"
                            v-for="(item, index) in selectedFiles"
                            :key="item.id"
                          >
                            <div class="input_wrapper round_radius file_input">
                              <input
                                type="file"
                                :id="`file_input${index}`"
                                :placeholder="
                                  $t('dashboard.requests.data.shipperName')
                                "
                                class="form-control"
                                @change="handelSelectedFile"
                              />
                              <label
                                for="`file_input${index}`"
                                class="form-control"
                              >
                                <span v-if="!selectedFile.file"
                                  >صورة الفاتورة التجارية</span
                                >
                                <span v-else>{{ selectedFile.name }}</span>
                              </label>
                              <button
                                @click="handelSelectedFile"
                                type="button"
                                class="upload_file"
                              >
                                ارفاق صورة
                              </button>
                            </div>

                            <!--Action Buttons-->
                            <v-btn @click="addItem" small dark color="success">
                              <i class="fal fa-plus"></i>
                            </v-btn>
                            <v-btn
                              @click="remmoveItem(index)"
                              small
                              dark
                              color="#f00"
                              v-if="index > 0"
                            >
                              <i class="fal fa-minus"></i>
                            </v-btn>
                            <!--Action Buttons-->
                          </div>
                          <!--Bill Image-->
                        </div>

                        <div class="col-md-5">
                          <!--Polis Image-->
                          <div class="input_wrapper round_radius file_input">
                            <input
                              type="file"
                              id="file_input"
                              :placeholder="
                                $t('dashboard.requests.data.shipperName')
                              "
                              class="form-control"
                              @change="handelSelectedFile"
                            />
                            <label for="file_input" class="form-control">
                              <span v-if="!selectedFile.file"
                                >صورة البوليسة</span
                              >
                              <span v-else>{{ selectedFile.name }}</span>
                            </label>
                            <button
                              @click="handelSelectedFile"
                              type="button"
                              class="upload_file"
                            >
                              ارفاق صورة
                            </button>
                          </div>
                          <!--Polis Image-->
                        </div>

                        <div class="col-md-2">
                          <div class="request_actions">
                            <button type="button" class="order_button button">
                              <i class="fas fa-spinner"></i>
                              {{ $t("saveBtn") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <!--Tabs-->
        </div>
        <!--Request Form-->
      </div>
    </div>
    <!-- Contact Dialog-->
    <v-dialog v-model="show_contact" class="custom_dialog contact_dialog">
      <!--Dialog Title-->
      <div class="dialog_title">
        <h4>تواصل مع الدعم الفني</h4>
        <p>اختر ما هي مشكلتك وسوف يتم التواصل معك على الفور</p>
        <form>
          <div class="row">
            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-md-8">
              <!--Problem Details-->
              <div class="input_wrapper round_radius">
                <textarea
                  placeholder="تفاصيل المشكلة"
                  class="form-control"
                ></textarea>
              </div>
              <!--Problem Details-->
            </div>

            <div class="col-md-4">
              <div class="request_actions">
                <button type="button" class="order_button button">
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendBtn") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--Dialog Title-->
    </v-dialog>
    <!-- Contact Dialog-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Show Contact
      show_contact: false,
      //Show Contact
      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // START:: SELECTED FILES DATA
      selectedFiles: [
        {
          file: null,
          name: null,
          path: null,
        },
      ],
      // END:: SELECTED FILES DATA
    };
  },
  methods: {
    showContact() {
      this.show_contact = true;
    },
    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: ADD NEW ITEM
    addItem() {
      this.selectedFiles.push({
        // id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        file: null,
        name: null,
        path: null,
      });
    },
    // END:: ADD NEW ITEM

    // START:: DELETE ITEM
    remmoveItem(index) {
      this.selectedFiles.splice(index, 1);
    },
    // END:: DELETE ITEM
  },
};
</script>

<style></style>
