<template>
  <div class="login">
    <!--Auth Form-->
    <div class="auth_form">
      <!--Logo-->
      <div class="logo">
        <img src="@/assets/images/Icons/logo.png" />
      </div>
      <!--Logo-->
      <form>
        <!--Input Wrapper Email-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-lock"></i></span>
          <input
            type="password"
            class="form-control"
            v-model="data.password"
            placeholder="كلمة المرور"
          />
        </div>
        <!--Input Wrapper Email-->

        <!--Input Wrapper Password-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-lock"></i></span>
          <input
            type="password"
            v-model="data.confirmPassword"
            class="form-control"
            placeholder="تأكيد كلمة المرور"
          />
        </div>
        <!--Input Wrapper Email-->

        <!--Login Button-->
        <button
          type="button"
          class="second_button button login_btn"
          @click="verifyAccount"
          ref="verify"
        >
          <i class="fas fa-spinner"></i>
          {{ $t("auth.resetPassword") }}
        </button>
        <!--Login Button-->

        <!--Have Account-->
        <div class="have_account">
          <span
            >{{ $t("auth.noAccount") }}
            <router-link to="/register">{{
              $t("auth.register")
            }}</router-link></span
          >
        </div>
        <!--Have Account-->
      </form>
    </div>
    <!--Auth Form-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Verify Data
      data: {
        password: null,
        confirmPassword: null,
      },
    };
  },
  methods: {
    //Verify Account
    verifyAccount() {
      var submitButton = this.$refs["verify"];
      submitButton.classList.add("click");
      if (!this.data.password) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.password"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.confirmPassword != this.data.password) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.confirmPassword"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("email", this.email);
        submit_data.append("password", this.data.password);
        submit_data.append("password_confirmation", this.data.confirmPassword);
        submit_data.append("code", this.code);
        this.$axios({
          method: "post",
          url: "update_password",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("auth.validation.passwordChanged"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/login" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  computed: {
    email() {
      return this.$store.getters["auth_module/userEmail"];
    },
    code() {
      return this.$store.getters["auth_module/verifyCode"];
    },
  },
};
</script>

<style></style>
