<template>
  <div class="our_location" id="loc">
    <bubble></bubble>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <!--Main Title-->
          <div class="main_title">
            <h3>{{ $t("location.title") }}</h3>
          </div>
          <!--Main Title-->
          <!--Map location-->
          <div class="map_location">
            <img src="@/assets/images/Location/map3.png" />
          </div>
          <!--Map location-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bubble from "../Global/BubbleDotted.vue";
export default {
  components: {
    Bubble,
  },
};
</script>

<style></style>
