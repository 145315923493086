<template>
  <div class="all_blogs">
    <!--Main Bg-->
    <div class="main_bg">
      <!--Particles Shape-->
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="120"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <!--Particles Shape-->
    </div>
    <!--Main Bg-->
    <!--Start Blogs-->
    <div class="singe_blog">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <!--BraedCrumbs-->
            <ul class="breadcrumb">
              <li>
                <router-link to="/">{{ $t("navbar.home") }} /</router-link>
              </li>
              <li>
                <router-link to="/">{{ $t("navbar.blogs") }} /</router-link>
              </li>
              <li><span>تفاصيل المقال</span></li>
            </ul>
            <!--BraedCrumbs-->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!--Blog Image-->
            <div class="blog_image">
              <img src="@/assets/images/Blogs/single.png" />
            </div>
            <!--Blog Image-->

            <!--Blog Exp-->
            <div class="blog_exp">
              <!--Title-->
              <div class="blog_tit">
                <h4>اسم المقال</h4>
              </div>
              <!--Date-->
              <div class="create_at">
                <span>16/ 2 / 2022</span>
                <span>2 تعليقات</span>
              </div>
              <!--Desc-->
              <div class="desc">
                <p>
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                  توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا
                  النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف
                  التى يولدها التطبيق هذا النص هو مثال لنص يمكن أن يستبدل في نفس
                  المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك
                  أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى
                  زيادة عدد الحروف التى يولدها التطبيق
                </p>
              </div>
              <div class="desc">
                <p>
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                  توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا
                  النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف
                  التى يولدها التطبيق هذا النص هو مثال لنص يمكن أن يستبدل في نفس
                  المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك
                  أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى
                  زيادة عدد الحروف التى يولدها التطبيق
                </p>
              </div>
              <div class="desc">
                <h4>هذا النص هو مثال لنص</h4>
                <p>
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                  توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا
                  النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف
                  التى يولدها التطبيق هذا النص هو مثال لنص يمكن أن يستبدل في نفس
                  المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك
                  أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى
                  زيادة عدد الحروف التى يولدها التطبيق
                </p>
              </div>
              <!--Tags-->
              <ul class="tags">
                <li><span>كلمة تعريفية</span></li>
                <li><span>كلمة تعريفية</span></li>
                <li><span>كلمة تعريفية</span></li>
              </ul>
            </div>
            <!--Blog Exp-->

            <!--Comments-->
            <div class="all_comments">
              <!--Head Title-->
              <div class="head_title">
                <h4>{{ $t("blogs.comments.title") }}</h4>
                <span>(4)</span>
              </div>
              <!--Head Title-->
              <!--Comments Content-->
              <div class="comment_content">
                <div class="comment">
                  <!--User Image-->
                  <div class="image">
                    <img src="@/assets/images/Icons/user.png" />
                  </div>
                  <!--User Image-->
                  <!--User Details-->
                  <div class="details">
                    <div class="top_det">
                      <h4>أحمد بن عبد العزيز</h4>
                      <span>30/2/2020</span>
                    </div>
                    <div class="desc">
                      <p>
                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد
                        تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد
                        مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة
                        عدد الحروف
                      </p>
                    </div>
                  </div>
                  <!--User Details-->
                </div>
              </div>
              <!--Comments Content-->
            </div>
            <!--Comments-->
          </div>
        </div>
      </div>
    </div>
    <!--End Blogs-->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
