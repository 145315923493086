import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,

    state() {
        return {
            sliders: [],
            degrees: [],
            specialization: [],
            academicYears: [],
            categories: [],
            countries: [],
            cities: [],
            messages: [],
            orders: [],
            teachers: [],
            parents: [],
            students: [],
            products: [],
            withdrawals: [],
            relayRequests: [],
            transactions: [],
            notification: [],
            loading: false,
            noteIcon: false,
            paginations: {
                current_page: 1,
                last_page: 2,
            },
        };
    },
    mutations,
    actions,
    getters,
};