<template>
  <div class="all_tablels pad_100">
    <div class="container">
      <!--Top Search-->
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="search_form">
            <input type="text" class="form-control" v-model="search" />
            <!--Filter Button-->
            <button class="btn" type="button">
              <img src="@/assets/images/Icons/bars.png" /><span
                >كل الطلبات</span
              >
              <img src="@/assets/images/Icons/arrow_down.png" />
            </button>
            <!--Filter Button-->
          </div>
        </div>
        <div class="col-md-12">
          <hr class="line" />
          <!--Reports Actions-->
          <div class="reports_actions">
            <!--Create Request-->
            <div class="cre_req">
              <router-link class="new_req" to="/newRequest"
                >انشاء طلب جديد <i class="fal fa-plus"></i
              ></router-link>
            </div>
            <!--Create Request-->

            <!--Reports Details-->
            <div class="reports_details">
              <button class="rep_btn" type="button">
                اصدار التقارير <img src="@/assets/images/Icons/graph.png" />
              </button>
              <button class="rep_btn" type="button">
                الاطلاع على التقارير
                <img src="@/assets/images/Icons/graph.png" />
              </button>
            </div>
            <!--Reports Details-->
          </div>
          <!--Reports Actions-->
        </div>
      </div>
      <!--Top Search-->
      <!-- Start Main Section -->
      <v-data-table
        class="elevation-1 thumb"
        :headers="headers"
        :items="rows"
        :search="search"
        :loading="lodaing"
        :loading-text="$t('table.loadingData')"
        v-model="selected"
        item-key="id"
        :items-per-page="paginations.items_per_page"
        hide-default-footer
      >
        <!-- ================== You Can use any slots you want ================== -->
        <!-- ====== Select row field ====== -->
        <!-- Select actions-->
        <template v-slot:[`item.details`]="{ item }">
          <div class="_actions">
            <button class="order_button" @click="showItem(item)">
              مشاهدة التفاصيل
            </button>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="_actions">
            <v-icon class="delete" small @click="deleteItem(item)">
              fal fa-trash-alt
            </v-icon>
            <v-icon class="delete" small @click="deleteItem(item)">
              fal fa-ellipsis-v
            </v-icon>
          </div>
        </template>

        <!-- ======================== Start Top Section ======================== -->
        <template v-slot:top>
          <!-- Delete dialog -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 justify-center">{{
                $t("table.deletedialog.areYouSure")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
                  $t("table.deletedialog.ok")
                }}</v-btn>
                <v-btn color="#F44336" @click="dialogDelete = false">{{
                  $t("table.deletedialog.cancel")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete_selected" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 justify-center">{{
                $t("table.deletedialog.areYouSure")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1B5E20" @click="deleteSelectedConfirm">{{
                  $t("table.deletedialog.ok")
                }}</v-btn>
                <v-btn color="#F44336" @click="dialogDelete_selected = false">{{
                  $t("table.deletedialog.cancel")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Add & Delete -->
          <v-row>
            <v-col cols="12" sm="4"> </v-col>
          </v-row>
        </template>
        <!-- ======================== End Top Section ======================== -->
      </v-data-table>
      <!-- End Main Section -->

      <!-- Start Pagination -->
      <template>
        <div class="pagination_container text-center mb-5">
          <v-pagination
            v-model="paginations.current_page"
            :length="paginations.last_page"
            :total-visible="6"
            prev-icon="fal fa-angle-left"
            next-icon="fal fa-angle-right"
          ></v-pagination>
        </div>
      </template>
      <!-- End Pagination -->

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Filter
      show_filter: false,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Search
      search: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },
      selectedItemDesc: null,
      selectedItemNotes: null,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },

      // ========== Loding
      lodaing: false,
      table_length: null,
    };
  },

  computed: {
    headers() {
      return [
        { text: "المسلسل", value: "id", align: "center", width: 40 },
        {
          text: "رقم الطلب",
          value: "order_number",
          align: "center",
          width: 40,
        },
        {
          text: "المنفذ",
          value: "port",
          align: "center",
          width: 220,
        },
        {
          text: "رقم البوليصة",
          value: "pols_number",
          align: "center",
          width: 50,
        },
        {
          text: "رقم البيان",
          value: "bian_number",
          align: "center",
          width: 70,
        },
        {
          text: "الحالة",
          value: "status",
          align: "center",
          width: 50,
        },
        {
          text: "",
          value: "details",
          align: "center",
          sortable: false,
        },
        {
          text: "التحكم",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  methods: {
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    //Show Filter
    openFilter() {
      this.show_filter = true;
    },
    closeFilter() {
      this.show_filter = false;
    },

    // Filter
    filterRows(obj) {
      console.log(obj);
    },

    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/debits/add" });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/debits/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `client/debt/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "DELETE",
        url: `client/debt/delete_all`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },

    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.rows = [
        {
          id: 1,
          order_number: 5,
          port: "طلبات مطار الملك خالد بالرياض",
          pols_number: 15556566,
          bian_number: 56655,
          status: "مدفوع",
        },
        {
          id: 2,
          order_number: 5,
          port: "طلبات مطار الملك خالد بالرياض",
          pols_number: 15556566,
          bian_number: 56655,
          status: "مدفوع",
        },
        {
          id: 3,
          order_number: 5,
          port: "طلبات مطار الملك خالد بالرياض",
          pols_number: 15556566,
          bian_number: 56655,
          status: "مدفوع",
        },
        {
          id: 4,
          order_number: 5,
          port: "طلبات مطار الملك خالد بالرياض",
          pols_number: 15556566,
          bian_number: 56655,
          status: "مدفوع",
        },
        {
          id: 5,
          order_number: 5,
          port: "طلبات مطار الملك خالد بالرياض",
          pols_number: 15556566,
          bian_number: 56655,
          status: "مدفوع",
        },
      ];
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
  },
};
</script>

<style lang="scss" scoped></style>
