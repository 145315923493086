<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <!--Logo-->
          <div class="foot_logo">
            <img src="@/assets/images/Icons/logo.png" />
            <div class="ma">
              <iframe src="http://maroof.sa/Business/GetStamp?bid=177038" style=" width: auto; height: 250px; overflow-y:hidden;  "  frameborder="0" seamless='seamless' scrollable="no"></iframe>

            </div>
          </div>
          <!--Logo-->
        </div>
        <div class="col-md-4">
          <!--Footer Links-->
          <div class="foot_links">
            <!--Title-->
            <h4>{{ $t("footer.quickLinks") }}</h4>
            <!--Title-->
            <!--Links List-->
            <ul>
              <li>
                <router-link to="/">{{ $t("footer.home") }}</router-link>
              </li>
              <li>
                <router-link to="/aboutUs">{{
                  $t("footer.about")
                }}</router-link>
              </li>
              <li>
                <a href="#val">{{ $t("footer.values") }}</a>
              </li>
              <li>
                <a href="#cli">{{ $t("footer.clients") }}</a>
              </li>
              <li>
                <a href="#loc">{{ $t("footer.ports") }}</a>
              </li>
              <!-- <li>
                <router-link to="">{{ $t("footer.blogs") }}</router-link>
              </li> -->
            </ul>
            <!--Links List-->
          </div>
          <!--Footer Links-->
        </div>
        <!--         <div class="col-md-3">
          <!~~Footer Links~~>
          <div class="foot_links">
            <!~~Title~~>
            <h4>{{ $t("footer.quickLinks") }}</h4>
            <!~~Title~~>
            <!~~Links List~~>
            <ul>
              <li>
                <router-link to="">{{ $t("footer.home") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.about") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.values") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.clients") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.ports") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.blogs") }}</router-link>
              </li>
            </ul>
            <!~~Links List~~>
          </div>
          <!~~Footer Links~~>
        </div> -->
        <div class="col-md-4">
          <!--Footer Contact-->
          <div class="foot_conract">
            <!--Title-->
            <h4>{{ $t("footer.contact.title") }}</h4>
            <!--Title-->
            <!--Desciption-->
            <p>{{ $t("footer.contact.desc") }}</p>
            <!--Desciption-->
            <!--Contact Desc-->
            <div class="contact_desc">
              <span>{{ $t("footer.contact.address") }}</span>
              <p>المملكة العربية السعودية، مدينة الدمام</p>
            </div>
            <!--Contact Desc-->
            <!--Contact Desc-->
            <div class="contact_desc">
              <span>{{ $t("footer.contact.phone") }}</span>
              <p>0554664114</p>
            </div>
            <!--Contact Desc-->
            <!--Contact Desc-->
            <div class="contact_desc">
              <span>{{ $t("footer.contact.follow") }}</span>
              <ul>
                <li>
                  <a :href="allData.linkedIn" target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
                <!-- <li>
                  <a href=""><i class="fab fa-whatsapp"></i></a>
                </li> -->
                <li>
                  <a :href="allData.twitter" target="_blank"
                    ><i class="fab fa-twitter-square"></i
                  ></a>
                </li>
                <li>
                  <a href="tel:0554664114" target="_blank"
                    ><i class="fas fa-phone"></i
                  ></a>
                </li>
                <!-- <li>
                  <a :href="allData.facebook" target="_blank"
                    ><i class="fab fa-facebook-square"></i
                  ></a>
                </li> -->
              </ul>
            </div>
            <!--Contact Desc-->
          </div>
          <!--Footer Contact-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      allData: null,
    };
  },
  methods: {
    getSetting() {
      this.$axios({
        method: "GET",
        url: "setting",
      }).then((res) => {
        this.allData = res.data.data;
        console.log(res.data.data);
      });
    },
  },
  mounted() {
    this.getSetting();
  },
};
</script>

<style scoped>

.ma{
  margin-right: -120px;
}

</style>
