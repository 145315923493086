export default {
    sliders(state) {
        return state.sliders;
    },

    degrees(state) {
        return state.degrees;
    },

    specialization(state) {
        return state.specialization;
    },

    academicYears(state) {
        return state.academicYears;
    },

    categories(state) {
        return state.categories;
    },

    countries(state) {
        return state.countries;
    },

    cities(state) {
        return state.cities;
    },

    messages(state) {
        return state.messages;
    },

    orders(state) {
        return state.orders;
    },

    teachers(state) {
        return state.teachers;
    },

    parents(state) {
        return state.parents;
    },

    students(state) {
        return state.students;
    },

    products(state) {
        return state.products;
    },

    withdrawals(state) {
        return state.withdrawals;
    },

    transactions(state) {
        return state.transactions;
    },

    relayRequests(state) {
        return state.relayRequests;
    },

    notification(state) {
        return state.notification;
    },

    loading(state) {
        return state.loading;
    },
    noteIcon(state) {
        return state.noteIcon;
    },
    lastPage(state) {
        return state.paginations.last_page;
    }
};