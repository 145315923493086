<template>
  <div>
    <Header></Header>
    <transition name="slither" mode="out-in">
      <router-view></router-view>
    </transition>
    <Footer></Footer>
    <a href="https://wa.me/966554664114" target="_blank"
      ><div class="floating-button"><i class="fab fa-whatsapp icon wa"></i></div
    ></a>
  </div>
</template>

<script>
import Header from "../components/Layouts/TheHeader.vue";
import Footer from "../components/Layouts/TheFooter.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
};
</script>
