<template>
  <footer class="dash_footer">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <!--Footer Items-->
          <div class="footer_items">
            <!--Footer Logo-->
            <div class="footer_logo">
              <img src="@/assets/images/Icons/logo.png" />
              <div class="ma">
              <iframe src="http://maroof.sa/Business/GetStamp?bid=177038" style=" width: auto; height: 250px; overflow-y:hidden;  "  frameborder="0" seamless='seamless' scrollable="no"></iframe>

            </div>
            </div>
            <!--Footer Logo-->
            <!--Footer Links-->
            <ul class="footer_links">
              <!--Link Item-->
              <!--               <li class="link_item">
                <router-link to="">الخدمات</router-link>
              </li>
              <!~~Link Item~~>
              <!~~Link Item~~>
              <li class="link_item">
                <router-link to="">أحكام و شروط</router-link>
              </li>
              <!~~Link Item~~>
              <!~~Link Item~~>
              <li class="link_item">
                <router-link to="">سياسة الخصوصية</router-link>
              </li>
              <!~~Link Item~~>
              <!~~Link Item~~>
              <li class="link_item">
                <router-link to="">اتصل بينا</router-link>
              </li> -->
              <!--Link Item-->
            </ul>
            <!--Footer Links-->

            <!--Footer Social-->
            <div class="social_links">
              <span>: تابعنا</span>
              <ul>
                <li class="linkedin">
                  <a :href="allData.linkedIn" target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
                <li class="linkedin">
                  <a :href="'tel:' + allData.phone"
                    ><i class="fas fa-phone"></i
                  ></a>
                </li>
                <li class="whatsapp">
                  <a href="https://wa.me/966554664114"
                    ><i class="fab fa-whatsapp"></i
                  ></a>
                </li>
                <li class="twitter">
                  <a :href="allData.twitter" target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <!-- <li class="facebook">
                  <a :href="allData.facebook" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li> -->
              </ul>
            </div>
            <!--Footer Social-->
          </div>
          <!--Footer Items-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      allData: null,
    };
  },
  methods: {
    getSetting() {
      this.$axios({
        method: "GET",
        url: "setting",
      }).then((res) => {
        this.allData = res.data.data;
      });
    },
  },
  created() {
    this.getSetting();
  },
};
</script>

<style scoped>

.ma{
  margin-right: -120px;
}

</style>

