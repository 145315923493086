<template>
  <div class="main_header">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <!--Start Text Part-->
          <div class="text_part">
            <!--Start Frist Title-->
            <h3>{{ $t("mainHeader.fristTitle") }}</h3>
            <!--End Frist Title-->
            <!--Start Second Title-->
            <h3 v-if="items.main_title != ''">{{ item.main_title }}</h3>
            <h3 v-else>{{ $t("mainHeader.secondTitle") }}</h3>
            <!--End Second Title-->
            <!--Start Description-->
            <p v-if="items.sub_text != ''">{{ items.sub_text }}</p>
            <p>{{ $t("mainHeader.desc") }}</p>
            <!--Start Description-->
          </div>
          <!--End Text Part-->
        </div>
        <div class="col-md-6">
          <!--Start Image Part-->
          <div class="image_part">
            <img src="@/assets/images/MainHeader/illustration1.png" />
          </div>
          <!--End Image Part-->
        </div>
      </div>
    </div>
    <vue-particles
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="120"
      shapeType="circle"
      :particleSize="5"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
  </div>
</template>

<script>
export default {
  props: ["items"],
};
</script>

<style></style>
