<template>
  <!--Start Add -->
  <div class="container">
    <div class="custom_card">
      <form>
        <div class="row">
          <div class="col-sm-12">
            <!--Top Title-->
            <!-- <div class="form_title">
              <h4>{{ $t("address.addNew") }}</h4>
            </div> -->
            <div class="dashboard_title with_flex">
              <h4>{{ $t("address.addNew") }}</h4>
              <router-link to="/profile" class="order_button button">
                {{ $t("dashboard.profile.title") }}
              </router-link>
            </div>
            <!--Top Title-->
          </div>
          <!--           <div class="col-md-6">
            <!~~Floor Number~~>
            <div class="input_wrapper round_radius top_label">
              <label class="form-label">{{ $t("address.floorNum") }}</label>
              <input
                type="number"
                v-model="data.floorNum"
                class="form-control"
              />
            </div>
            <!~~Floor Number~~>
          </div>

          <div class="col-md-6">
            <!~~Building Number~~>
            <div class="input_wrapper round_radius top_label">
              <label class="form-label">{{ $t("address.buildingNum") }}</label>
              <input
                type="number"
                v-model="data.buildingNum"
                class="form-control"
              />
            </div>
            <!~~Building Number~~>
          </div> -->

          <div class="col-md-4">
            <!--Country-->
            <div class="input_wrapper round_radius select top_label">
              <label class="form-label">{{ $t("auth.data.country") }}</label>
              <select
                class="form-control"
                v-model="data.country"
                @change="getCity"
              >
                <option
                  v-for="country in countries"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
            <!--Country-->
          </div>

          <div class="col-md-4">
            <!--City-->
            <div class="input_wrapper round_radius select top_label">
              <label class="form-label">{{ $t("auth.data.city") }}</label>
              <select
                class="form-control"
                v-model="data.city"
                @change="getArea"
              >
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
            </div>
            <!--City-->
          </div>

          <div class="col-md-4">
            <!--Area-->
            <div class="input_wrapper round_radius select top_label">
              <label class="form-label">{{ $t("auth.data.area") }}</label>
              <select class="form-control" v-model="data.area">
                <option v-for="area in areas" :key="area.id" :value="area.id">
                  {{ area.name }}
                </option>
              </select>
            </div>
            <!--Area-->
          </div>

          <div class="col-md-12">
            <!--Address-->
            <div class="input_wrapper round_radius top_label">
              <label class="form-label">{{ $t("auth.data.address") }}</label>
              <input type="text" v-model="data.address" class="form-control" />
            </div>
            <!--Address-->
          </div>

          <div class="col-md-12">
            <!--Address Number-->
            <div class="input_wrapper round_radius top_label">
              <label class="form-label">{{ $t("address.addressDesc") }}</label>
              <textarea v-model="data.desc" class="form-control"></textarea>
            </div>
            <!--Address Number-->
          </div>

          <!--Button-->
          <button
            type="button"
            class="main_button2 button"
            ref="add"
            @click="submitForm"
          >
            <i class="fas fa-spinner"></i>
            {{ $t("sendBtn") }}
          </button>
          <!--Button-->
        </div>
      </form>
    </div>
  </div>
  <!--End Add -->
</template>

<script>
export default {
  data() {
    return {
      data: {
        floorNum: null,
        buildingNum: null,
        desc: null,
        address: null,
        country: "",
        city: "",
        area: "",
      },
      //Countries
      countries: [],
      //Cities
      cities: [],
      //Areas
      areas: [],
    };
  },
  methods: {
    // START:: GET COUNTRY
    getCountry() {
      this.$axios({
        method: "GET",
        url: "countries",
      }).then((res) => {
        this.countries = res.data.data;
      });
    },
    // END:: GET COUNTRY

    // START:: GET CITY
    getCity() {
      this.$axios({
        method: "GET",
        url: "cities",
        params: { country_id: this.data.country },
      }).then((res) => {
        this.cities = res.data.data;
      });
    },
    // END:: GET CITY

    // START:: GET AREA
    getArea() {
      this.$axios({
        method: "GET",
        url: "areas",
        params: { country_id: this.data.country, city_id: this.data.city },
      }).then((res) => {
        this.areas = res.data.data;
      });
    },
    // END:: GET AREA

    //Submit Form
    submitForm() {
      var submitButton = this.$refs["add"];
      submitButton.classList.add("click");
      // if (!this.data.floorNum) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.floorNum"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.buildingNum) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.buildingNum"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else
      if (!this.data.country) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.country"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.city) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.city"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.address) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.address"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.desc) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.descAddress"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("country_id", this.data.country);
        submit_data.append("city_id", this.data.city);
        submit_data.append("area_id", this.data.area);
        submit_data.append("desc", this.data.desc);
        // submit_data.append("type", "floor");
        // submit_data.append("floor_number", "1");
        // submit_data.append("number", "1");
        submit_data.append("address", this.data.address);
        this.$axios({
          method: "POST",
          url: "address",
          headers: {
            Authorization:
              "bearer " + localStorage.getItem("Moronah_App_Token"),
          },
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("auth.validation.success"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/profile" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  mounted() {
    this.getCountry();
  },
};
</script>

<style></style>
