<template>
  <div class="service_card">
    <!--Top Shape-->
    <div class="top_shape"></div>
    <!--Top Shape-->
    <!--Start Title-->
    <div class="title">
      <h3>{{ service.title }}</h3>
    </div>
    <!--End Title-->
    <!--Start Desc-->
    <div class="desc">
      <p>{{ service.desc }}</p>
    </div>
    <!--End Desc-->
    <!--Start Image-->
    <div class="image">
      <img :src="service.image" />
    </div>
    <div class="desc" style="margin-top:20px" v-if="service.id != 4 && service.id != 6">
      
      <p>{{ $t("services.start_from") }}</p>
      <p>{{ service.price }}</p>
    </div>
    <div class="desc" style="margin-top:20px" v-if="service.id === 6">     
      <p>{{ $t("services.free_for_clients") }}</p>
    </div>

    <div class="desc" style="margin-top:20px" v-if="service.id === 4">     
      <p>{{ $t("services.price_by_distance") }}</p>
    </div>

    <!--End Image-->
  </div>
</template>

<script>
export default {
  props: ["service"],
};
</script>

<style></style>
