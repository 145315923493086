<template>
  <div class="all_static">
    <!--Main Bg-->
    <div class="main_bg">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-md-3">
            <!--Image-->
            <div class="image">
              <img src="@/assets/images/Icons/contact.png" />
            </div>
            <!--Image-->
          </div>
        </div>
      </div>
      <!--Particles Shape-->
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="120"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <!--Particles Shape-->
    </div>
    <!--Main Bg-->

    <!--Contact Us-->
    <div class="contact_us">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-8">
            <!--Main Title-->
            <div class="main_title">
              <h3>{{ $t("contactUs.title") }}</h3>
              <p>{{ $t("contactUs.desc") }}</p>
            </div>
            <!--Main Title-->

            <!--Start Contact Form-->
            <div class="contact_form">
              <!--Start Inputs Part-->
              <div class="contact_inputs">
                <form @submit.prevent="contact">
                  <!--Start Name-->
                  <div class="input_wrapper">
                    <input
                      class="form-control"
                      type="text"
                      v-model="data.name"
                      :placeholder="$t('contactUs.data.name')"
                    />
                  </div>
                  <!--End Name-->
                  <!--Start Phone Number-->
                  <div class="input_wrapper phone_num">
                    <input
                      class="form-control"
                      type="number"
                      v-model="data.phone"
                      :placeholder="$t('contactUs.data.phone')"
                    />
                  </div>
                  <!--End Phone Number-->
                  <!--Start Email-->
                  <div class="input_wrapper">
                    <input
                      class="form-control"
                      type="email"
                      v-model="data.email"
                      :placeholder="$t('contactUs.data.email')"
                    />
                  </div>
                  <!--End Email-->
                  <!--Start Content-->
                  <div class="input_wrapper">
                    <textarea
                      class="form-control"
                      v-model="data.content"
                      :placeholder="$t('contactUs.data.content')"
                    ></textarea>
                  </div>
                  <!--End Content-->
                  <!--Send Button-->
                  <button class="main_button button" ref="contact">
                    <i class="fas fa-spinner"></i>
                    {{ $t("sendBtn") }}
                  </button>
                  <!--Send Button-->
                </form>
              </div>
              <!--End Inputs Part-->
            </div>
            <!--End Contact Form-->
          </div>
        </div>
      </div>
    </div>
    <!--Contact Us-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //START INPUTS DATA
      data: {
        name: null,
        phone: null,
        email: null,
        content: null,
      },
      //END INPUTS DATA
    };
  },
  methods: {
    // Start Contact
    contact() {
      const submitButton = this.$refs["contact"];
      submitButton.classList.add("click");
      if (this.data.name == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("contactUs.validation.name"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.phone == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("contactUs.validation.phone"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.email == null) {
        submitButton.classList.remove("click");
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("contactUs.validation.email"),
          position: "bottomRight",
        });
      } else if (this.data.content == null) {
        submitButton.classList.remove("click");
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("contactUs.validation.content"),
          position: "bottomRight",
        });
      } else {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$router.push({ path: "/" });
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("sendSuccess"),
            position: "bottomRight",
          });
        }, 1000);
      }
    },
    //End Contact
  },
};
</script>

<style></style>
