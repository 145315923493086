// ======== core Vue
import Vue from "vue";

// ======== Main app
import App from "./App.vue";

// ======== Router
import router from "./router";

// ======== Store
import store from "./store";

// ======== language
store.dispatch("lang_module/handleLang"); // ==> Store (Vuex) <==
store.dispatch("theme_module/handleTheme"); // ==> Store (Vuex) <==

// ========  Start Axios
import axios from "axios";

Vue.prototype.$axios = axios; // Glopal variable

// Global headers
axios.defaults.baseURL = "https://mronah.develobug.com/api/"; // baseurl
axios.defaults.headers.common["cache-control"] = "no-cache";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["lang"] =
    store.getters["lang_module/lang"]; // ==> Store (Vuex) <==

// Set Token If Exists
if (store.getters["auth_module/token"]) {
    axios.defaults.headers.common["Authorization"] =
        "bearer " + localStorage.getItem("Moronah_App_Token");
}
// ========  End Axios

// ======== Bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
Vue.use(BootstrapVue);

// ======== iziToast
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
Vue.prototype.$iziToast = iziToast; // Glopal variable
Vue.use(iziToast);

// ======== Moment
const moment = require("moment");
// if (store.getters["lang_module/lang"] == "ar") {
//     require("moment/locale/ar");
// }
Vue.use(require("vue-moment"), {
    moment,
});

// ======== Particles
import VueParticles from 'vue-particles';
Vue.use(VueParticles);

// import Particles from "particles.vue";

// Vue.use(Particles);

// ======== AOS
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();


// ======== Carousel
import carousel from 'vue-owl-carousel'
Vue.component("carousel", carousel);

// ======== i18n
import i18n from "./i18n";

// ======= vuetify
import vuetify from "./plugins/vuetify";

// =============> Vue Slike Carousal
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// =============> Multiselect
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiselect", Multiselect);

// ========  Main Style
import "./assets/scss/main.scss";

// =============> Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        // Add your API key here
        key: "AIzaSyBI8-9ej_tnz03I0yEW-irNptyIkZ23FSs",
        libraries: "places", // This is required if you use the Auto complete plug-in
    },
});

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// Import Globbal Components
//Data Laoder
import DataLoader from "./components/Loaders/DataLoader.vue";
//Base Model
import BaseModel from "./components/UI/BaseModel.vue";
//Upload Image
import UplodeImage from "./components/Uploads/UplodeImage.vue";

// Register Globbal Components
Vue.component("vue-slick", VueSlickCarousel);
//Data Laoder
Vue.component("data-loader", DataLoader);
//Base Model
Vue.component("base-model", BaseModel);
//Upload Image
Vue.component("uplode-image", UplodeImage);

// ======== Attatch and Mount
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");