<template>
  <div class="login">
    <!--Auth Form-->
    <div class="auth_form">
      <!--Logo-->
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/Icons/logo.png" />
        </router-link>
      </div>
      <!--Logo-->
      <form @submit.prevent="login">
        <!--Input Wrapper Email-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-envelope"></i></span>
          <input
            type="email"
            class="form-control"
            v-model="data.email"
            :placeholder="this.$t('auth.data.email')"
          />
        </div>
        <!--Input Wrapper Email-->

        <!--Input Wrapper Password-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-lock"></i></span>
          <input
            type="password"
            v-model="data.password"
            class="form-control"
            :placeholder="this.$t('auth.data.password')"
          />
        </div>
        <!--Input Wrapper Email-->

        <!--Forget Password-->
        <div class="forget_password">
          <router-link to="/enterEmail">{{
            $t("auth.forgetPassword")
          }}</router-link>
        </div>
        <!--Forget Password-->

        <!--Login Button-->
        <button class="second_button button login_btn" ref="login">
          <i class="fas fa-spinner"></i>
          {{ $t("auth.login") }}
        </button>
        <!--Login Button-->

        <!--Have Account-->
        <div class="have_account">
          <span
            >{{ $t("auth.noAccount") }}
            <router-link to="/register">{{
              $t("auth.register")
            }}</router-link></span
          >
        </div>
        <!--Have Account-->
      </form>
    </div>
    <!--Auth Form-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Login Data
      data: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    //Login
    login() {
      var submitButton = this.$refs["login"];
      submitButton.classList.add("click");
      if (!this.data.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.email"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.password) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.password"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("email", this.data.email);
        submit_data.append("password", this.data.password);
        this.$axios({
          method: "POST",
          url: "login",
          data: submit_data,
        })
          .then((res) => {
            let myData = {
              token: res.data.data.token,
              userType: res.data.data.type,
              userId: res.data.data.id,
              userImage: res.data.data.image,
              userName: res.data.data.name,
              userPhone: res.data.data.mobile,
              userEmail: res.data.data.email,
            };
            setTimeout(() => {
              this.$store.dispatch("auth_module/logIn", myData);
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("auth.validation.success"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/dashboard" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
};
</script>

<style></style>
