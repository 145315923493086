import axios from "axios";

export default {
    get_sliders(context, payload) {
        let sliders = [];
        context.commit("set_loading", true);
        axios({
                method: "GET",
                url: "slider",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                sliders = res.data.data;
                context.commit("set_sliders", sliders);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_degrees(context, payload) {
        let degrees = [];
        context.commit("set_loading", true);
        axios({
                method: "GET",
                url: "degrees",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                degrees = res.data.data;
                context.commit("set_degrees", degrees);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_specialization(context, payload) {
        context.commit("set_loading", true);
        let specialization = [];
        axios({
                method: "GET",
                url: "specialization",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                specialization = res.data.data;
                context.commit("set_specialization", specialization);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_academicYears(context, payload) {
        context.commit("set_loading", true);
        let academicYears = [];
        axios({
                method: "GET",
                url: "academic-years",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                academicYears = res.data.data;
                context.commit("set_academicYears", academicYears);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_categories(context, payload) {
        context.commit("set_loading", true);
        let categories = [];
        axios({
                method: "GET",
                url: "categories",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                categories = res.data.data;
                context.commit("set_categories", categories);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_countries(context, payload) {
        context.commit("set_loading", true);
        let countries = [];
        axios({
                method: "GET",
                url: "countries",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                countries = res.data.data;
                context.commit("set_countries", countries);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_cities(context, payload) {
        context.commit("set_loading", true);
        let cities = [];
        axios({
                method: "GET",
                url: "cities",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                cities = res.data.data;
                context.commit("set_cities", cities);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_messages(context, payload) {
        context.commit("set_loading", true);
        let messages = [];
        axios({
                method: "GET",
                url: "contacts",
                params: { page: payload, is_read: "not_read", },
            })
            .then((res) => {
                context.commit("set_loading", false);
                messages = res.data.data;
                context.commit("set_messages", messages);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_orders(context, payload) {
        context.commit("set_loading", true);
        let orders = [];
        axios({
                method: "GET",
                url: "orders",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                orders = res.data.data;
                context.commit("set_orders", orders);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_teachers(context, payload) {
        context.commit("set_loading", true);
        let teachers = [];
        axios({
                method: "GET",
                url: "teachers",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                teachers = res.data.data;
                context.commit("set_teachers", teachers);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_parents(context, payload) {
        context.commit("set_loading", true);
        let parents = [];
        axios({
                method: "GET",
                url: "parents",
                params: { page: payload },
            })
            .then((res) => {
                context.commit("set_loading", false);
                parents = res.data.data;
                context.commit("set_parents", parents);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_students(context, payload) {
        context.commit("set_loading", true);
        let students = [];
        axios({
                method: "GET",
                url: payload.id ? "parents/show_children/" + payload.id : "students",
                params: !payload.id ? { page: payload.paginations } : ""
            })
            .then((res) => {
                context.commit("set_loading", false);
                students = res.data.data;
                context.commit("set_students", students);
                if (!payload.id) {
                    context.commit("set_lastPage", res.data.meta.last_page);
                }
            });
    },

    get_products(context, payload) {
        context.commit("set_loading", true);
        let products = [];
        axios({
                method: "GET",
                url: "products",
                params: { page: payload }
            })
            .then((res) => {
                context.commit("set_loading", false);
                products = res.data.data;
                context.commit("set_products", products);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_withdrawals(context, payload) {
        context.commit("set_loading", true);
        let withdrawals = [];
        axios({
                method: "GET",
                url: "withdrawals",
                params: { page: payload }
            })
            .then((res) => {
                context.commit("set_loading", false);
                withdrawals = res.data.data;
                context.commit("set_withdrawals", withdrawals);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_transactions(context, payload) {
        context.commit("set_loading", true);
        let transactions = [];
        axios({
                method: "GET",
                url: "transactions",
                params: { page: payload }
            })
            .then((res) => {
                context.commit("set_loading", false);
                transactions = res.data.data;
                context.commit("set_transactions", transactions);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_relayRequests(context, payload) {
        context.commit("set_loading", true);
        let relayRequests = [];
        axios({
                method: "GET",
                url: payload.myRoute.includes("parent") ?
                    "parents/relay_requests/" + payload.id : payload.myRoute.includes("student") ?
                    "students/relay_requests/" + payload.id : payload.myRoute.includes("teacher") ?
                    "teachers/relay_requests/" + payload.id : "subscriptions_relay_requests",
                params: { page: payload.paginations }
            })
            .then((res) => {
                context.commit("set_loading", false);
                relayRequests = res.data.data;
                context.commit("set_relayRequests", relayRequests);
                context.commit("set_lastPage", res.data.meta.last_page);
            });
    },

    get_notification(context) {
        let notification = [];
        axios({
            method: "GET",
            url: "notifications",
            headers: {
                Authorization: "bearer " + localStorage.getItem("Moronah_App_Token")
            }
        }).then((res) => {
            notification = res.data.data;
            context.commit("set_notification", notification);
        })
    },
};