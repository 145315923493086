<template>
  <div class="all_static">
    <!--Main Bg-->
    <div class="main_bg">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-md-4">
            <!--Image-->
            <div class="image">
              <img src="@/assets/images/Icons/policy.png" />
            </div>
            <!--Image-->
          </div>
        </div>
      </div>
      <!--Particles Shape-->
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="120"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <!--Particles Shape-->
    </div>
    <!--Main Bg-->
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <!--All Text-->
          <div class="all_text about">
            <!--Main Title-->
            <div class="main_title">
              <h3>{{ $t("navbar.policy") }}</h3>
            </div>
            <!--Main Title-->
            <!--Description Text-->
            <div class="desc_text">
              <p>{{ $t("policy.main_section") }}</p>
            </div>
            <div class="desc_text">
              <h3> {{$t('policy.accept_policy')}} </h3>
            </div>
            <div class="desc_text">
              <p>{{ $t("policy.second_section") }}</p>
            </div>
            <div class="desc_text">
              <h3> {{$t('policy.who_collect_data')}} </h3>
            </div>
            <div class="desc_text">
              <p>{{ $t("policy.who_collect_desc") }}</p>
            </div>
            <div class="desc_text">
              <h5> {{$t('policy.links_with_other_website')}} </h5>
            </div>
            <div class="desc_text">
              <p>{{ $t("policy.section3") }}</p>
            </div>
            <div class="desc_text">
              <h3> {{$t('policy.what_data_collected')}} </h3>
            </div>
            <div class="desc_text">
              <h5>{{ $t("policy.collect_data_section_1_title") }}</h5>
              <p>{{ $t("policy.collect_data_section_1_desc") }}</p>
            </div>
            <div class="desc_text">
              <h5>{{ $t("policy.collect_data_section_2_title") }}</h5>
              <p>{{ $t("policy.collect_data_section_2_desc") }}</p>
            </div>
            <div class="desc_text">
              <h5>{{ $t("policy.collect_data_section_3_title") }}</h5>
              <p>{{ $t("policy.collect_data_section_3_desc") }}</p>
            </div>
            <hr>
            <div class="desc_text">
              <h3> {{$t('policy.collect_data_purps_title')}} </h3>
              <p>{{ $t("policy.collect_data_desc_1") }}</p>
            </div>
            <div class="desc_text">
              <h5>{{ $t("policy.transfer_data") }}</h5>
              <p>{{ $t("policy.transfer_data_desc") }}</p>
            </div>
            <div class="desc_text">
              <h5>{{ $t("policy.save_data") }}</h5>
              <p>{{ $t("policy.save_date_desc") }}</p>
            </div>
            <hr>
            <div class="desc_text">
              <h3> {{$t('policy.user_rules')}} </h3>
              <p>{{ $t("policy.user_rules_desc") }}</p>
              <ol style="width: 25%;margin: 0 auto;">
                <li> {{ $t("policy.user_rules_1") }}  </li>
                <li> {{ $t("policy.user_rules_2") }}  </li>
                <li> {{ $t("policy.user_rules_3") }}  </li>
                <li> {{ $t("policy.user_rules_4") }}  </li>
              </ol>
              <p> {{ $t("policy.user_contact") }}  </p>

            </div>
            <hr>
            <div class="desc_text">
              <h3> {{$t('policy.user_contact_us')}} </h3>
              <p>{{ $t("policy.user_contact_us_desc") }}</p>
              <ol style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.user_contact_us_phone") }}  </li>
                <li> {{ $t("policy.user_contact_us_sms") }}  </li>
                <li> {{ $t("policy.user_contact_us_email") }}  </li>
              </ol>
              <p> {{ $t("policy.user_contact_us_work_hours") }}  </p>
              <p> {{$t('policy.user_contact_us_respond')}} </p>
              <p> {{$t('policy.user_contact_us_review')}} </p>
              <h5>{{ $t("policy.policy_can_change") }}</h5>
              <p>{{ $t("policy.policy_can_change_desc") }}</p>

            </div>
            <hr>
            <div class="desc_text">
              <h3> {{$t('policy.more_information')}} </h3>
              <p>{{ $t("policy.more_information_desc") }}</p>
              <h5>{{ $t("policy.save_data_princiables") }}</h5>

              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable1_tite") }}  </li>
              </ul>
              <p>{{ $t("policy.princable1_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable2_tite") }}  </li>
              </ul>
              <p>{{ $t("policy.princable2_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable3_tite") }}  </li>
              </ul>
              <p>{{ $t("policy.princable3_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable4_title") }}  </li>
              </ul>
              <p>{{ $t("policy.princable4_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable5_title") }}  </li>
              </ul>
              <p>{{ $t("policy.princable5_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable6_title") }}  </li>
              </ul>
              <p>{{ $t("policy.princable6_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable7_title") }}  </li>
              </ul>
              <p>{{ $t("policy.princable7_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable8_title") }}  </li>
              </ul>
              <p>{{ $t("policy.princable8_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable9_title") }}  </li>
              </ul>
              <p>{{ $t("policy.princable9_desc") }}</p>
              <ul style="width: 30%;margin: 0 auto;">
                <li> {{ $t("policy.princable10_title") }}  </li>
              </ul>
              <p>{{ $t("policy.princable10_desc") }}</p>
            </div>
            <hr>
             <div class="desc_text">
              <h3> {{$t('policy.share_data_list_title')}} </h3>
              <p>{{ $t("policy.user_rules_desc") }}</p>
              <ul style="width: 100%;margin: 0 auto;list-style-type:circle !important;">
                <li> {{ $t("policy.share_data_list_1") }}  </li>
                <li> {{ $t("policy.share_data_list_2") }}  </li>
                <li> {{ $t("policy.share_data_list_3") }}  </li>
                <li> {{ $t("policy.share_data_list_4") }}  </li>
                <li> {{ $t("policy.share_data_list_5") }}  </li>
                <li> {{ $t("policy.share_data_list_6") }}  </li>
                <li> {{ $t("policy.share_data_list_7") }}  </li>
              </ul>
             </div>
            <!--Description Text-->
          </div>
          <!--All Text-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
