<template>
  <div class="register">
    <!--Auth Form-->
    <div class="auth_form">
      <!--Logo-->
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/Icons/logo.png" />
        </router-link>
      </div>
      <!--Logo-->
      <form @submit.prevent="register">
        <!--Input Wrapper FullName Ar-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-user"></i></span>
          <input
            type="text"
            class="form-control"
            v-model="data.nameAr"
            :placeholder="$t('auth.data.nameAr')"
          />
        </div>
        <!--Input Wrapper FullName Ar-->

        <!--Input Wrapper FullName En-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-user"></i></span>
          <input
            type="email"
            class="form-control"
            v-model="data.nameEn"
            :placeholder="$t('auth.data.nameEn')"
          />
        </div> -->
        <!--Input Wrapper FullName En-->

        <!--Input Wrapper Personal Image--->
        <!-- <div class="input_wrapper round_radius file_input full_file with_icon">
          <span class="icon"><i class="fal fa-image"></i></span>
          <input
            type="file"
            id="file_input"
            class="form-control"
            @change="personalImage"
          />
          <label for="file_input" class="form-control">
            <span v-if="!personal_image.file">{{
              $t("auth.data.personalImage")
            }}</span>
            <span v-else>{{ personal_image.name }}</span>
          </label>
        </div> -->
        <!--Input Wrapper Personal Image--->

        <!--Input Wrapper Commercial Register-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-building"></i></span>
          <input
            type="text"
            class="form-control"
            v-model="data.commercialRegister"
            :placeholder="$t('auth.data.commercialRegister')"
          />
        </div>
        <!--Input Wrapper Commercial Register-->

        <!--Input Wrapper Commercial Image--->
        <div class="input_wrapper round_radius file_input full_file with_icon">
          <span class="icon"><i class="fal fa-building"></i></span>
          <input
            type="file"
            id="file_input"
            class="form-control"
            @change="commercialImage"
          />
          <label for="file_input" class="form-control">
            <span v-if="!commercial_image.file">{{
              $t("auth.data.commercialImage")
            }}</span>
            <span v-else>{{ commercial_image.name }}</span>
          </label>
        </div>
        <!--Input Wrapper Commercial Image--->

        <!--Input Wrapper Email-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-envelope"></i></span>
          <input
            type="email"
            class="form-control"
            v-model="data.email"
            :placeholder="$t('auth.data.email')"
          />
        </div>
        <!--Input Wrapper Email-->

        <!--Input Wrapper Phone-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-mobile"></i></span>
          <input
            type="number"
            class="form-control"
            v-model="data.phone"
            :placeholder="$t('auth.data.phone')"
          />
        </div>
        <!--Input Wrapper Phone-->

        <!--Input Wrapper Password-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-lock"></i></span>
          <input
            type="password"
            v-model="data.password"
            class="form-control"
            :placeholder="$t('auth.data.password')"
          />
        </div>
        <!--Input Wrapper Password-->

        <!--Country-->
        <!-- <div class="input_wrapper round_radius select">
          <select
            class="form-control"
            @change="getCity()"
            v-model="data.country"
          >
            <option value="" disabled>
              {{ $t("auth.data.country") }}
            </option>
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div> -->
        <!--Country-->

        <!--City-->
        <!-- <div class="input_wrapper round_radius select">
          <select class="form-control" @change="getArea" v-model="data.city">
            <option disabled>
              {{ $t("auth.data.city") }}
            </option>
            <option v-for="city in cities" :key="city.id" :value="city.id">
              {{ city.name }}
            </option>
          </select>
        </div> -->
        <!--City-->

        <!--Area-->
        <!-- <div class="input_wrapper round_radius select">
          <select class="form-control" v-model="data.area">
            <option disabled>
              {{ $t("auth.data.area") }}
            </option>
            <option v-for="area in areas" :key="area.id" :value="area.id">
              {{ area.name }}
            </option>
          </select>
        </div> -->
        <!--Area-->

        <!--Input Wrapper Address-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-map-marker-alt"></i></span>
          <input
            type="text"
            class="form-control"
            id="autocomplete_ar"
            @input="getAddressAr()"
            :placeholder="$t('auth.data.address')"
          />
        </div> -->
        <!--Input Wrapper Address-->

        <!--Input Wrapper National Id-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-address-card"></i></span>
          <input
            type="number"
            class="form-control"
            v-model="data.nationalId"
            :placeholder="$t('auth.data.nationalId')"
          />
        </div> -->
        <!--Input Wrapper National Id--->

        <!--Input Wrapper National Id-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-address-card"></i></span>
          <Datepicker
            class="form-control"
            v-model="data.nationalIdExp"
            :placeholder="$t('auth.data.nationalIdExp')"
          ></Datepicker>
        </div> -->
        <!--Input Wrapper National Id--->

        <!--Input Wrapper Import Number-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-address-card"></i></span>
          <input
            type="number"
            class="form-control"
            v-model="data.importNumber"
            :placeholder="$t('auth.data.importNumber')"
          />
        </div> -->
        <!--Input Wrapper Import Number--->

        <!--Input Wrapper Vat Number-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-address-card"></i></span>
          <input
            type="number"
            class="form-control"
            v-model="data.vatNumber"
            :placeholder="$t('auth.data.vatNumber')"
          />
        </div> -->
        <!--Input Wrapper Vat Number--->

        <!--Input Wrapper Iban Number-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-address-card"></i></span>
          <input
            type="number"
            class="form-control"
            v-model="data.ibanNumber"
            :placeholder="$t('auth.data.ibanNumber')"
          />
        </div> -->
        <!--Input Wrapper Iban Number--->

        <!--Input Wrapper Vat Image--->
        <!-- <div class="input_wrapper round_radius file_input full_file with_icon">
          <span class="icon"><i class="fal fa-image"></i></span>
          <input
            type="file"
            id="file_input"
            class="form-control"
            @change="vatImage"
          />
          <label for="file_input" class="form-control">
            <span v-if="!vat_image.file">{{ $t("auth.data.vatImage") }}</span>
            <span v-else>{{ vat_image.name }}</span>
          </label>
        </div> -->
        <!--Input Wrapper Vat Image--->

        <!--Input Wrapper Confirm Password-->
        <!-- <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-lock"></i></span>
          <input
            type="password"
            v-model="data.confirmPassword"
            class="form-control"
            :placeholder="$t('auth.data.confirmPassword')"
          />
        </div> -->
        <!--Input Wrapper Confirm Password-->
        <!--Input Wrapper Phone-->
        <div class="input_wrapper round_radius with_icon">
          <input
            type="checkbox"
            v-model="data.accpet_terms"
            class="my-checkbox"
          />
          <router-link to="/terms"
            ><span style="margin-right: 10px; color: #28a4cb">{{
              $t("accept_terms_and_conditions")
            }}</span></router-link
          >
        </div>
        <!--Input Wrapper Phone-->
        <!--register Button-->
        <button class="second_button button register_btn" ref="register">
          <i class="fas fa-spinner"></i>
          {{ $t("auth.register") }}
        </button>
        <!--register Button-->

        <!--Have Account-->
        <div class="have_account">
          <span>
            {{ $t("auth.haveAccount") }}
            <router-link to="/login">{{ $t("auth.login") }}</router-link></span
          >
        </div>
        <div class="have_account">
          <span>
            <router-link to="/policy">{{
              $t("navbar.policy")
            }}</router-link></span
          >
        </div>
        <!--Have Account-->
      </form>
    </div>
    <!--Auth Form-->
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBI8-9ej_tnz03I0yEW-irNptyIkZ23FSs"></script>
<script>
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      //Register Data
      data: {
        nameAr: null,
        nameEn: null,
        commercialRegister: null,
        email: null,
        phone: null,
        accpet_terms: false,
        nationalId: null,
        nationalIdExp: null,
        importNumber: null,
        vatNumber: null,
        ibanNumber: null,
        country: "",
        city: "",
        area: "",
        password: null,
        confirmPassword: null,
        address: null,
        lat: null,
        lng: null,
      },
      vat_image: {
        file: null,
        name: null,
        path: null,
      },
      commercial_image: {
        file: null,
        name: null,
        path: null,
      },
      personal_image: {
        file: null,
        name: null,
        path: null,
      },
      //Countries
      countries: [],
      //Cities
      cities: [],
      //Areas
      areas: [],
    };
  },
  methods: {
    // ------------- get location from map ---------------------
    getAddressAr() {
      var self = this;
      var input = document.getElementById("autocomplete_ar");
      let searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener("places_changed", function () {
        var places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function (place) {
          bounds.extend(place.geometry.location);
          place.geometry.location.lng();
          place.geometry.location.lat();
          place.formatted_address;
          self.data.lng = place.geometry.location.lng();
          self.data.lat = place.geometry.location.lat();
          self.data.address = place.formatted_address;
        });
      });
    },

    // START:: VAT IMAGE METHOD
    vatImage(e) {
      this.vat_image.file = e.target.files[0];
      this.vat_image.name = e.target.files[0].name;
      this.vat_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: VAT IMAGE METHOD

    // START:: COMMERICAL IMAGE METHOD
    commercialImage(e) {
      this.commercial_image.file = e.target.files[0];
      this.commercial_image.name = e.target.files[0].name;
      this.commercial_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: COMMERICAL IMAGE METHOD

    // START:: PERSONAL IMAGE METHOD
    personalImage(e) {
      this.personal_image.file = e.target.files[0];
      this.personal_image.name = e.target.files[0].name;
      this.personal_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: PERSONAL IMAGE METHOD

    // START:: GET COUNTRY
    getCountry() {
      this.$axios({
        method: "GET",
        url: "countries",
      }).then((res) => {
        this.countries = res.data.data;
      });
    },
    // END:: GET COUNTRY

    // START:: GET CITY
    getCity() {
      this.$axios({
        method: "GET",
        url: "cities",
        params: { country_id: this.data.country },
      }).then((res) => {
        this.cities = res.data.data;
      });
    },
    // END:: GET CITY

    // START:: GET AREA
    getArea() {
      this.$axios({
        method: "GET",
        url: "areas",
        params: { country_id: this.data.country, city_id: this.data.city },
      }).then((res) => {
        this.areas = res.data.data;
      });
    },
    // END:: GET AREA

    //Register
    register() {
      var submitButton = this.$refs["register"];
      submitButton.classList.add("click");
      if (!this.data.nameAr) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.nameAr"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.accpet_terms) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("validations.accpet_terms"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.data.nameEn) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.nameEn"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      // else if (!this.personal_image.file) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.personalImage"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else if (!this.data.commercialRegister) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.commercialRegister"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.commercial_image.file) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.commercialImage"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.email"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.phone) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.phone"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.password) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.password"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.data.country) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.country"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.city) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.city"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.area) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.area"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      // // else if (!this.data.address) {
      // //   setTimeout(() => {
      // //     submitButton.classList.remove("click");
      // //     this.$iziToast.error({
      // //       timeout: 2000,
      // //       message: this.$t("auth.validation.address"),
      // //       position: "bottomRight",
      // //     });
      // //   }, 1000);
      // // }
      // else if (!this.data.nationalId) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.nationalId"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.importNumber) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.importNumber"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.vatNumber) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.vatNumber"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.ibanNumber) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.ibanNumber"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.vat_image.file) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.vat_image"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      // else if (this.data.password != this.data.confirmPassword) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.confirmPassword"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else {
        let submit_data = new FormData();
        submit_data.append("name", this.data.nameAr);
        // submit_data.append("name_en", this.data.nameEn);
        submit_data.append("email", this.data.email);
        submit_data.append("phone", this.data.phone);
        submit_data.append("password", this.data.password);
        if (this.personal_image.file) {
          submit_data.append("image", this.personal_image.file);
        }
        submit_data.append("cr_number", this.data.commercialRegister);
        if (this.commercial_image.file) {
          submit_data.append("cr_image", this.commercial_image.file);
        }
        // submit_data.append("address", "null");
        // submit_data.append("nid_number", this.data.nationalId);
        // submit_data.append("nid_expired_date", this.data.nationalIdExp);
        // submit_data.append("country_id", this.data.country);
        // submit_data.append("city_id", this.data.city);
        // submit_data.append("area_id", this.data.area);
        // submit_data.append("country_id", this.data.country);
        // submit_data.append("vat_number", this.data.vatNumber);
        // if (this.vat_image.file) {
        //   submit_data.append("vat_image", this.vat_image.file);
        // }
        // submit_data.append("bank_account_iban", this.data.ibanNumber);
        // submit_data.append("import_number", this.data.importNumber);
        this.$axios({
          method: "post",
          url: "register",
          data: submit_data,
        })
          .then((res) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$router.push({ path: "/verifyAccount" });
              let myData = {
                code: res.data.data,
                email: this.data.email,
              };
              this.$store.dispatch("auth_module/verifyAccount", myData);
              this.$iziToast.success({
                timeout: 2000,
                message: res.data.message,
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  created() {
    this.getCountry();
  },
  components: {
    Datepicker,
  },
};
</script>

<style scoped>
.my-checkbox {
  transform: scale(2);
  margin-right: 11px;
}

.my-checkbox-x2 {
  transform: scale(3);
  margin: 40px 15px 0 0;
}

body {
  margin: 100px 10%;
  font-size: 1.2em;
  font-family: arial;
}
</style>
