<template>
  <div class="dashboard_home">
    <!--Bubble Dots-->
    <bubble-dotted></bubble-dotted>
    <!--Bubble Dots-->
    <!--Animation Circle-->
    <div class="animation_circle"></div>
    <!--Animation Circle-->
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <!--Start Our Services-->
          <div class="our_services">
            <!--Main Title-->
            <div class="main_title2">
              <h4>خدماتنا</h4>
            </div>
            <!--Main Title-->
            <!--Service Card-->
            <div class="custom_card service_card">
              <!--Card Header-->
              <div class="card_header">
                <h4>التخليص الجمركي</h4>
                <img src="@/assets/images/Dashboard/Home/custom.png" />
              </div>
              <!--Card Header-->

              <!--Card Footer-->
              <div class="card_footer">
                <router-link to="/allShipment" class="main_button2">{{
                  $t("allRequests")
                }}</router-link>
                <router-link to="/addShipment" class="plus_button"
                  ><i class="fal fa-plus"></i
                ></router-link>
              </div>
              <!--Card Footer-->
            </div>
            <!--Service Card-->

            <!--Service Card-->
            <div class="custom_card service_card come_soon">
              <!--Card Header-->
              <div class="card_header">
                <h4>شهادة سابر</h4>
                <img src="@/assets/images/Dashboard/Home/saber-logo.png" />
              </div>
              <!--Card Header-->

              <!--Card Footer-->
              <div class="card_footer">
                <router-link to="/allRequests" class="main_button2"
                  >كل الطلبات</router-link
                >
                <button class="plus_button"><i class="fal fa-plus"></i></button>
              </div>
              <!--Card Footer-->
            </div>
            <!--Service Card-->

            <!--Service Card-->
            <div class="custom_card service_card come_soon">
              <!--Card Header-->
              <div class="card_header">
                <h4>شهادة هيئة الغذاء والدواء</h4>
              </div>
              <!--Card Header-->

              <!--Card Footer-->
              <div class="card_footer">
                <router-link to="" class="main_button2">انشاء طلب</router-link>
                <button class="plus_button"><i class="fal fa-plus"></i></button>
              </div>
              <!--Card Footer-->
            </div>
            <!--Service Card-->
          </div>
          <!--End Our Services-->
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6">
              <!--Requests-->
              <div class="all_requests">
                <!--Main Title-->
                <div class="main_title2">
                  <h4>طلبات المنافذ</h4>
                </div>
                <!--Main Title-->
                <!--Request Card-->
                <div class="request_card custom_card">
                  <h4>كل المعاملات ( {{ all_count }} )</h4>
                  <apexchart
                    ref="chartId"
                    width="280"
                    type="donut"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
                <!--Request Card-->
              </div>
              <!--Requests-->
            </div>

            <div class="col-md-6">
              <!--Requests-->
              <div class="all_requests">
                <!--Main Title-->
                <div class="main_title2">
                  <h4>طلبات التخليص</h4>
                </div>
                <!--Main Title-->
                <!--Request Card-->
                <div class="request_card custom_card">
                  <h4>جميع طلبات التخليص ({{ all_count }})</h4>
                  <apexchart
                    ref="chartId2"
                    width="280"
                    type="donut"
                    :options="chartOptions3"
                    :series="series3"
                  ></apexchart>
                </div>
                <!--Request Card-->
              </div>
              <!--Requests-->
            </div>
            <div class="col-md-12">
              <!--Request Card-->
              <div class="request_card custom_card">
                <apexchart
                  ref="lineChart"
                  type="line"
                  height="270"
                  :options="chartOptions2"
                  :series="series2"
                ></apexchart>
              </div>
              <!--Request Card-->
            </div>
          </div>
        </div>
        <div class="col-md-1">
          <!--Start Icon Actions-->
          <div class="icon_actions">
            <ul>
              <!--Contact For Support-->
              <li>
                <button type="button" class="btn" @click="showContact">
                  <img src="@/assets/images/Icons/message.png" />
                </button>
              </li>
              <!--Contact For Support-->

              <!--Contact For Support-->
              <li>
                <router-link to="">
                  <img src="@/assets/images/Icons/user_icon.png" />
                </router-link>
              </li>
              <!--Contact For Support-->
            </ul>
          </div>
          <!--End Icon Actions-->
        </div>
      </div>
    </div>
    <!-- Contact Dialog-->
    <v-dialog v-model="show_contact" class="custom_dialog contact_dialog">
      <!--Dialog Title-->
      <div class="dialog_title">
        <h4>تواصل مع الدعم الفني</h4>
        <p>اختر ما هي مشكلتك وسوف يتم التواصل معك على الفور</p>
        <form>
          <div class="row">
            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-md-8">
              <!--Problem Details-->
              <div class="input_wrapper round_radius">
                <textarea
                  placeholder="تفاصيل المشكلة"
                  class="form-control"
                ></textarea>
              </div>
              <!--Problem Details-->
            </div>

            <div class="col-md-4">
              <div class="request_actions">
                <button type="button" class="order_button button">
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendBtn") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--Dialog Title-->
    </v-dialog>
    <!-- Contact Dialog-->
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="!has_authorization">
        <span @click="has_authorization = true">
          <i class="fas fa-times"></i>
        </span>
        <p>
          <router-link to="/addAuthrization"
            >ليس لديك تفويض لاضافة تفويض اضغط هنا</router-link
          >
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import BubbleDotted from "../../../components/Global/BubbleDotted.vue";
export default {
  components: { BubbleDotted },
  data() {
    return {
      chartData: null,
      has_authorization: true,
      //Chart1 Dount
      series: [20, 55],
      chartOptions: {
        chart: {
          height: 200,
          type: "radialBar",
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "16px",
          floating: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "معاملة",
                  color: "#29479A",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        labels: [],
        colors: [],
      },
      //Chart1 Dount

      //Chart2 Line
      series2: [
        {
          name: "طلب",
          data: [50, 29, 40, 25, 38, 15, 30],
        },
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "day",
          categories: [
            "السبت",
            "الاحد",
            "الاثنين",
            "الثلاثاء",
            "الاربعاء",
            "الخميس",
            "الجمعه",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
      //Chart2 Line

      //Chart3 Dount
      series3: [],
      chartOptions3: {
        chart: {
          height: 200,
          type: "radialBar",
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "16px",
          floating: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "طلب",
                  color: "#29479A",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        labels: [],
        colors: [],
      },
      //Chart3 Dount
      all_count: 0,

      //Show Contact
      show_contact: false,
      //Show Contact
    };
  },
  methods: {
    showContact() {
      this.show_contact = true;
    },
    //Get Stats
    async getData() {
      let chart_colors = [];
      let chart2_colors = [];

      let response = await this.$axios.get("search/stats");

      chart_colors = this.generateColor(response.data.data.status_labels);
      this.$refs.chartId.updateOptions({
        labels: response.data.data.status_labels,
        series: response.data.data.status_count,
        colors: chart_colors,
      });
      chart2_colors = this.generateColor(response.data.data.mode_label);
      this.$refs.chartId2.updateOptions({
        labels: response.data.data.mode_label,
        series: response.data.data.mode_count,
        colors: chart2_colors,
      });
      this.series2.data = response.data.data.chart_incoming_day;
      this.$refs.lineChart.updateOptions({
        xaxis: { categories: response.data.data.chart_label },
        series: [{ data: response.data.data.chart_incoming_day }],
      });
      console.log(response.data.data);
      this.has_authorization = response.data.data.has_authorization;
    },
    generateColor(data) {
      let colors = [];
      for (let i = 0; i < data.length; i++) {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (let x = 0; x < 6; x++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        colors.push(color);
      }
      return colors;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
