<template>
  <div class="profile_page">
    <div class="container">
      <div class="col-md-12">
        <!--Title-->
        <div class="dashboard_title with_flex">
          <div class="flex_status">
            <h4>طلب رقم ({{ data.id }})</h4>
            <div class="shape_btn" v-if="all_data.status">
              <span class="mas">{{ all_data.status.name }}</span>
              <a class="">{{ all_data.status.name }}</a>
            </div>
          </div>
          <router-link to="/allShipment" class="order_button button">
            {{ $t("allShipment") }}
          </router-link>
        </div>
        <!--Title-->

        <!--Request Form-->
        <div class="request_form">
          <!--Tabs-->
          <b-tabs content-class="mt-3" v-model="tabIndex">
            <!--Personal Info-->
            <b-tab :title="$t('dashboard.shipment.general')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.shiperName") }}</h3>
                            <h4>{{ data.shipper_name }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.polisNumber") }}</h3>
                            <h4>{{ data.polis_number }}</h4>
                          </div>
                        </div>
                        <!-- <div class="col-lg-4 widget" v-if="all_data.status">
                          <div class="card-widget after">
                            <h3>حالة الشحنة</h3>
                            <h4>{{ all_data.status.name }}</h4>
                          </div>
                        </div> -->
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.port") }}</h3>
                            <h4>{{ data.port }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.office") }}</h3>
                            <h4>{{ data.office }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.shipWay") }}</h3>
                            <h4>{{ data.ship_way }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.shipType") }}</h3>
                            <h4>{{ data.ship_type }}</h4>
                          </div>
                        </div>

                        <div
                          class="col-sm-12"
                          v-if="data.ship_way.have_container"
                        >
                          <div
                            class="row"
                            v-for="(container, index) in containers"
                            :key="container.id"
                            :id="`cont_${container.id}`"
                          >
                            <div class="col-lg-4 widget">
                              <div class="card-widget after">
                                <h3>{{ $t("auth.data.containerNumber") }}</h3>
                                <h4>{{ containers[index].number }}</h4>
                              </div>
                            </div>
                            <div class="col-lg-4 widget">
                              <div class="card-widget after">
                                <h3>{{ $t("auth.data.containerSize") }}</h3>
                                <h4>{{ containers[index].size }}</h4>
                              </div>
                            </div>
                            <div class="col-lg-4 widget">
                              <div class="card-widget after">
                                <h3>{{ $t("auth.data.containerWeight") }}</h3>
                                <h4>{{ containers[index].weight }}</h4>
                              </div>
                            </div>
                            <div class="col-lg-4 widget">
                              <div class="card-widget after">
                                <h3>
                                  {{ $t("auth.data.containerSealNumber") }}
                                </h3>
                                <h4>{{ containers[index].seal_number }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.realContry") }}</h3>
                            <h4>{{ data.country_of_origin_id }}</h4>
                          </div>
                        </div>

                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.weight") }}</h3>
                            <h4>{{ data.gross_weight }}</h4>
                          </div>
                        </div>

                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.realWeight") }}</h3>
                            <h4>{{ data.net_weight }}</h4>
                          </div>
                        </div>

                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.quantity") }}</h3>
                            <h4>{{ data.quantity }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.size") }}</h3>
                            <h4>{{ data.size }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-4 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.expectDate") }}</h3>
                            <h4>{{ data.expected_date }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-12 widget">
                          <div class="card-widget after">
                            <h3>{{ $t("auth.data.desc") }}</h3>
                            <h4>{{ data.desc }}</h4>
                          </div>
                        </div>
                      </div>
                    </form>
                    <button
                      type="button"
                      class="order_button button"
                      @click="tabIndex++"
                    >
                      {{ $t("next") }}
                    </button>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--General Info-->

            <!--Personal Info-->
            <b-tab :title="$t('dashboard.shipment.extraInfo')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.actual_date"
                        >
                          <div class="card-widget after">
                            <h3>تاريخ الوصول</h3>
                            <h4>{{ all_data.actual_date }}</h4>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.transporter"
                        >
                          <div class="card-widget after">
                            <h3>شركة التوصيل</h3>
                            <h4>{{ all_data.transporter.name }}</h4>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.transportation_number"
                        >
                          <div class="card-widget after">
                            <h3>رقم موعد النقل</h3>
                            <h4>{{ all_data.transportation_number }}</h4>
                          </div>
                        </div>
                        <div class="col-lg-4 widget" v-if="all_data.address">
                          <div class="card-widget after">
                            <h3>عنوان التوصيل</h3>
                            <h4>{{ all_data.address.desc }}</h4>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.delivery_order_number"
                        >
                          <div class="card-widget after">
                            <h3>رقم اذن التوصيل</h3>
                            <h4>{{ all_data.delivery_order_number }}</h4>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.delivery_order_date"
                        >
                          <div class="card-widget after">
                            <h3>تاريخ اذن التوصيل</h3>
                            <h4>{{ all_data.delivery_order_date }}</h4>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.declaration_number"
                        >
                          <div class="card-widget after">
                            <h3>رقم البيان الجمركي</h3>
                            <h4>{{ all_data.declaration_number }}</h4>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.declaration_date"
                        >
                          <div class="card-widget after">
                            <h3>تاريخ البيان الجمركي</h3>
                            <h4>{{ all_data.declaration_date }}</h4>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 widget"
                          v-if="all_data.fash_reference"
                        >
                          <div class="card-widget after">
                            <h3>رقم وثيقة الفسخ</h3>
                            <h4>{{ all_data.fash_reference }}</h4>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="flex_buttons">
                      <button
                        type="button"
                        class="order_button button"
                        @click="tabIndex--"
                      >
                        {{ $t("previous") }}
                      </button>
                      <button
                        type="button"
                        class="order_button button"
                        @click="tabIndex++"
                      >
                        {{ $t("next") }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--General Info-->

            <!--Other Data-->
            <b-tab :title="$t('dashboard.shipment.attachment')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-lg-6 widget">
                          <div class="card-widget after">
                            <div class="card-flex">
                              <h3>{{ $t("auth.data.invoiceImage") }}</h3>
                              <div class="card-flex">
                                <h4>
                                  <a :href="invoice_image.name" target="_blank"
                                    ><i class="fas fa-eye"></i
                                  ></a>
                                </h4>
                                <h4>
                                  <a :href="invoice_image.name" download
                                    ><i class="fas fa-download"></i
                                  ></a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 widget">
                          <div class="card-widget after">
                            <div class="card-flex">
                              <h3>{{ $t("auth.data.polisImage") }}</h3>
                              <div class="card-flex">
                                <h4>
                                  <a :href="polis_image.name" target="_blank"
                                    ><i class="fas fa-eye"></i
                                  ></a>
                                </h4>
                                <h4>
                                  <a :href="polis_image.name" download
                                    ><i class="fas fa-download"></i
                                  ></a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 widget" v-if="country_image.name">
                          <div class="card-widget after">
                            <div class="card-flex">
                              <h3>{{ $t("auth.data.countryImage") }}</h3>
                              <div class="card-flex">
                                <h4>
                                  <a :href="country_image.name" target="_blank"
                                    ><i class="fas fa-eye"></i
                                  ></a>
                                </h4>
                                <h4>
                                  <a :href="country_image.name" download
                                    ><i class="fas fa-download"></i
                                  ></a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-6 widget"
                          v-if="certificate_image.name"
                        >
                          <div class="card-widget after">
                            <div class="card-flex">
                              <h3>{{ $t("auth.data.certificateImage") }}</h3>
                              <div class="card-flex">
                                <h4>
                                  <a
                                    :href="certificate_image.name"
                                    target="_blank"
                                    ><i class="fas fa-eye"></i
                                  ></a>
                                </h4>
                                <h4>
                                  <a :href="certificate_image.name" download
                                    ><i class="fas fa-download"></i
                                  ></a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-6 widget"
                          v-if="frist_identify_image.name"
                        >
                          <div class="card-widget after">
                            <div class="card-flex">
                              <h3>البيان الجمركي</h3>
                              <div class="card-flex">
                                <h4>
                                  <a
                                    :href="frist_identify_image.name"
                                    target="_blank"
                                    ><i class="fas fa-eye"></i
                                  ></a>
                                </h4>
                                <h4>
                                  <a :href="frist_identify_image.name" download
                                    ><i class="fas fa-download"></i
                                  ></a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-6 widget"
                          v-if="last_identify_image.name"
                        >
                          <div class="card-widget after">
                            <div class="card-flex">
                              <h3>البيان النهائي</h3>
                              <div class="card-flex">
                                <h4>
                                  <a
                                    :href="last_identify_image.name"
                                    target="_blank"
                                    ><i class="fas fa-eye"></i
                                  ></a>
                                </h4>
                                <h4>
                                  <a :href="last_identify_image.name" download
                                    ><i class="fas fa-download"></i
                                  ></a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12">
                          <div class="by_flex">
                            <p>{{ $t("byUser") }}</p>
                            <p>{{ $t("byAdmin") }}</p>
                          </div>
                        </div>

                        <div class="col-md-12 all_attaches">
                          <div
                            class="widget"
                            v-for="attach in attatches"
                            :key="attach.id"
                            :id="`atth_${attach.id}`"
                            :class="{
                              user: attach.by == 'user',
                              admin: attach.by == 'admin',
                            }"
                          >
                            <div class="card-widget after">
                              <div class="card-flex">
                                <h3>{{ $t("auth.data.extraFile") }}</h3>
                                <div class="card-flex">
                                  <h4>
                                    <a :href="attach.file" target="_blank"
                                      ><i class="fas fa-eye"></i
                                    ></a>
                                  </h4>
                                  <h4>
                                    <a :href="attach.file" download
                                      ><i class="fas fa-download"></i
                                    ></a>
                                  </h4>
                                </div>
                              </div>
                              <hr />
                              <h4>{{ attach.comment }}</h4>
                            </div>
                            <!-- <div class="card-widget after">
                              <h3>{{ $t("auth.data.comment") }}</h3>
                              <h4>{{ attach.comment }}</h4>
                            </div> -->
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="row">
                            <template v-if="all_data.available_add_file">
                              <div class="col-md-6">
                                <!--Polis Image-->
                                <div
                                  class="input_wrapper round_radius file_input full_file"
                                >
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="attachFiles"
                                  />
                                  <label class="form-control">
                                    <span v-if="!attatches2.name">{{
                                      $t("auth.data.extraFile")
                                    }}</span>
                                    <span v-else>{{ attatches2.name }}</span>
                                  </label>
                                </div>
                                <!--Polis Image-->
                              </div>
                              <div class="col-md-6">
                                <!--Comment-->
                                <div
                                  class="input_wrapper round_radius top_label"
                                >
                                  <label class="form-label">{{
                                    $t("auth.data.comment")
                                  }}</label>
                                  <input
                                    type="text"
                                    v-model="data.comment"
                                    class="form-control"
                                  />
                                </div>
                                <!--Comment-->
                              </div>
                            </template>
                            <div class="col-sm-12">
                              <div class="flex_buttons">
                                <button
                                  type="button"
                                  class="order_button button"
                                  @click="tabIndex--"
                                >
                                  {{ $t("previous") }}
                                </button>
                                <button
                                  type="button"
                                  v-if="all_data.available_add_file"
                                  @click="addNewAttach"
                                  class="order_button button"
                                  ref="add2"
                                >
                                  <i class="fas fa-spinner"></i>
                                  {{ $t("add") }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Other Data-->
          </b-tabs>
          <!--Tabs-->
        </div>
        <!--Request Form-->
      </div>
    </div>
    <!-- Delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">{{
          $t("table.deletedialog.areYouSure")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
            $t("table.deletedialog.ok")
          }}</v-btn>
          <v-btn color="#F44336" @click="dialogDelete = false">{{
            $t("table.deletedialog.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete dialog2 -->
    <v-dialog v-model="dialogDelete2" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">{{
          $t("table.deletedialog.areYouSure")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#1B5E20" @click="deleteItemConfirm2">{{
            $t("table.deletedialog.ok")
          }}</v-btn>
          <v-btn color="#F44336" @click="dialogDelete2 = false">{{
            $t("table.deletedialog.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Contact Dialog-->
    <v-dialog v-model="show_contact" class="custom_dialog contact_dialog">
      <!--Dialog Title-->
      <div class="dialog_title">
        <h4>تواصل مع الدعم الفني</h4>
        <p>اختر ما هي مشكلتك وسوف يتم التواصل معك على الفور</p>
        <form>
          <div class="row">
            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-md-8">
              <!--Problem Details-->
              <div class="input_wrapper round_radius">
                <textarea
                  placeholder="تفاصيل المشكلة"
                  class="form-control"
                ></textarea>
              </div>
              <!--Problem Details-->
            </div>

            <div class="col-md-4">
              <div class="request_actions">
                <button type="button" class="order_button button">
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendBtn") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--Dialog Title-->
    </v-dialog>
    <!-- Contact Dialog-->
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  props: ["id"],
  data() {
    return {
      //Show Contact
      show_contact: false,
      //Show Contact
      //Countries
      countries: [],
      //Cities
      cities: [],
      //Areas
      areas: [],
      //Areas
      ports: [],
      //Offices
      offices: [],
      //Mode Shipping
      modeShipping: [],
      //Shipping Types
      shippingTypes: [],
      //Transporters
      transporters: [],
      //Transporters
      addresses: [],
      all_data: null,
      tabIndex: 0,
      data: {
        id: null,
        polis_number: null,
        shipper_name: null,
        ship_way: "",
        ship_type: "",
        port: "",
        office: "",
        has_office: false,
        arrive_port: "",
        loading_country: "",
        transporter: "",
        address: "",
        country: "",
        city: "",
        desc: "",
        country_of_origin_id: "",
        gross_weight: null,
        net_weight: null,
        quantity: null,
        size: null,
        expected_date: null,
        area: null,
        comment: null,
      },
      dialogDelete: false,
      itemtoDelete: null,
      dialogDelete2: false,
      itemtoDelete2: null,
      invoice_image: {
        file: null,
        name: null,
        path: null,
      },
      polis_image: {
        file: null,
        name: null,
        path: null,
      },

      country_image: {
        file: null,
        name: null,
        path: null,
      },

      certificate_image: {
        file: null,
        name: null,
        path: null,
      },

      frist_identify_image: {
        file: null,
        name: null,
        path: null,
      },

      last_identify_image: {
        file: null,
        name: null,
        path: null,
      },

      attatches2: {
        file: null,
        name: null,
        path: null,
      },

      containers: [
        // {
        //   id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        //   number: null,
        //   size: null,
        //   weight: null,
        //   seal_number: null,
        // },
      ],

      attatches: [],
    };
  },
  methods: {
    // Uplode Avatar
    uplodeImg_1(obj) {
      this.data.avatar = obj;
    },
    // Uplode Avatar

    //Show Contract
    showContact() {
      this.show_contact = true;
    },
    //Show Contract

    //Date Form
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //Date Form

    // START:: INVOICE IMAGE METHOD
    invoiceImage(e) {
      this.invoice_image.file = e.target.files[0];
      this.invoice_image.name = e.target.files[0].name;
      this.invoice_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: INVOICE IMAGE METHOD

    // START:: POLIS IMAGE METHOD
    polisImage(e) {
      this.polis_image.file = e.target.files[0];
      this.polis_image.name = e.target.files[0].name;
      this.polis_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: POLIS IMAGE METHOD

    // START:: COUNTRY IMAGE METHOD
    countryImage(e) {
      this.country_image.file = e.target.files[0];
      this.country_image.name = e.target.files[0].name;
      this.country_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: COUNTRY IMAGE METHOD

    // START:: CERTIFICATE IMAGE METHOD
    certificateImage(e) {
      this.certificate_image.file = e.target.files[0];
      this.certificate_image.name = e.target.files[0].name;
      this.certificate_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: CERTIFICATE IMAGE METHOD

    // START:: FIRST IMAGE METHOD
    firstIdentityImage(e) {
      this.frist_identify_image.file = e.target.files[0];
      this.frist_identify_image.name = e.target.files[0].name;
      this.frist_identify_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: FIRST IMAGE METHOD

    // START:: LAST IMAGE METHOD
    lastIdentityImage(e) {
      this.last_identify_image.file = e.target.files[0];
      this.last_identify_image.name = e.target.files[0].name;
      this.last_identify_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: LAST IMAGE METHOD

    // START:: FIRST IMAGE METHOD
    attachFiles(e) {
      this.attatches2.file = e.target.files[0];
      this.attatches2.name = e.target.files[0].name;
      this.attatches2.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: FIRST IMAGE METHOD

    // ADD:: ADD ITEM
    addItem() {
      this.containers.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        number: null,
        size: null,
        weight: null,
        seal_number: null,
      });
    },
    // END:: ADD ITEM

    // START:: DELETE ITEM
    remmoveItem(index) {
      this.containers.splice(index, 1);
    },
    // END:: DELETE ITEM

    // ADD:: ADD ITEM
    addAttach() {
      this.attatches.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        comment: null,
        file: null,
        name: null,
        path: null,
      });
    },
    // END:: ADD ITEM

    // START:: DELETE ITEM
    remmoveAttach(index) {
      this.attatches.splice(index, 1);
    },
    // END:: DELETE ITEM

    // START:: GET DATA
    getData() {
      //Mode Shipping
      this.$axios({
        method: "GET",
        url: "search/mode_shippings",
      }).then((res) => {
        this.modeShipping = res.data.data;
      });

      //Shipping Types
      this.$axios({
        method: "GET",
        url: "search/shipping_types",
      }).then((res) => {
        this.shippingTypes = res.data.data;
      });

      //Ports
      this.$axios({
        method: "GET",
        url: "search/ports",
      }).then((res) => {
        this.ports = res.data.data;
      });

      //Offices
      this.$axios({
        method: "GET",
        url: "search/authorized_offices",
      }).then((res) => {
        this.offices = res.data.data;
        this.data.has_office = res.data.has_offices;
      });

      //Transporters
      this.$axios({
        method: "GET",
        url: "search/transporters",
      }).then((res) => {
        this.transporters = res.data.data;
      });

      //Addresses
      this.$axios({
        method: "GET",
        url: "address",
      }).then((res) => {
        this.addresses = res.data.data;
      });

      //Ship Data
      this.$axios({
        method: "GET",
        url: "shipment/" + this.id,
      }).then((res) => {
        let myData = res.data.data;
        this.all_data = myData;
        this.data.id = myData.id;
        this.data.shipper_name = myData.shipper_name;
        this.data.polis_number = myData.bill_of_lading_number;
        this.data.port = myData.port_id.name;
        this.data.office = myData.office_id.name;
        this.data.ship_way = myData.mode_shipping_id.name;
        this.data.ship_type = myData.shipping_type_id.name;
        this.data.country_of_origin_id = myData.country_of_origin.name;
        this.data.gross_weight = myData.gross_weight;
        this.data.net_weight = myData.net_weight;
        this.data.quantity = myData.quantity;
        this.data.size = myData.size;
        this.data.expected_date = myData.expected_date;
        this.data.desc = myData.description;
        this.containers = myData.containers;
        this.attatches = myData.attaches;
        this.invoice_image.name = myData.invoice_image;
        this.polis_image.name = myData.bill_lading_file;
        this.certificate_image.name = myData.cert_image;
        this.country_image.name = myData.country_origin_image;
        this.frist_identify_image.name = myData.initial_declaration_image;
        this.last_identify_image.name = myData.final_declaration_image;
      });
    },
    // END:: GET DATA

    // START:: GET COUNTRY
    getCountry() {
      this.$axios({
        method: "GET",
        url: "search/countries",
      }).then((res) => {
        this.countries = res.data.data;
        this.getCity();
      });
    },
    // END:: GET COUNTRY

    // START:: GET CITY
    getCity() {
      this.$axios({
        method: "GET",
        url: "search/cities",
        params: { country_id: this.data.country },
      }).then((res) => {
        this.cities = res.data.data;
        this.getArea();
      });
    },
    // END:: GET CITY

    // START:: GET AREA
    getArea() {
      this.$axios({
        method: "GET",
        url: "search/areas",
        params: { country_id: this.data.country, city_id: this.data.city },
      }).then((res) => {
        this.areas = res.data.data;
      });
    },
    // END:: GET AREA

    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },

    //Delete Container
    deleteItemConfirm() {
      let submit_data = new FormData();
      submit_data.append("container_id", this.itemtoDelete.id);
      this.$axios({
        method: "post",
        url: `shipment/delete_container`,
        data: submit_data,
      })
        .then(() => {
          // this.myData.containers = this.myData.containers.filter((item) => {
          //   return item.id != this.itemtoDelete.id;
          // });
          this.getData();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    //Delete Container

    deleteItem2(item) {
      this.dialogDelete2 = true;
      this.itemtoDelete2 = item;
    },

    //Delete Container
    deleteItemConfirm2() {
      let submit_data = new FormData();
      submit_data.append("comment_id", this.itemtoDelete.id);
      this.$axios({
        method: "post",
        url: `shipment/delete_comment`,
        data: submit_data,
      })
        .then(() => {
          // this.myData.containers = this.myData.containers.filter((item) => {
          //   return item.id != this.itemtoDelete.id;
          // });
          this.getData();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    //Delete Container

    //Add New Attach
    addNewAttach() {
      var submitButton = this.$refs["add2"];
      submitButton.classList.add("click");
      if (!this.data.comment) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.comment"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.attatches2.file) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.extraFile"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("shipment_id", this.id);
        submit_data.append("file", this.attatches2.file);
        submit_data.append("comment", this.data.comment);
        this.$axios({
          method: "POST",
          url: "shipment/add_comment",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("addsuccess"),
                position: "bottomRight",
              });
              this.getData();
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //Add New Attach

    //Submit Form
    submitForm() {
      var submitButton = this.$refs["add"];
      submitButton.classList.add("click");
      if (!this.data.shipper_name) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.shipper_name"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.polis_number) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.polis_number"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.port) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.port"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.office) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.office"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.ship_way) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.ship_way"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.ship_type) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.ship_type"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.loading_country) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.loading_country"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.country_of_origin_id) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.country_of_origin_id"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.transporter) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.transporter"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.address) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.address"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.gross_weight) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.gross_weight"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.net_weight) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.net_weight"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.quantity) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.quantity"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.expected_date) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.expected_date"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.desc) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.desc"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("mode_shipping_id", this.data.ship_way.id);
        this.containers.forEach((element, index) => {
          submit_data.append(`containers[${index}][number]`, element.number);
          submit_data.append(`containers[${index}][size]`, element.size);
          submit_data.append(`containers[${index}][weight]`, element.weight);
          submit_data.append(
            `containers[${index}][seal_number]`,
            element.seal_number
          );
        });
        submit_data.append("port_id", this.data.port);
        submit_data.append("office_id", this.data.office);
        submit_data.append("shipping_type_id", this.data.ship_type);
        submit_data.append("country_loading_id", this.data.loading_country);
        submit_data.append("bill_of_lading_number", this.data.polis_number);
        submit_data.append("shipper_name", this.data.shipper_name);
        submit_data.append("gross_weight", this.data.gross_weight);
        submit_data.append("net_weight", this.data.net_weight);
        submit_data.append("quantity", this.data.quantity);
        submit_data.append("size", this.data.size);
        submit_data.append(
          "country_of_origin_id",
          this.data.country_of_origin_id
        );
        if (this.country_image.file) {
          submit_data.append(
            "country_of_origin_image",
            this.country_image.file
          );
        }
        submit_data.append("description", this.data.desc);
        submit_data.append(
          "expected_date",
          this.formatDate(this.data.expected_date)
        );
        if (this.invoice_image.file) {
          submit_data.append("invoice_image", this.invoice_image.file);
        }
        if (this.certificate_image.file) {
          submit_data.append("cert_image", this.certificate_image.file);
        }
        if (this.polis_image.file) {
          submit_data.append("bill_lading_file", this.polis_image.file);
        }
        submit_data.append("address_id", this.data.port);
        submit_data.append("transporter_id", this.data.transporter);
        this.$axios({
          method: "POST",
          url: "shipment/update_shipment/" + this.id,
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("auth.validation.success"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/profile" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  components: {
  },
  created() {
    this.getData();
    this.getCountry();
  },
};
</script>

<style>
.card-widget {
  background: #fff;
  border: 1px solid #28a4cb;
  width: 100%;
  padding: 15px;
  border-radius: 0px 20px 0 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.card-widget .card-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-widget .card-flex a {
  display: inline-block;
  margin-inline-end: 8px;
}

.card-widget h3 {
  font-size: 17px;
  color: #28a4cb;
}

.card-widget h3.status {
  position: relative;
  z-index: 1;
  padding: 6px 15px;
  display: inline-flex;
  font-size: 12px !important;
  border-radius: 5px;
  margin-bottom: 0;
  color: #fff;
}

.card-widget h4 {
  font-size: 15px;
  color: #29479a;
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.card-widget.after:after {
  content: "";
  /* background: url("~@/assets/images/Icons/logo.png"); */
  position: absolute;
  bottom: 5px;
  left: 10px;
  width: 100px;
  height: 100px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
}

.all_attaches .widget {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.by_flex {
  display: flex;
  justify-content: space-between;
}

.by_flex p {
  border-bottom: 2px solid #28a4cb;
  padding: 5px 0;
}

.all_attaches .widget.user {
  align-items: flex-start;
}

.all_attaches .widget.admin {
  align-items: flex-end;
}

.all_attaches .widget .card-widget {
  width: calc(100% - 50%);
}
.flex_status {
  display: flex;
  align-items: center;
}

.flex_status h4 {
  margin-inline-end: 6px;
}
</style>
