export default {
    token(state) {
        return state.token;
    },
    usertype(state) {
        return state.usertype;
    },
    set_userId(state) {
        return state.user_id;
    },
    userImage(state) {
        return state.userImage;
    },
    userName(state) {
        return state.userName;
    },
    userPhone(state) {
        return state.userPhone;
    },
    userEmail(state) {
        return state.userEmail;
    },
    verifyCode(state) {
        return state.verifyCode;
    },
};