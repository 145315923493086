<template>
  <!--Start Add -->
  <div class="container">
    <div class="custom_card">
      <form>
        <div class="row">
          <div class="col-sm-12">
            <!--Top Title-->
            <div class="form_title">
              <h4>{{ $t("editBtn") }}</h4>
            </div>
            <!--Top Title-->
            <!--Floor Number-->
            <!-- <div class="input_wrapper round_radius">
              <input
                type="number"
                :placeholder="$t('address.floorNum')"
                v-model="data.floorNum"
                class="form-control"
              />
            </div> -->
            <!--Floor Number-->

            <!--Floor Number-->
            <!-- <div class="input_wrapper round_radius">
              <input
                type="number"
                :placeholder="$t('address.buildingNum')"
                v-model="data.buildingNum"
                class="form-control"
              />
            </div> -->
            <!--Floor Number-->

            <!--Address Number-->
            <div class="input_wrapper round_radius">
              <textarea
                type="number"
                :placeholder="$t('address.addressDesc')"
                v-model="data.desc"
                class="form-control"
              ></textarea>
            </div>
            <!--Address Number-->

            <!--Button-->
            <button
              type="button"
              class="main_button2 button"
              ref="add"
              @click="submitForm"
            >
              <i class="fas fa-spinner"></i>
              {{ $t("sendBtn") }}
            </button>
            <!--Button-->
          </div>
        </div>
      </form>
    </div>
  </div>
  <!--End Add -->
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      data: {
        floorNum: null,
        buildingNum: null,
        desc: null,
        country: "",
        city: "",
        area: "",
      },
      //Countries
      countries: [],
      //Cities
      cities: [],
      //Areas
      areas: [],
    };
  },
  methods: {
    //Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: "address/" + this.id,
      }).then((res) => {
        this.data.floorNum = res.data.data.floor_number;
        this.data.buildingNum = res.data.data.number;
        this.data.desc = res.data.data.desc;
      });
    },
    //Get Data

    // START:: GET COUNTRY
    getCountry() {
      this.$axios({
        method: "GET",
        url: "countries",
      }).then((res) => {
        this.countries = res.data.data;
      });
    },
    // END:: GET COUNTRY

    // START:: GET CITY
    getCity() {
      this.$axios({
        method: "GET",
        url: "cities",
        params: { country_id: this.data.country },
      }).then((res) => {
        this.cities = res.data.data;
      });
    },
    // END:: GET CITY

    // START:: GET AREA
    getArea() {
      this.$axios({
        method: "GET",
        url: "areas",
        params: { country_id: this.data.country, city_id: this.data.city },
      }).then((res) => {
        this.areas = res.data.data;
      });
    },
    // END:: GET AREA

    //Submit Form
    submitForm() {
      var submitButton = this.$refs["add"];
      submitButton.classList.add("click");
      // if (!this.data.floorNum) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.floorNum"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.buildingNum) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.buildingNum"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      if (!this.data.country) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("register.validation.country"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.city) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("register.validation.city"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.area) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("register.validation.area"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.desc) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.descAddress"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = {
          floor_number: this.data.floorNum,
          number: this.data.buildingNum,
          desc: this.data.desc,
          type: "مكتب",
          address: "null",
        };
        this.$axios({
          method: "PUT",
          url: "address/" + this.id,
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("auth.validation.success"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/profile" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  created() {
    this.getData();
    this.getCountry();
  },
};
</script>

<style></style>
