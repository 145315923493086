<template>
  <div>
    <Header></Header>
    <div class="main_dash">
      <transition name="slither" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/Dashboard/Layouts/Header.vue";
import Footer from "../../components/Dashboard/Layouts/Footer.vue";
import Echo from "laravel-echo";
export default {
  data() {
    return {
      userId: null,
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    // socket
    receiveMessage() {
      if (localStorage.getItem("Moronah_App_Token") != null) {
        window.io = require("socket.io-client");
        window.Echo = new Echo({
          broadcaster: "socket.io",
          host: "https://mronah.develobug.com:6060",
          // host: "https://crm.soly-erp.com:6065",
          csrfToken: "Bearer " + localStorage.getItem("Moronah_App_Token"),
          auth: {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("Moronah_App_Token"),
            },
          },
        });
        // -----------------
        console.log(window.Echo);
        window.Echo.private(
          `mronah-user-notification.${this.userId}`
        ).notification((payload) => {
          if (payload) {
            console.log(payload);
            this.$iziToast.info({
              message: payload.title,
              backgroundColor: "#08e1ff",
              timeout: 5000,
              class: "note-alert",
              titleColor: "#0E59B6",
              position: "topRight",
            });
            this.$store.dispatch("socket_module/get_notification");
            new Audio(require("@/assets/sounds/message.mp3")).play();
          }
        });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("Moronah_App_Token") != null) {
      this.userId = localStorage.getItem("Moronah_App_User_Id");
      this.receiveMessage();
    }
    this.$store.dispatch("socket_module/get_notification");
  },
};
</script>
