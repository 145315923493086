<template>
  <div class="all_static">
    <!--Main Bg-->
    <div class="main_bg">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-md-4">
            <!--Image-->
            <div class="image">
              <img src="@/assets/images/Icons/terms.png" />
            </div>
            <!--Image-->
          </div>
        </div>
      </div>
      <!--Particles Shape-->
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="120"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <!--Particles Shape-->
    </div>
    <!--Main Bg-->
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <!--All Text-->
          <div class="all_text about">
            <!--Main Title-->
            <div class="main_title">
              <h3>{{ $t("navbar.terms") }}</h3>
            </div>
            <!--Main Title-->
            <!--Description Text-->
            <div class="desc_text">
              <p v-html='$t("terms.section1")'></p>
            </div>
            <!--Description Text-->
                        <!--Description Text-->
            <div class="desc_text">
              <p v-html='$t("terms.section2")'></p>
            </div>
            <!--Description Text-->
                                    <!--Description Text-->
            <div class="desc_text">
              <p v-html='$t("terms.section3")'></p>
            </div>
            <!--Description Text-->
            <!--Description Text-->
            <div class="desc_text">
              <ol style="text-align: right;">
                <li style="margin-top:10px">
                  <div class="main-title">
                      <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                        {{$t("terms.sub_title1")}}
                      </h3>  
                      <ol>
                        <li>{{$t("terms.sub_title1_sections.section1")}}</li>
                        <li>{{$t("terms.sub_title1_sections.section2")}}</li>
                        <li>{{$t("terms.sub_title1_sections.section3")}}</li>
                        <li>{{$t("terms.sub_title1_sections.section4")}}</li>
                        <li>{{$t("terms.sub_title1_sections.section5")}}</li>
                        <li>{{$t("terms.sub_title1_sections.section6")}}</li>
                        <li>{{$t("terms.sub_title1_sections.section7")}}</li>
                      </ol>
                  </div>
                 </li>
                 <li style="margin-top:15px">
                    <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title2")}}
                        </h3>
                    </div>

                        <ol>
                          <li>
                            <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                              {{$t("terms.sub_title2_sub1.title1")}}
                            </h3> 
                            <ol>
                              <li>{{$t("terms.sub_title2_sub1.title1_sections.section1")}}</li>
                              <li>{{$t("terms.sub_title2_sub1.title1_sections.section2")}}</li>
                            </ol>
                          </li>
                          <li>
                            <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                              {{$t("terms.sub_title2_sub1.title2")}}
                            </h3> 
                            <ol>
                              <li>{{$t("terms.sub_title2_sub1.title2_sections.section1")}}</li>
                              <li>{{$t("terms.sub_title2_sub1.title2_sections.section2")}}</li>
                              <li>{{$t("terms.sub_title2_sub1.title2_sections.section3")}}</li>
                            </ol>
                            <br>
                            <div class="desc_text">
                              <p>{{$t("terms.sub_title2_sub1.title2_sections.section4")}}</p>
                            </div>
                          </li>
                        </ol> 
                          
                 </li>
                 <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title3")}}
                        </h3>
                        <p>{{$t("terms.title3_sections.section")}} </p>
                        <ol>
                              <li>{{$t("terms.title3_sections.section1")}}</li>
                              <li>{{$t("terms.title3_sections.section2")}}</li>
                              <li>{{$t("terms.title3_sections.section3")}}</li>
                              <li>{{$t("terms.title3_sections.section4")}}</li>
                              <li>{{$t("terms.title3_sections.section5")}}</li>
                              <li>{{$t("terms.title3_sections.section6")}}</li>
                              <li>{{$t("terms.title3_sections.section7")}}</li>
                              <li>{{$t("terms.title3_sections.section8")}}</li>
                        </ol>
                     </div>
                 </li>
                 <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title4")}}
                        </h3>
                        <ol>
                              <li>{{$t("terms.title4_sections.section1")}}</li>
                              <li>{{$t("terms.title4_sections.section2")}}</li>
                              <li>{{$t("terms.title4_sections.section3")}}</li>
                              <li>{{$t("terms.title4_sections.section4")}}</li>
                              <li>{{$t("terms.title4_sections.section5")}}</li>
                              <li>{{$t("terms.title4_sections.section6")}}</li>
                              <li>{{$t("terms.title4_sections.section7")}}</li>
                        </ol>
                     </div>
                 </li>
                  <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title5")}}
                        </h3>
                        <ol>
                              <li>{{$t("terms.title5_sections.section1")}}</li>
                              <li>{{$t("terms.title5_sections.section2")}}</li>
                              <li>{{$t("terms.title5_sections.section3")}}</li>
                              <li>{{$t("terms.title5_sections.section4")}}</li>
                        </ol>
                     </div>
                  </li>
                  <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_titles6")}}
                        </h3>
                        <p>{{$t("terms.title6_sections.section")}}</p>
 
                     </div>
                  </li>
                  <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_titles7")}}
                        </h3>
                        <p>{{$t("terms.title7_sections.section1")}}</p>
 
                     </div>
                  </li>
                  <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_titles8")}}
                        </h3>
                        <p>{{$t("terms.title8_sections.section")}}</p>
 
                     </div>
                  </li>
                  <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title9")}}
                        </h3>
                        <ol>
                              <li>{{$t("terms.title9_sections.section1")}}</li>
                              <li>{{$t("terms.title9_sections.section2")}}</li>
                              <li>{{$t("terms.title9_sections.section3")}}</li>
                              <li>{{$t("terms.title9_sections.section4")}}</li>
                              <li>{{$t("terms.title9_sections.section5")}}</li>
                        </ol>
                     </div>
                  </li>
                  <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title10")}}
                        </h3>
                        <ol>
                              <li>{{$t("terms.title10_sections.section1")}}</li>
                              <li>
                                {{$t("terms.title10_sections.section2")}}
                                <ol>
                                  <li>{{$t("terms.title10_sections.section2_sub_items.section1")}}</li>
                                  <li>{{$t("terms.title10_sections.section2_sub_items.section2")}}</li>
                                  <li>{{$t("terms.title10_sections.section2_sub_items.section3")}}</li>
                                  <li>{{$t("terms.title10_sections.section2_sub_items.section4")}}</li>
                                  <li>{{$t("terms.title10_sections.section2_sub_items.section5")}}</li>
                                  <li>{{$t("terms.title10_sections.section2_sub_items.section6")}}</li>
                                </ol>
                              </li>
                        </ol>
                     </div>
                  </li>
                  <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title11")}}
                        </h3>
                        <ol>
                              <li>{{$t("terms.title11_sections.section1")}}</li>
                              <li>{{$t("terms.title11_sections.section2")}}</li>
                 
                        </ol>
                     </div>
                  </li>
                   <li style="margin-top:15px">
                     <div class="main-title">
                        <h3 style="color: var(--mainColor);position: relative;padding-bottom: 15px;font-size: calc(var(--medium_Font) - 2px) !important;">
                          {{$t("terms.sub_title12")}}
                        </h3>
                        <ol>
                              <li>{{$t("terms.title12_sections.section1")}}</li>
                              <li>{{$t("terms.title12_sections.section2")}}</li>
                              <li>{{$t("terms.title12_sections.section3")}}</li>
                              <li>{{$t("terms.title12_sections.section4")}}</li>
                              <li>{{$t("terms.title12_sections.section5")}}</li>
                              <li>{{$t("terms.title12_sections.section6")}}</li>
                        </ol>
                     </div>
                   </li>
              </ol>
            <div class="main_title">
              <h3>يمكن إرسال أي أسئلة حول شروط الاستخدام  إلى info@mronah.com.</h3>
              <h4>جميع الحقوق محفوظة 2022  شركة مرونة للخدمات اللوجستية</h4>
            </div>
            </div>
            <!--Description Text-->
          </div>
          <!--All Text-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
 li::marker{
  color: var(--mainColor);
  font-size: calc(var(--medium_Font) - 2px) !important;
 }

</style>
