<template>
  <!--Start Add -->
  <div class="container">
    <div class="custom_card">
      <form>
        <div class="row">
          <div class="col-sm-12">
            <!--Top Title-->
            <div class="form_title">
              <h4>{{ $t("editBtn") }}</h4>
            </div>
            <!--Top Title-->
            <!--Country-->
            <div class="input_wrapper round_radius select">
              <select
                class="form-control"
                v-model="data.port"
                @change="getOffices"
              >
                <option value="" class="disabled">
                  {{ $t("authrization.data.ports") }}
                </option>
                <option v-for="port in ports" :key="port.id" :value="port.id">
                  {{ port.name }}
                </option>
              </select>
            </div>
            <!--Country-->

            <!--City-->
            <div class="input_wrapper round_radius select">
              <select class="form-control" v-model="data.office">
                <option value="" class="disabled">
                  {{ $t("authrization.data.office") }}
                </option>
                <option
                  v-for="office in offices"
                  :key="office.id"
                  :value="office.id"
                >
                  {{ office.name }}
                </option>
              </select>
            </div>
            <!--City-->

            <!--Authrization Number-->
            <div class="input_wrapper round_radius">
              <input
                type="number"
                :placeholder="$t('authrization.data.number')"
                v-model="data.number"
                class="form-control"
              />
            </div>
            <!--Authrization Number-->

            <!--End Date-->
            <div class="input_wrapper round_radius">
              <Datepicker
                class="form-control"
                v-model="data.endDate"
                :format="formatDate"
                :placeholder="$t('authrization.data.endDate')"
              ></Datepicker>
            </div>
            <!--End Date-->

            <!--Button-->
            <button
              type="button"
              class="main_button2 button"
              ref="add"
              @click="submitForm"
            >
              <i class="fas fa-spinner"></i>
              {{ $t("editBtn") }}
            </button>
            <!--Button-->
          </div>
        </div>
      </form>
    </div>
  </div>
  <!--End Add -->
</template>

<script>
import Datepicker from "vuejs-datepicker";
import * as moment from "moment/moment";
export default {
  props: ["id"],
  components: {
    Datepicker,
  },
  data() {
    return {
      data: {
        port: "",
        office: "",
        number: null,
        endDate: null,
      },
      //Ports
      ports: [],
      //Offices
      offices: [],
    };
  },
  methods: {
    //Get Data
    getData() {
      //Ports
      this.$axios({
        method: "GET",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
        url: "ports",
      }).then((res) => {
        this.ports = res.data.data;
      });

      //My Data
      this.$axios({
        method: "GET",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
        url: "authorization/" + this.id,
      }).then((res) => {
        this.data.port = res.date.date.port_id;
        this.data.office = res.date.date.office_id;
        this.data.number = res.date.date.number;
        this.data.endDate = res.date.date.end_date;
      });
    },

    //Get Offices
    getOffices() {
      this.$axios({
        method: "GET",
        url: "offices",
        params: { port_id: this.data.port },
      }).then((res) => {
        this.offices = res.data.data;
      });
    },
    //Get Offices

    //Date Form
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //Date Form

    //Submit Form
    submitForm() {
      var submitButton = this.$refs["add"];
      submitButton.classList.add("click");
      if (!this.data.port) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.port"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.office) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.office"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.number) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.number"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.endDate) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.endDate"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = {
          port_id: this.data.port,
          office_id: this.data.office,
          number: this.data.number,
          end_date: this.data.endDate,
        };
        this.$axios({
          method: "PUT",
          url: "authorization",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("auth.validation.success"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/profile" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
