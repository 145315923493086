import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
import Home from "../views/Home.vue"; // Home

// *************** Children
import HomePage from "../views/children/HomePage.vue";

//***************** Static Pages
// About us
import AboutUs from "../views/children/StaticPages/AboutUs.vue";
//Contact Us
import ContactUs from "../views/children/StaticPages/ContactUs.vue";
//Terms
import Terms from "../views/children/StaticPages/Terms.vue";
//Policy
import Policy from "../views/children/StaticPages/Policy.vue";

//*****Blogs**** 
import Blogs from "../views/children/Blogs/Blogs.vue";
import SingleBlog from "../views/children/Blogs/SingleBlog.vue";

// *************** Start Auth ***************
import Auth from "../views/Auth/Auth.vue";
import Login from "../views/Auth/Login.vue";
import Register from "../views/Auth/Register.vue";
import VerifyAccount from "../views/Auth/VerifyAccount.vue";
import EnterEmail from "../views/Auth/ForgetPassword/EnterEmail.vue";
import CheckCode from "../views/Auth/ForgetPassword/CheckCode.vue";
import ResetPassword from "../views/Auth/ForgetPassword/ResetPassword.vue";
// *************** End Auth ***************

// *************** Start Dashboard ***************
import DashboardHome from "../views/Dashboard/Home.vue";
import DashboardHomePage from "../views/Dashboard/Children/Home.vue";
//*****Requests******/
import AllRequests from "../views/Dashboard/Children/AllRequests/AllRequests.vue";
import NewRequest from "../views/Dashboard/Children/AllRequests/CreateRequest.vue";
//*****Requests******/

//*****Profile******/
import Profile from "../views/Dashboard/Children/Profile/ProfilePage.vue";
//*****Profile******/

//*****Address******/
import AddAddress from "../views/Dashboard/Children/Addresses/Add.vue";
import EditAddress from "../views/Dashboard/Children/Addresses/Edit.vue";
//*****Address******/

//*****Authrization******/
import AddAuthrization from "../views/Dashboard/Children/Authrization/Add.vue";
import EditAuthrization from "../views/Dashboard/Children/Authrization/Edit.vue";
//*****Authrization******/

//=======Shipment=========
import AllShipment from "../views/Dashboard/Children/Shipment/ShowAll.vue";
import AddShipment from "../views/Dashboard/Children/Shipment/Add.vue";
import EditShipment from "../views/Dashboard/Children/Shipment/Edit.vue";
import ShowShipment from "../views/Dashboard/Children/Shipment/ShowSingle.vue";

//=======Notifications=========
import Notifications from "../views/Dashboard/Children/Notifications/AllNotifications.vue";

// *************** End Dashboard ***************

// *************** NotFound
import NotFound from "../views/NotFound.vue";

// ============================== End Views ==============================

// Vuex store
import store from "../store/index";

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    routes: [
        //Main
        {
            path: "/",
            component: Home,
            children: [{
                    path: "/",
                    name: "HomePage",
                    component: HomePage,
                },
                //About Us
                {
                    path: "/aboutUs",
                    name: "AboutUs",
                    component: AboutUs
                },
                //terms
                {
                    path: "/terms",
                    name: "Terms",
                    component: Terms
                },
                //terms
                {
                    path: "/policy",
                    name: "Policy",
                    component: Policy
                },
                //Contact Us
                {
                    path: "/contactUs",
                    name: "ContactUs",
                    component: ContactUs
                },
                //Blogs
                {
                    path: "/blogs",
                    name: "Blogs",
                    component: Blogs
                },
                //Single Blog
                {
                    path: "/blog",
                    name: "SingleBlog",
                    component: SingleBlog
                }
            ],
        },

        //Dashboard
        {
            path: "/dashboard",
            component: DashboardHome,
            children: [{
                    path: "/dashboard",
                    name: "DashboardHomePage",
                    component: DashboardHomePage
                },
                //=======Requests========
                {
                    path: "/allRequests",
                    name: "AllRequests",
                    component: AllRequests
                },
                {
                    path: "/newRequest",
                    name: "NewRequest",
                    component: NewRequest
                },
                //=======Profile========
                {
                    path: "/profile",
                    name: "Profile",
                    component: Profile
                },
                //=======Address========
                {
                    path: "/addAddress",
                    name: "AddAddress",
                    component: AddAddress
                },
                {
                    path: "/editAddress/:id",
                    name: "EditAddress",
                    component: EditAddress,
                    props: true
                },
                //=======Authrization========
                {
                    path: "/addAuthrization",
                    name: "AddAuthrization",
                    component: AddAuthrization
                },
                {
                    path: "/editAuthrization/:id",
                    name: "EditAuthrization",
                    component: EditAuthrization,
                    props: true
                },
                //========Shipment========/
                {
                    path: "/allShipment",
                    name: "AllShipment",
                    component: AllShipment
                },
                {
                    path: "/addShipment",
                    name: "AddShipment",
                    component: AddShipment
                },
                {
                    path: "/editShipment/:id",
                    name: "EditShipment",
                    component: EditShipment,
                    props: true
                },
                {
                    path: "/showShipment/:id",
                    name: "ShowShipment",
                    component: ShowShipment,
                    props: true
                },
                //Notifications
                {
                    path: "/notifications",
                    name: "Notifications",
                    component: Notifications
                }
            ]
        },

        // Auth
        {
            path: "/",
            component: Auth,
            children: [{
                    path: "/login",
                    name: "Login",
                    component: Login
                },
                {
                    path: "/register",
                    name: "Register",
                    component: Register
                },
                {
                    path: "/verifyAccount",
                    name: "VerifyAccount",
                    component: VerifyAccount
                },
                {
                    path: "/enterEmail",
                    name: "EnterEmail",
                    component: EnterEmail
                },
                {
                    path: "/resetPassword",
                    name: "ResetPassword",
                    component: ResetPassword
                },
                {
                    path: "/checkCode",
                    name: "CheckCode",
                    component: CheckCode
                },

            ]
        },

        // NotFound
        { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
    ],
});

router.beforeEach((to, _2, next) => {
    if (
        (to.name !== "Login" &&
            to.name !== "Register" &&
            to.name !== "VerifyAccount" &&
            to.name !== "EnterEmail" &&
            to.name !== "ResetPassword" &&
            to.name !== "HomePage" &&
            to.name !== "AboutUs" &&
            to.name !== "Terms" &&
            to.name !== "Policy" &&

            // to.name !== "ContactUs" &&
            // to.name !== "Blogs" &&
            to.name !== "CheckCode") &&
        !store.getters["auth_module/token"]
    ) {
        next({ name: "HomePage" });
    } else if (to.name == "Login" && store.getters["auth_module/token"]) {
        next("/dashboard");
    } else if (to.name == "HomePage" && store.getters["auth_module/token"]) {
        next("/dashboard");
    } else {
        next();
    }
});

Vue.use(VueRouter);
export default router;