<template>
  <div class="login">
    <!--Auth Form-->
    <div class="auth_form">
      <!--Logo-->
      <div class="logo">
        <img src="@/assets/images/Icons/logo.png" />
      </div>
      <!--Logo-->
      <form>
        <!--Input Wrapper Email-->
        <div class="input_wrapper round_radius with_icon">
          <span class="icon"><i class="fal fa-envelope"></i></span>
          <input
            type="email"
            class="form-control"
            v-model="data.email"
            :placeholder="$t('auth.data.email')"
          />
        </div>
        <!--Input Wrapper Email-->

        <!--Login Button-->
        <button
          type="button"
          class="second_button button login_btn"
          @click="forgetPassword"
          ref="forget"
        >
          <i class="fas fa-spinner"></i>
          {{ $t("auth.resetPassword") }}
        </button>
        <!--Login Button-->

        <!--Have Account-->
        <div class="have_account">
          <span
            >{{ $t("auth.noAccount") }}
            <router-link to="/register">{{
              $t("auth.register")
            }}</router-link></span
          >
        </div>
        <!--Have Account-->
      </form>
    </div>
    <!--Auth Form-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Login Data
      data: {
        email: null,
      },
    };
  },
  methods: {
    //Forget Password
    forgetPassword() {
      var submitButton = this.$refs["forget"];
      submitButton.classList.add("click");
      if (!this.data.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.email"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("email", this.data.email);
        this.$axios({
          method: "POST",
          url: "forget_password",
          data: submit_data,
        })
          .then((res) => {
            let myData = {
              email: this.data.email,
              code: res.data.data,
            };
            this.$store.dispatch("auth_module/verifyAccount", myData);
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("sendSuccess"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/checkCode" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.error,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
};
</script>

<style></style>
