<template>
  <div class="our_services" id="ser">
    <bubble></bubble>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <!--Main Title-->
          <div class="main_title">
            <h3>{{ $t("services.title") }}</h3>
          </div>
          <!--Main Title-->
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-4 our_ser"
          v-for="service in services"
          :key="service.id"
        >
          <!--Service Card-->
          <service-card :service="service"></service-card>
          <!--Service Card-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../HomeComponents/ServiceCard.vue";
import Bubble from "../Global/BubbleDotted.vue";
export default {
  data() {
    return {
      //Start Services
      services: [
        {
          id: 1,
          title: this.$t("services.customTitle"),
          desc: this.$t("services.customDesc"),
          image: require("@/assets/images/Services/air-freight.png"),
          price:"تبدأ من 499"
        },
        {
          id: 2,
          title: this.$t("services.foodTitle"),
          desc: this.$t("services.foodDesc"),
          image: require("@/assets/images/Services/24.png"),
          price:" تبدأ من 899"
        },
        {
          id: 3,
          title: this.$t("services.saberTitle"),
          desc: this.$t("services.saberDesc"),
          image: require("../../assets/images/Services/saber-logo.png"),
          price:" تبدأ من 899"
        },
        {
          id: 4,
          title: this.$t("services.transportTitle"),
          desc: this.$t("services.transportDesc"),
          image: require("../../assets/images/Services/delivery.png"),
          price:"حسب الوجهة"
        },
        {
          id: 5,
          title: this.$t("services.comunicateTitle"),
          desc: this.$t("services.comunicateDesc"),
          image: require("../../assets/images/Services/Layer 2.png"),
          price:" تبدأ من 899"
        },
        {
          id: 6,
          title: this.$t("services.consalutTitle"),
          desc: this.$t("services.consalutDesc"),
          image: require("../../assets/images/Services/inventory.png"),
          price:"مجانا لعملائنا"
        },
      ],
      //End Services
    };
  },
  components: {
    ServiceCard,
    Bubble,
  },
};
</script>

<style></style>
