<template>
  <div class="profile_page">
    <div class="container">
      <div class="col-md-12">
        <!--Title-->
        <div class="dashboard_title with_flex">
          <h4>شحنة جديدة</h4>
          <router-link to="/allShipment" class="order_button button">
            {{ $t("allShipment") }}
          </router-link>
        </div>
        <!--Title-->

        <!--Request Form-->
        <div class="request_form">
          <!--Tabs-->
          <b-tabs content-class="mt-3" v-model="tabIndex">
            <!--Personal Info-->
            <b-tab :title="$t('dashboard.shipment.general')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-md-4">
                          <!--Shipment Way-->
                          <div
                            class="input_wrapper round_radius select top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.shipWay")
                            }}</label>
                            <select
                              class="form-control"
                              v-model="data.ship_way"
                              @change="getPorts"
                            >
                              <!-- <option value="" disabled="disabled">
                                {{ $t("auth.data.shipWay") }}
                              </option> -->
                              <option
                                v-for="mode in modeShipping"
                                :key="mode.id"
                                :value="mode"
                              >
                                {{ mode.name }}
                              </option>
                            </select>
                          </div>
                          <!--Shipment Way-->
                        </div>

                        <div class="col-md-4">
                          <!--Shipment Type-->
                          <div
                            class="input_wrapper round_radius select top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.shipType")
                            }}</label>
                            <select
                              class="form-control"
                              v-model="data.ship_type"
                            >
                              <!-- <option value="" disabled="disabled">
                                {{ $t("auth.data.shipType") }}
                              </option> -->
                              <option
                                v-for="type in shippingTypes"
                                :key="type.id"
                                :value="type.id"
                              >
                                {{ type.name }}
                              </option>
                            </select>
                          </div>
                          <!--Shipment Type-->
                        </div>

                        <div class="col-md-4">
                          <!--Shiper Name-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.shiperName")
                            }}</label>
                            <input
                              type="text"
                              v-model="data.shipper_name"
                              class="form-control"
                            />
                          </div>
                          <!--Shiper Name-->
                        </div>

                        <div class="col-md-4">
                          <!--Port-->
                          <div
                            class="input_wrapper round_radius select top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.port")
                            }}</label>
                            <select class="form-control" v-model="data.port">
                              <!-- <option value="" disabled="disabled">
                                {{ $t("auth.data.port") }}
                              </option> -->
                              <option
                                v-for="port in ports"
                                :key="port.id"
                                :value="port.id"
                              >
                                {{ port.name }}
                              </option>
                            </select>
                          </div>
                          <!--Port-->
                        </div>

                        <div class="col-md-4">
                          <!--Office-->
                          <div
                            class="input_wrapper round_radius select top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.office")
                            }}</label>
                            <select class="form-control" v-model="data.office">
                              <!-- <option value="" disabled="disabled">
                                {{ $t("auth.data.office") }}
                              </option> -->
                              <option
                                v-for="office in offices"
                                :key="office.id"
                                :value="office.id"
                              >
                                {{ office.name }}
                              </option>
                            </select>
                          </div>
                          <!--Office-->
                        </div>

                        <div class="col-md-4">
                          <!--Polis Number-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.polisNumber")
                            }}</label>
                            <input
                              type="text"
                              v-model="data.polis_number"
                              class="form-control"
                            />
                          </div>
                          <!--Polis Number-->
                        </div>

                        <div class="col-sm-12" v-if="!data.has_office">
                          <router-link
                            to="addAuthrization"
                            class="order_button start_one"
                          >
                            {{ $t("addOffice") }}
                          </router-link>
                        </div>

                        <div
                          class="col-sm-12"
                          v-if="data.ship_way.have_container"
                        >
                          <button
                            type="button"
                            class="order_button button start_one"
                            @click="addItem"
                          >
                            {{ $t("addContainer") }}
                          </button>
                        </div>

                        <div
                          class="col-sm-12"
                          v-if="data.ship_way.have_container"
                        >
                          <div
                            class="row"
                            v-for="(container, index) in containers"
                            :key="container.id"
                            :id="`cont_${container.id}`"
                          >
                            <div class="col-md-6">
                              <!--Containers Number-->
                              <div class="input_wrapper round_radius top_label">
                                <label class="form-label">{{
                                  $t("auth.data.containerNumber")
                                }}</label>
                                <input
                                  type="number"
                                  v-model="containers[index].number"
                                  class="form-control"
                                />
                              </div>
                              <!--Containers Number-->
                            </div>

                            <div class="col-md-6">
                              <!--Containers Size-->
                              <div class="input_wrapper round_radius top_label">
                                <label class="form-label">{{
                                  $t("auth.data.containerSize")
                                }}</label>
                                <input
                                  type="number"
                                  v-model="containers[index].size"
                                  class="form-control"
                                />
                              </div>
                              <!--Containers Size-->
                            </div>

                            <div class="col-md-6">
                              <!--Containers Weight-->
                              <div class="input_wrapper round_radius top_label">
                                <label class="form-label">{{
                                  $t("auth.data.containerWeight")
                                }}</label>
                                <input
                                  type="number"
                                  v-model="containers[index].weight"
                                  class="form-control"
                                />
                              </div>
                              <!--Containers Weight-->
                            </div>

                            <div class="col-md-6">
                              <!--Containers Seal Number-->
                              <div class="input_wrapper round_radius top_label">
                                <label class="form-label">{{
                                  $t("auth.data.containerSealNumber")
                                }}</label>
                                <input
                                  type="number"
                                  v-model="containers[index].seal_number"
                                  class="form-control"
                                />
                              </div>
                              <!--Containers Seal Number-->
                            </div>

                            <div class="col-md-12">
                              <button
                                type="button"
                                class="order_button button"
                                @click="remmoveItem(index)"
                              >
                                {{ $t("deleteContainer") }}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <!--Real Country-->
                          <div
                            class="input_wrapper round_radius select top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.realContry")
                            }}</label>
                            <select
                              class="form-control"
                              v-model="data.country_of_origin_id"
                            >
                              <!-- <option value="" disabled="disabled">
                                {{ $t("auth.data.realContry") }}
                              </option> -->
                              <option
                                v-for="country in countries"
                                :key="country.id"
                                :value="country.id"
                              >
                                {{ country.name }}
                              </option>
                            </select>
                          </div>
                          <!--Real Country-->
                        </div>

                        <!--                         <div class="col-md-4">
                          <!~~Transporter~~>
                          <div
                            class="input_wrapper round_radius select top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.transporter")
                            }}</label>
                            <select
                              class="form-control"
                              v-model="data.transporter"
                            >
                              <!~~ <option value="" disabled="disabled">
                                {{ $t("auth.data.transporter") }}
                              </option> ~~>
                              <option
                                v-for="tra in transporters"
                                :key="tra.id"
                                :value="tra.id"
                              >
                                {{ tra.name }}
                              </option>
                            </select>
                          </div>
                          <!~~Transporter~~>
                        </div> -->

                        <!--                         <div class="col-md-6">
                          <!~~Address~~>
                          <div
                            class="input_wrapper round_radius select top_label"
                          >
                            <label class="form-label">{{
                              $t("auth.data.address")
                            }}</label>
                            <select class="form-control" v-model="data.address">
                              <!~~ <option value="" disabled="disabled">
                                {{ $t("auth.data.address") }}
                              </option> ~~>
                              <option
                                v-for="address in addresses"
                                :key="address.id"
                                :value="address.id"
                              >
                                {{ address.type }}
                              </option>
                            </select>
                          </div>
                          <!~~Address~~>
                        </div> -->

                        <div class="col-md-4">
                          <!--Weight-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.weight")
                            }}</label>
                            <input
                              type="number"
                              v-model="data.gross_weight"
                              class="form-control"
                            />
                          </div>
                          <!--Weight-->
                        </div>

                        <div class="col-md-4">
                          <!--Real Weight-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.realWeight")
                            }}</label>
                            <input
                              type="number"
                              v-model="data.net_weight"
                              class="form-control"
                            />
                          </div>
                          <!--Real Weight-->
                        </div>

                        <div class="col-md-4">
                          <!-- Quantity-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.quantity")
                            }}</label>
                            <input
                              type="number"
                              v-model="data.quantity"
                              class="form-control"
                            />
                          </div>
                          <!--Quantity-->
                        </div>

                        <div class="col-md-4">
                          <!-- Size-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.size")
                            }}</label>
                            <input
                              type="number"
                              v-model="data.size"
                              class="form-control"
                            />
                          </div>
                          <!--Size-->
                        </div>

                        <div class="col-md-4">
                          <!--Expect Date-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.expectDate")
                            }}</label>
                            <Datepicker
                              class="form-control"
                              :format="formatDate"
                              v-model="data.expected_date"
                            ></Datepicker>
                          </div>
                          <!--Expect Date-->
                        </div>

                        <div class="col-md-12">
                          <!--Description-->
                          <div class="input_wrapper round_radius top_label">
                            <label class="form-label">{{
                              $t("auth.data.desc")
                            }}</label>
                            <textarea
                              v-model="data.desc"
                              class="form-control"
                            ></textarea>
                          </div>
                          <!--Description-->
                        </div>
                        <div class="flex_buttons">
                          <button
                            type="button"
                            class="order_button button"
                            @click="tabIndex++"
                          >
                            {{ $t("next") }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--General Info-->

            <!--Other Data-->
            <b-tab :title="$t('dashboard.shipment.attachment')">
              <div class="row">
                <div class="col-md-11">
                  <div class="custom_card">
                    <form>
                      <div class="row">
                        <div class="col-md-6">
                          <!--Polis Image-->
                          <div
                            class="input_wrapper round_radius file_input full_file with_flex"
                          >
                            <input
                              type="file"
                              class="form-control"
                              @change="polisImage"
                              accept="image/*,.doc,.pdf"
                            />
                            <label class="form-control">
                              <span v-if="!polis_image.name">{{
                                $t("auth.data.polisImage")
                              }}</span>
                              <span v-else>{{ polis_image.name }}</span>
                            </label>
                            <div class="del_icon">
                              <button
                                type="button"
                                class="btn"
                                @click="frist_del"
                              >
                                <i class="fal fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                          <!--Polis Image-->
                        </div>

                        <div class="col-md-6">
                          <!--Invoice Image-->
                          <div
                            class="input_wrapper round_radius file_input full_file with_flex"
                          >
                            <input
                              type="file"
                              class="form-control"
                              @change="invoiceImage"
                              accept="image/*,.doc,.pdf"
                            />
                            <label class="form-control">
                              <span v-if="!invoice_image.name">{{
                                $t("auth.data.invoiceImage")
                              }}</span>
                              <span v-else>{{ invoice_image.name }}</span>
                            </label>
                            <div class="del_icon">
                              <button
                                type="button"
                                class="btn"
                                @click="second_del"
                              >
                                <i class="fal fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                          <!--Invoice Image-->
                        </div>

                        <div class="col-md-6">
                          <!--Country Image-->
                          <div
                            class="input_wrapper round_radius file_input full_file with_flex"
                          >
                            <input
                              type="file"
                              class="form-control"
                              @change="countryImage"
                              accept="image/*,.doc,.pdf"
                            />
                            <label class="form-control">
                              <span v-if="!country_image.name">{{
                                $t("auth.data.countryImage")
                              }}</span>
                              <span v-else>{{ country_image.name }}</span>
                            </label>
                            <div class="del_icon">
                              <button
                                type="button"
                                class="btn"
                                @click="third_del"
                              >
                                <i class="fal fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                          <!--Country Image-->
                        </div>

                        <div class="col-md-6">
                          <!--Polis Image-->
                          <div
                            class="input_wrapper round_radius file_input full_file with_flex"
                          >
                            <input
                              type="file"
                              class="form-control"
                              @change="certificateImage"
                              accept="image/*,.doc,.pdf"
                            />
                            <label class="form-control">
                              <span v-if="!certificate_image.name">{{
                                $t("auth.data.certificateImage")
                              }}</span>
                              <span v-else>{{ certificate_image.name }}</span>
                            </label>
                            <div class="del_icon">
                              <button
                                type="button"
                                class="btn"
                                @click="forth_del"
                              >
                                <i class="fal fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                          <!--Polis Image-->
                        </div>
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="order_button button start_one"
                            @click="addAttach()"
                          >
                            {{ $t("addAttach") }}
                          </button>
                        </div>
                        <div
                          class="col-md-12"
                          v-for="(attach, index) in attatches"
                          :key="attach.id"
                          :id="`atth_${attach.id}`"
                        >
                          <div class="row">
                            <div class="col-md-5">
                              <!--Polis Image-->
                              <div
                                class="input_wrapper round_radius file_input full_file"
                              >
                                <label class="form-control">
                                  <span v-if="!attach.name">{{
                                    $t("auth.data.extraFile")
                                  }}</span>
                                  <span v-else>{{ attach.name }}</span>
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="attachFiles($event, index)"
                                />
                              </div>
                              <!--Polis Image-->
                            </div>

                            <div class="col-md-5">
                              <!--Shiper Name-->
                              <div class="input_wrapper round_radius top_label">
                                <label class="form-label">{{
                                  $t("auth.data.comment")
                                }}</label>
                                <input
                                  type="text"
                                  v-model="attach.comment"
                                  class="form-control"
                                />
                              </div>
                              <!--Shiper Name-->
                            </div>
                            <div class="col-md-2">
                              <v-btn
                                @click="remmoveAttach(index)"
                                small
                                fab
                                color="#f95353"
                              >
                                <i class="fal fa-trash-alt"></i>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex_buttons">
                        <button
                          type="button"
                          class="order_button button"
                          @click="tabIndex--"
                        >
                          {{ $t("previous") }}
                        </button>
                        <button
                          type="button"
                          @click="submitForm"
                          class="order_button button"
                          ref="add"
                        >
                          <i class="fas fa-spinner"></i>
                          {{ $t("applayRequest") }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-1">
                  <!--Start Icon Actions-->
                  <div class="icon_actions">
                    <ul>
                      <!--Contact For Support-->
                      <li>
                        <button type="button" class="btn" @click="showContact">
                          <img src="@/assets/images/Icons/message.png" />
                        </button>
                      </li>
                      <!--Contact For Support-->

                      <!--Contact For Support-->
                      <li>
                        <router-link to="">
                          <img src="@/assets/images/Icons/user_icon.png" />
                        </router-link>
                      </li>
                      <!--Contact For Support-->
                    </ul>
                  </div>
                  <!--End Icon Actions-->
                </div>
              </div>
            </b-tab>
            <!--Other Data-->
          </b-tabs>
          <!--Tabs-->
        </div>
        <!--Request Form-->
      </div>
    </div>
    <!-- Contact Dialog-->
    <v-dialog v-model="show_contact" class="custom_dialog contact_dialog">
      <!--Dialog Title-->
      <div class="dialog_title">
        <h4>تواصل مع الدعم الفني</h4>
        <p>اختر ما هي مشكلتك وسوف يتم التواصل معك على الفور</p>
        <form>
          <div class="row">
            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-lg-4">
              <!--Problem-->
              <div class="input_wrapper round_radius">
                <input type="text" placeholder="المشكلة" class="form-control" />
              </div>
              <!--Problem-->
            </div>

            <div class="col-md-8">
              <!--Problem Details-->
              <div class="input_wrapper round_radius">
                <textarea
                  placeholder="تفاصيل المشكلة"
                  class="form-control"
                ></textarea>
              </div>
              <!--Problem Details-->
            </div>

            <div class="col-md-4">
              <div class="request_actions">
                <button type="button" class="order_button button">
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendBtn") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--Dialog Title-->
    </v-dialog>
    <!-- Contact Dialog-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import * as moment from "moment/moment";
export default {
  data() {
    return {
      //Show Contact
      show_contact: false,
      //Show Contact
      //Countries
      countries: [],
      //Cities
      cities: [],
      //Areas
      areas: [],
      //Areas
      ports: [],
      //Offices
      offices: [],
      //Mode Shipping
      modeShipping: [],
      //Shipping Types
      shippingTypes: [],
      //Transporters
      transporters: [],
      //Transporters
      addresses: [],
      tabIndex: 0,
      data: {
        polis_number: null,
        shipper_name: null,
        ship_way: "",
        ship_type: "",
        port: "",
        office: "",
        has_office: false,
        arrive_port: "",
        loading_country: "",
        transporter: "",
        address: "",
        country: "",
        city: "",
        desc: "",
        country_of_origin_id: "",
        gross_weight: null,
        net_weight: null,
        quantity: null,
        size: null,
        expected_date: null,
        area: null,
      },
      invoice_image: {
        file: null,
        name: null,
        path: null,
      },
      polis_image: {
        file: null,
        name: null,
        path: null,
      },

      country_image: {
        file: null,
        name: null,
        path: null,
      },

      certificate_image: {
        file: null,
        name: null,
        path: null,
      },

      frist_identify_image: {
        file: null,
        name: null,
        path: null,
      },

      last_identify_image: {
        file: null,
        name: null,
        path: null,
      },

      containers: [
        // {
        //   id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        //   number: null,
        //   size: null,
        //   weight: null,
        //   seal_number: null,
        // },
      ],

      attatches: [],
    };
  },
  methods: {
    // Uplode Avatar
    uplodeImg_1(obj) {
      this.data.avatar = obj;
    },
    // Uplode Avatar

    //Show Contract
    showContact() {
      this.show_contact = true;
    },
    //Show Contract

    //Date Form
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //Date Form

    // START:: INVOICE IMAGE METHOD
    invoiceImage(e) {
      this.invoice_image.file = e.target.files[0];
      this.invoice_image.name = e.target.files[0].name;
      this.invoice_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: INVOICE IMAGE METHOD

    // START:: POLIS IMAGE METHOD
    polisImage(e) {
      this.polis_image.file = e.target.files[0];
      this.polis_image.name = e.target.files[0].name;
      this.polis_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: POLIS IMAGE METHOD

    // START:: COUNTRY IMAGE METHOD
    countryImage(e) {
      this.country_image.file = e.target.files[0];
      this.country_image.name = e.target.files[0].name;
      this.country_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: COUNTRY IMAGE METHOD

    // START:: CERTIFICATE IMAGE METHOD
    certificateImage(e) {
      this.certificate_image.file = e.target.files[0];
      this.certificate_image.name = e.target.files[0].name;
      this.certificate_image.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: CERTIFICATE IMAGE METHOD

    // START:: FIRST IMAGE METHOD
    attachFiles(e, index) {
      this.attatches[index].file = e.target.files[0];
      this.attatches[index].name = e.target.files[0].name;
      this.attatches[index].path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: FIRST IMAGE METHOD

    // ADD:: ADD ITEM
    addItem() {
      this.containers.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        number: null,
        size: null,
        weight: null,
        seal_number: null,
      });
    },
    // END:: ADD ITEM

    // ADD:: ADD ATTACH
    addAttach() {
      this.attatches.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        comment: null,
        file: null,
        name: null,
        path: null,
      });
    },
    // END:: ADD ATTACH

    // START:: DELETE ITEM
    remmoveItem(index) {
      this.containers.splice(index, 1);
    },
    // END:: DELETE ITEM

    // START:: DELETE ATTACH
    remmoveAttach(index) {
      this.attatches.splice(index, 1);
    },
    // END:: DELETE ATTACH

    // START:: GET DATA
    getData() {
      //Mode Shipping
      this.$axios({
        method: "GET",
        url: "search/mode_shippings",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.modeShipping = res.data.data;
      });

      //Shipping Types
      this.$axios({
        method: "GET",
        url: "search/shipping_types",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.shippingTypes = res.data.data;
      });

      //Offices
      this.$axios({
        method: "GET",
        url: "search/authorized_offices",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.offices = res.data.data;
        this.data.has_office = res.data.has_offices;
      });

      //Transporters
      this.$axios({
        method: "GET",
        url: "search/transporters",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.transporters = res.data.data;
      });

      //Addresses
      this.$axios({
        method: "GET",
        url: "address",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.addresses = res.data.data;
      });
    },
    // END:: GET DATA

    // START:: GET PORTS
    getPorts() {
      //Ports
      this.$axios({
        method: "GET",
        url: "search/authorized_ports",
        params: { mode_shipping_id: this.data.ship_way.id },
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.ports = res.data.data;
      });
    },
    // END:: GET PORTS

    // START:: GET COUNTRY
    getCountry() {
      this.$axios({
        method: "GET",
        url: "search/countries",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.countries = res.data.data;
        this.getCity();
      });
    },
    // END:: GET COUNTRY

    // START:: GET CITY
    getCity() {
      this.$axios({
        method: "GET",
        url: "search/cities",
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
        params: { country_id: this.data.country },
      }).then((res) => {
        this.cities = res.data.data;
        this.getArea();
      });
    },
    // END:: GET CITY

    // START:: GET AREA
    getArea() {
      this.$axios({
        method: "GET",
        url: "search/areas",
        params: { country_id: this.data.country, city_id: this.data.city },
        headers: {
          Authorization: "bearer " + localStorage.getItem("Moronah_App_Token"),
        },
      }).then((res) => {
        this.areas = res.data.data;
      });
    },
    // END:: GET AREA

    //Del Buttons
    frist_del() {
      this.polis_image.file = null;
      this.polis_image.name = null;
      this.polis_image.path = null;
    },
    second_del() {
      this.invoice_image.file = null;
      this.invoice_image.name = null;
      this.invoice_image.path = null;
    },
    third_del() {
      this.country_image.file = null;
      this.country_image.name = null;
      this.country_image.path = null;
    },
    forth_del() {
      this.certificate_image.file = null;
      this.certificate_image.name = null;
      this.certificate_image.path = null;
    },
    //Del Buttons

    //Submit Form
    submitForm() {
      var submitButton = this.$refs["add"];
      submitButton.classList.add("click");
      if (!this.data.ship_way) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.ship_way"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.ship_type) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.ship_type"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.shipper_name) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.shipper_name"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.port) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.port"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.office) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.office"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.polis_number) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.polis_number"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.country_of_origin_id) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.country_of_origin_id"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.data.transporter) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.transporter"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.data.address) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.address"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else if (!this.data.gross_weight) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.gross_weight"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.net_weight) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.net_weight"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.quantity) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.quantity"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.size) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.size"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.expected_date) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.expected_date"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.data.desc) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.desc"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.polis_image.file) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.polis_image"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (!this.invoice_image.file) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.invoice_image"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (!this.country_image) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.country_image"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // } else if (!this.certificate_image.file) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.certificate_image"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else {
        let submit_data = new FormData();
        submit_data.append("mode_shipping_id", this.data.ship_way.id);
        this.containers.forEach((element, index) => {
          submit_data.append(`containers[${index}][number]`, element.number);
          submit_data.append(`containers[${index}][size]`, element.size);
          submit_data.append(`containers[${index}][weight]`, element.weight);
          submit_data.append(
            `containers[${index}][seal_number]`,
            element.seal_number
          );
        });
        submit_data.append("port_id", this.data.port);
        submit_data.append("office_id", this.data.office);
        submit_data.append("shipping_type_id", this.data.ship_type);
        // submit_data.append("country_loading_id", this.data.country);
        submit_data.append("bill_of_lading_number", this.data.polis_number);
        submit_data.append("shipper_name", this.data.shipper_name);
        submit_data.append("gross_weight", this.data.gross_weight);
        submit_data.append("net_weight", this.data.net_weight);
        submit_data.append("quantity", this.data.quantity);
        submit_data.append("size", this.data.size);
        submit_data.append(
          "country_of_origin_id",
          this.data.country_of_origin_id
        );
        submit_data.append("country_of_origin_image", this.country_image.file);
        submit_data.append("description", this.data.desc);
        submit_data.append(
          "expected_date",
          this.formatDate(this.data.expected_date)
        );
        submit_data.append("invoice_image", this.invoice_image.file);
        if (this.certificate_image.file) {
          submit_data.append("cert_image", this.certificate_image.file);
        }
        if (this.polis_image.file) {
          submit_data.append("bill_lading_file", this.polis_image.file);
        }
        // submit_data.append("address_id", this.data.port);
        // submit_data.append("transporter_id", this.data.transporter);
        this.attatches.forEach((element, index) => {
          submit_data.append(`attaches[${index}][file]`, element.file);
          submit_data.append(`attaches[${index}][comment]`, element.comment);
        });
        this.$axios({
          method: "POST",
          url: "shipment",
          headers: {
            Authorization:
              "bearer " + localStorage.getItem("Moronah_App_Token"),
          },
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("addsuccess"),
                position: "bottomRight",
              });
              this.$router.push({ path: "/allShipment" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  components: {
    Datepicker,
  },
  created() {
    this.getData();
    this.getCountry();
  },
};
</script>

<style lang="scss" scoped>
.del_icon {
  margin-inline-start: 5px;
  position: relative;
  z-index: 9;
  .btn {
    width: 27px;
    height: 27px;
    background-color: #e05d5d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    font-size: 13px;
  }
}
</style>
