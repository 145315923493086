<template>
  <div class="all_blogs">
    <!--Main Bg-->
    <div class="main_bg">
      <!--Particles Shape-->
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="120"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <!--Particles Shape-->
    </div>
    <!--Main Bg-->
    <!--Start Blogs-->
    <div class="blogs">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <!--Main Title-->
            <div class="main_title">
              <h3>{{ $t("blogs.title") }}</h3>
              <p>{{ $t("blogs.desc") }}</p>
            </div>
            <!--Main Title-->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!--Blogs Carousel-->
            <carousel
              dir="ltr"
              :items="3"
              :autoplay="true"
              :margin="20"
              :dots="true"
              :navs="false"
              :loop="true"
              :navText="[
                `<span class='fas fa-arrow-right'></span>`,
                `<span class='fas fa-arrow-left'></span>`,
              ]"
              :responsive="{
                0: { items: 1, nav: false },
                600: { items: 2, nav: false },
                800: { items: 2, nav: false },
                1000: { items: 3, nav: false },
              }"
            >
              <!--Carousel Item-->
              <div class="item">
                <!--Carousel Image-->
                <div class="image">
                  <img src="@/assets/images/Blogs/blog1.png" />
                </div>
                <!--Carousel Image-->

                <!--Carousel Description-->
                <div class="desc">
                  <h4>{{ $t("blogs.blog.name") }}</h4>
                  <p>{{ $t("blogs.blog.desc") }}</p>
                </div>
                <!--Carousel Description-->
                <!--Read More-->
                <div class="read_more">
                  <router-link class="main_button" to="/blog">{{
                    $t("readMore")
                  }}</router-link>
                </div>
                <!--Read More-->
              </div>
              <!--Carousel Item-->
              <!--Carousel Item-->
              <div class="item">
                <!--Carousel Image-->
                <div class="image">
                  <img src="@/assets/images/Blogs/blog2.png" />
                </div>
                <!--Carousel Image-->

                <!--Carousel Description-->
                <div class="desc">
                  <h4>{{ $t("blogs.blog.name") }}</h4>
                  <p>{{ $t("blogs.blog.desc") }}</p>
                </div>
                <!--Carousel Description-->
                <!--Read More-->
                <div class="read_more">
                  <router-link class="main_button" to="/blog">{{
                    $t("readMore")
                  }}</router-link>
                </div>
                <!--Read More-->
              </div>
              <!--Carousel Item-->
              <!--Carousel Item-->
              <div class="item">
                <!--Carousel Image-->
                <div class="image">
                  <img src="@/assets/images/Blogs/blog3.png" />
                </div>
                <!--Carousel Image-->

                <!--Carousel Description-->
                <div class="desc">
                  <h4>{{ $t("blogs.blog.name") }}</h4>
                  <p>{{ $t("blogs.blog.desc") }}</p>
                </div>
                <!--Carousel Description-->
                <!--Read More-->
                <div class="read_more">
                  <router-link class="main_button" to="/blog">{{
                    $t("readMore")
                  }}</router-link>
                </div>
                <!--Read More-->
              </div>
              <!--Carousel Item-->
              <!--Carousel Item-->
              <div class="item">
                <!--Carousel Image-->
                <div class="image">
                  <img src="@/assets/images/Blogs/blog1.png" />
                </div>
                <!--Carousel Image-->

                <!--Carousel Description-->
                <div class="desc">
                  <h4>{{ $t("blogs.blog.name") }}</h4>
                  <p>{{ $t("blogs.blog.desc") }}</p>
                </div>
                <!--Carousel Description-->
                <!--Read More-->
                <div class="read_more">
                  <router-link class="main_button" to="/blog">{{
                    $t("readMore")
                  }}</router-link>
                </div>
                <!--Read More-->
              </div>
              <!--Carousel Item-->
              <!--Carousel Item-->
              <div class="item">
                <!--Carousel Image-->
                <div class="image">
                  <img src="@/assets/images/Blogs/blog2.png" />
                </div>
                <!--Carousel Image-->

                <!--Carousel Description-->
                <div class="desc">
                  <h4>{{ $t("blogs.blog.name") }}</h4>
                  <p>{{ $t("blogs.blog.desc") }}</p>
                </div>
                <!--Carousel Description-->
                <!--Read More-->
                <div class="read_more">
                  <router-link class="main_button" to="/blog">{{
                    $t("readMore")
                  }}</router-link>
                </div>
                <!--Read More-->
              </div>
              <!--Carousel Item-->
              <!--Carousel Item-->
              <div class="item">
                <!--Carousel Image-->
                <div class="image">
                  <img src="@/assets/images/Blogs/blog3.png" />
                </div>
                <!--Carousel Image-->

                <!--Carousel Description-->
                <div class="desc">
                  <h4>{{ $t("blogs.blog.name") }}</h4>
                  <p>{{ $t("blogs.blog.desc") }}</p>
                </div>
                <!--Carousel Description-->
                <!--Read More-->
                <div class="read_more">
                  <router-link class="main_button" to="/blog">{{
                    $t("readMore")
                  }}</router-link>
                </div>
                <!--Read More-->
              </div>
              <!--Carousel Item-->
              <!--Carousel Item-->
              <div class="item">
                <!--Carousel Image-->
                <div class="image">
                  <img src="@/assets/images/Blogs/blog1.png" />
                </div>
                <!--Carousel Image-->

                <!--Carousel Description-->
                <div class="desc">
                  <h4>{{ $t("blogs.blog.name") }}</h4>
                  <p>{{ $t("blogs.blog.desc") }}</p>
                </div>
                <!--Carousel Description-->
                <!--Read More-->
                <div class="read_more">
                  <router-link class="main_button" to="/blog">{{
                    $t("readMore")
                  }}</router-link>
                </div>
                <!--Read More-->
              </div>
              <!--Carousel Item-->
            </carousel>
            <!--Blogs Carousel-->
          </div>
        </div>
      </div>
    </div>
    <!--End Blogs-->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
