<template>
  <div class="homePage">
    <main-header :items="allData"></main-header>
    <services></services>
    <values></values>
    <location></location>
    <!-- <clients></clients> -->
    <download-app></download-app>
  </div>
</template>

<script>
import MainHeader from "@/components/HomePage/MainHeader.vue";
import Services from "@/components/HomePage/Services.vue";
import Values from "@/components/HomePage/Values.vue";
import Location from "@/components/HomePage/Location.vue";
// import Clients from "@/components/HomePage/Clients.vue";
import DownloadApp from "@/components/HomePage/DownloadApp.vue";
export default {
  data() {
    return {
      allData: null,
    };
  },
  methods: {
    changeTheme() {
      this.$store.dispatch("theme_module/changeTheme");
      if (this.$store.getters["theme_module/current_theme"] == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
    getSetting() {
      this.$axios({
        method: "GET",
        url: "setting",
      }).then((res) => {
        this.allData = res.data.data;
      });
    },
  },
  components: {
    MainHeader,
    Services,
    Values,
    Location,
    // Clients,
    DownloadApp,
  },
  created() {
    this.getSetting();
  },
};
</script>
