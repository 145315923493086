<template>
  <header class="header">
    <nav class="navbar navbar-expand-lg dashboard_navbar">
      <div class="container">
        <!--Logo-->
        <router-link class="navbar-brand" to="/"
          ><img src="@/assets/images/Icons/logo.png"
        /></router-link>
        <!--Logo-->

        <!--Button Toggle On Mobile Screen-->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="showNav"
        >
          <span class="navbar-toggler-icon"><i class="fal fa-bars"></i></span>
        </button>
        <!--Button Toggle On Mobile Screen-->

        <!--Navbar Container-->
        <div class="navbar-collapse s_nav" :class="{ show: show }">
          <!--After Login-->
          <div class="after_login mr-auto">
            <ul>
              <!--List Item-->
              <li class="list_item">
                <router-link to="/"><i class="fal fa-wallet"></i></router-link>
              </li>
              <!--List Item-->
              <!--List Item-->
              <li class="list_item">
                <router-link to="/"
                  ><i class="fal fa-comment-lines"></i
                ></router-link>
              </li>
              <!--List Item-->
              <!--List Item-->
              <li class="list_item with_dropmenu">
                <button type="button" @click="toggle_notification_menu">
                  <i class="fal fa-bell"></i>
                  <span class="notificationNumber">{{
                    notifications.unreadnotifications_count
                  }}</span>
                </button>
                <!-- notification dropdown -->
                <transition name="fadeUpTransform">
                  <div
                    v-if="notification_menu"
                    class="custom_card notification_dropdown nav_dropdown"
                  >
                    <header class="header">
                      <div>
                        <h3>
                          {{ $t("navbar.notification.topTitle_2") }}
                        </h3>
                      </div>
                      <router-link to="/notifications">{{
                        $t("showAll")
                      }}</router-link>
                    </header>
                    <!-- START:: EMPTY NOTIFICATIONS -->
                    <div
                      v-if="notifications.notifications.length == 0"
                      class="empty_notifications_wrapper d-flex flex-column justify-content-center align-items-center py-4"
                    >
                      <img
                        src="@/assets/images/no_notifications.svg"
                        alt="No Notifications"
                        width="120"
                        height="150"
                      />

                      <h4>No Notifications Yet!!</h4>
                    </div>
                    <!-- END:: EMPTY NOTIFICATIONS -->

                    <ul class="notifications_container" v-else>
                      <li
                        v-for="item in notifications.notifications"
                        :key="item.id"
                      >
                        <router-link to="" class="item">
                          <span class="icon"
                            ><i class="fal fa-plus-square"></i
                          ></span>
                          <div class="text">
                            <h4>{{ item.title }}</h4>
                            <!-- <p>{{ item.body.slice(0, 40) + "..." }}</p> -->
                          </div>
                          <!-- <span class="time">{{ item.created_time }}</span> -->
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </transition>
              </li>
              <!--List Item-->
              <li class="list_item with_dropmenu">
                <button type="button" @click="showUserMenu">
                  <!--User Part-->
                  <div class="user_part">
                    <!--UserName-->
                    <span>{{ userName }}</span>
                    <!--UserName-->
                    <!--UserImage-->
                    <div class="image">
                      <img :src="userImage" />
                    </div>
                    <!--UserImage-->
                  </div>
                  <!--User Part-->
                </button>

                <!--User Menu-->
                <transition name="fadeIn">
                  <ul class="user_menu" :class="{ dropdown_menu_5: user_menu }">
                    <li class="dropdown_item-1" @click="user_menu = false">
                      <router-link to="/profile">{{
                        $t("dashboard.profile.title")
                      }}</router-link>
                    </li>
                    <li class="dropdown_item-2">
                      <button type="button" @click="logout()">
                        {{ $t("navbar.logout") }}
                      </button>
                    </li>
                  </ul>
                </transition>
                <!--User Menu-->
              </li>
              <!--List Item-->
              <!-- <li class="lang">
                <button type="button" @click="changeLang" class="btn">
                  {{ $t("lang") }}
                </button>
              </li> -->
            </ul>
          </div>
          <!--After Login-->
          <!--Close SideMenu-->
          <div class="close_menu">
            <button class="btn" type="button" @click="hideNav">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <!--Close SideMenu-->
        </div>
        <!--Navbar Container-->
      </div>
      <!--Overlay-->
      <transition name="fadeIn" mode="in-out">
        <div class="overlay" v-if="show" @click="hideNav"></div>
      </transition>
      <!--Overlay-->
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: null,
      show: false,
      notification_menu: false,
      user_menu: false,
    };
  },
  methods: {
    //Update Scroll
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    //Update Scroll

    //Show Side Nav
    showNav() {
      this.show = true;
    },
    //Show Side Nav

    //Hide Side Nav
    hideNav() {
      this.show = false;
    },
    //Hide Side Nav

    //Show User Menu
    showUserMenu() {
      this.user_menu = !this.user_menu;
      this.notification_menu = false;
    },
    //Show User Menu

    //Toggle Notification Menu
    toggle_notification_menu() {
      this.notification_menu = !this.notification_menu;
      this.user_menu = false;
      this.$store.dispatch("socket_module/get_notification");
      this.readAll();
      // this.commit("socket_module/set_noteIcon", false);
    },
    //Toggle Notification Menu

    //Change Lang
    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
    //Change Lang

    //Read Notification
    readAll() {
      this.$axios({
        method: "GET",
        url: "read/all/notifications",
      }).then(() => {});
    },
    //Read Notification

    //Logout
    logout() {
      this.$store.dispatch("auth_module/logOut");
    },
    //Close Menu When Body Click
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.notification_menu = false;
        this.user_menu = false;
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    document.addEventListener("click", this.close);
  },
  computed: {
    userImage() {
      return this.$store.getters["auth_module/userImage"];
    },
    userName() {
      return this.$store.getters["auth_module/userName"];
    },
    notifications() {
      return this.$store.getters["socket_module/notification"];
    },
    notIcon() {
      return this.$store.getters["socket_module/noteIcon"];
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications_container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
